/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingRequest
 */
export interface BookingRequest {
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    shipmentId: string;
    /**
     * Indicates the type of service offered at Origin. Options are defined in the Receipt/Delivery entity.
     * - CY (Container yard (incl. rail ramp))
     * - SD (Store Door)
     * - CFS (Container Freight Station)
     * @type {string}
     * @memberof BookingRequest
     */
    receiptTypeAtOrigin?: BookingRequestReceiptTypeAtOriginEnum;
    /**
     * Indicates the type of service offered at Destination. Options are defined in the Receipt/Delivery entity.
     * - CY (Container yard (incl. rail ramp))
     * - SD (Store Door)
     * - CFS (Container Freight Station)
     * @type {string}
     * @memberof BookingRequest
     */
    deliveryTypeAtDestination?: BookingRequestDeliveryTypeAtDestinationEnum;
    /**
     * Refers to the shipment term at the loading of the cargo into the container. Options are defined in the Cargo Movement Type entity.
     * - FCL (Full Container Load)
     * - LCL (Less than Container Load)
     * - BB (Break Bulk)
     * @type {string}
     * @memberof BookingRequest
     */
    cargoMovementTypeAtOrigin?: BookingRequestCargoMovementTypeAtOriginEnum;
    /**
     * Refers to the shipment term at the unloading of the cargo out of the container. Options are defined in the Cargo Movement Type entity.
     * - FCL (Full Container Load)
     * - LCL (Less than Container Load)
     * - BB (Break Bulk)
     * @type {string}
     * @memberof BookingRequest
     */
    cargoMovementTypeAtDestination?: BookingRequestCargoMovementTypeAtDestinationEnum;
    /**
     * Reference number for agreement between shipper and carrier through which the shipper commits to provide a certain minimum quantity of cargo over a fixed period, and the carrier commits to a certain rate or rate schedule.
     * @type {string}
     * @memberof BookingRequest
     */
    serviceContractReference?: string;
    /**
     * Indication whether the shipper agrees to load part of the shipment in case where not all of the cargo is delivered within cut-off.
     * @type {boolean}
     * @memberof BookingRequest
     */
    isPartialLoadAllowed?: boolean | null;
    /**
     * Information provided by the shipper whether an export declaration is required for this particular shipment/commodity/destination.
     * @type {boolean}
     * @memberof BookingRequest
     */
    isExportDeclarationRequired?: boolean | null;
    /**
     * Information provided by the shipper whether an import permit or license is required for this particular shipment/commodity/destination.
     * @type {boolean}
     * @memberof BookingRequest
     */
    isImportLicenseRequired?: boolean | null;
    /**
     * Indicates if an alternate equipment type can be provided by the carrier.
     * @type {boolean}
     * @memberof BookingRequest
     */
    isEquipmentSubstitutionAllowed?: boolean | null;
    /**
     * A government document permitting designated goods to be shipped out of the country.  Reference number assigned by an issuing authority to an Export License. The export license must be valid at time of departure. Required if Export declaration required is true.
     * @type {string}
     * @memberof BookingRequest
     */
    exportDeclarationReference?: string;
    /**
     * A certificate, issued by countries exercising import controls, that permits importation of the articles stated in the license. Reference number assigned by an issuing authority to an Import License. The import license number must be valid at time of arrival. Required if import license required is true.
     * @type {string}
     * @memberof BookingRequest
     */
    importLicenseReference?: string;
    /**
     * Information provided by the shipper to identify whether pricing for the shipment has been agreed via a contract or a quotation reference. Mandatory if service contract (owner) is not provided.
     * @type {string}
     * @memberof BookingRequest
     */
    contractQuotationReference?: string;
    /**
     * The date when the shipment is expected to be loaded on board a vessel as provided by the shipper or its agent. If vessel/voyage or expected date of arrival is not provided, this is mandatory
     * @type {string}
     * @memberof BookingRequest
     */
    expectedDepartureDate?: string;
    /**
     * The start date (provided as a range together with `expectedArrivalAtPlaceOfDeliveryEndDate`) for when the shipment is expected to arrive at final destination. If vessel/voyage or `expectedDepartureDate` is not provided, this is mandatory together with `expectedArrivalAtPlaceOfDeliveryEndDate`
     * @type {string}
     * @memberof BookingRequest
     */
    expectedArrivalAtPlaceOfDeliveryStartDate?: string;
    /**
     * The end date (provided as a range together with `expectedArrivalAtPlaceOfDeliveryStartDate`) for when the shipment is expected to arrive at final destination. If vessel/voyage or `expectedDepartureDate` is not provided, this is mandatory together with `expectedArrivalAtPlaceOfDeliveryStartDate`
     * @type {string}
     * @memberof BookingRequest
     */
    expectedArrivalAtPlaceOfDeliveryEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    carrierExportVoyageNumber?: string;
    /**
     * Specifying which communication channel is to be used for this booking e.g.
     * - EI (EDI transmission)
     * - EM (Email)
     * - AO (API)
     * @type {string}
     * @memberof BookingRequest
     */
    communicationChannelCode?: BookingRequestCommunicationChannelCodeEnum;
    /**
     * Transport obligations, costs and risks as agreed between buyer and seller.
     * - FCA (Free Carrier)
     * - FOB (Free on Board)
     * @type {string}
     * @memberof BookingRequest
     */
    incoTerms?: BookingRequestIncoTermsEnum;
    /**
     * The mode of transport as defined by DCSA.
     * @type {string}
     * @memberof BookingRequest
     */
    modeOfTransport?: BookingRequestModeOfTransportEnum;
    /**
     * Indicates whether freight & charges are due for payment before the shipment is effected, practically before the transport document is released to shipper (Prepaid) or before the shipment is finalized meaning cargo released to consignee (Collect)
     * - PRE (Prepaid)
     * - COL (Collect)
     * @type {string}
     * @memberof BookingRequest
     */
    paymentTermCode?: BookingRequestPaymentTermCodeEnum;
    /**
     * The name of the Vessel given by the Vessel Operator and registered with IMO.
     * @type {string}
     * @memberof BookingRequest
     */
    vesselName?: string;
    /**
     * The unique reference for a registered Vessel. The reference is the International Maritime Organisation (IMO) number, also sometimes known as the Lloyd&apos;s register code, which does not change during the lifetime of the vessel
     * @type {string}
     * @memberof BookingRequest
     */
    vesselIMONumber?: string;
    /**
     * The date of the voyage charter party under which the bill of lading is issued.
     * @type {string}
     * @memberof BookingRequest
     */
    charterPartyDate?: string;
    /**
     * Specifying which eBL type will be targeted
     * - BIMCO 
     * - DCSA
     * @type {string}
     * @memberof BookingRequest
     */
    eblType?: BookingRequestEblTypeEnum;
}


/**
 * @export
 */
export const BookingRequestReceiptTypeAtOriginEnum = {
    Cy: 'CY',
    Sd: 'SD',
    Cfs: 'CFS'
} as const;
export type BookingRequestReceiptTypeAtOriginEnum = typeof BookingRequestReceiptTypeAtOriginEnum[keyof typeof BookingRequestReceiptTypeAtOriginEnum];

/**
 * @export
 */
export const BookingRequestDeliveryTypeAtDestinationEnum = {
    Cy: 'CY',
    Sd: 'SD',
    Cfs: 'CFS'
} as const;
export type BookingRequestDeliveryTypeAtDestinationEnum = typeof BookingRequestDeliveryTypeAtDestinationEnum[keyof typeof BookingRequestDeliveryTypeAtDestinationEnum];

/**
 * @export
 */
export const BookingRequestCargoMovementTypeAtOriginEnum = {
    Fcl: 'FCL',
    Lcl: 'LCL',
    Bb: 'BB'
} as const;
export type BookingRequestCargoMovementTypeAtOriginEnum = typeof BookingRequestCargoMovementTypeAtOriginEnum[keyof typeof BookingRequestCargoMovementTypeAtOriginEnum];

/**
 * @export
 */
export const BookingRequestCargoMovementTypeAtDestinationEnum = {
    Fcl: 'FCL',
    Lcl: 'LCL',
    Bb: 'BB'
} as const;
export type BookingRequestCargoMovementTypeAtDestinationEnum = typeof BookingRequestCargoMovementTypeAtDestinationEnum[keyof typeof BookingRequestCargoMovementTypeAtDestinationEnum];

/**
 * @export
 */
export const BookingRequestCommunicationChannelCodeEnum = {
    Ei: 'EI',
    Em: 'EM',
    Ao: 'AO'
} as const;
export type BookingRequestCommunicationChannelCodeEnum = typeof BookingRequestCommunicationChannelCodeEnum[keyof typeof BookingRequestCommunicationChannelCodeEnum];

/**
 * @export
 */
export const BookingRequestIncoTermsEnum = {
    Fca: 'FCA',
    Fob: 'FOB'
} as const;
export type BookingRequestIncoTermsEnum = typeof BookingRequestIncoTermsEnum[keyof typeof BookingRequestIncoTermsEnum];

/**
 * @export
 */
export const BookingRequestModeOfTransportEnum = {
    Vessel: 'VESSEL',
    Rail: 'RAIL',
    Truck: 'TRUCK',
    Barge: 'BARGE'
} as const;
export type BookingRequestModeOfTransportEnum = typeof BookingRequestModeOfTransportEnum[keyof typeof BookingRequestModeOfTransportEnum];

/**
 * @export
 */
export const BookingRequestPaymentTermCodeEnum = {
    Pre: 'PRE',
    Col: 'COL'
} as const;
export type BookingRequestPaymentTermCodeEnum = typeof BookingRequestPaymentTermCodeEnum[keyof typeof BookingRequestPaymentTermCodeEnum];

/**
 * @export
 */
export const BookingRequestEblTypeEnum = {
    Bimco: 'BIMCO',
    Dcsa: 'DCSA'
} as const;
export type BookingRequestEblTypeEnum = typeof BookingRequestEblTypeEnum[keyof typeof BookingRequestEblTypeEnum];


/**
 * Check if a given object implements the BookingRequest interface.
 */
export function instanceOfBookingRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shipmentId" in value;

    return isInstance;
}

export function BookingRequestFromJSON(json: any): BookingRequest {
    return BookingRequestFromJSONTyped(json, false);
}

export function BookingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'receiptTypeAtOrigin': !exists(json, 'receiptTypeAtOrigin') ? undefined : json['receiptTypeAtOrigin'],
        'deliveryTypeAtDestination': !exists(json, 'deliveryTypeAtDestination') ? undefined : json['deliveryTypeAtDestination'],
        'cargoMovementTypeAtOrigin': !exists(json, 'cargoMovementTypeAtOrigin') ? undefined : json['cargoMovementTypeAtOrigin'],
        'cargoMovementTypeAtDestination': !exists(json, 'cargoMovementTypeAtDestination') ? undefined : json['cargoMovementTypeAtDestination'],
        'serviceContractReference': !exists(json, 'serviceContractReference') ? undefined : json['serviceContractReference'],
        'isPartialLoadAllowed': !exists(json, 'isPartialLoadAllowed') ? undefined : json['isPartialLoadAllowed'],
        'isExportDeclarationRequired': !exists(json, 'isExportDeclarationRequired') ? undefined : json['isExportDeclarationRequired'],
        'isImportLicenseRequired': !exists(json, 'isImportLicenseRequired') ? undefined : json['isImportLicenseRequired'],
        'isEquipmentSubstitutionAllowed': !exists(json, 'isEquipmentSubstitutionAllowed') ? undefined : json['isEquipmentSubstitutionAllowed'],
        'exportDeclarationReference': !exists(json, 'exportDeclarationReference') ? undefined : json['exportDeclarationReference'],
        'importLicenseReference': !exists(json, 'importLicenseReference') ? undefined : json['importLicenseReference'],
        'contractQuotationReference': !exists(json, 'contractQuotationReference') ? undefined : json['contractQuotationReference'],
        'expectedDepartureDate': !exists(json, 'expectedDepartureDate') ? undefined : json['expectedDepartureDate'],
        'expectedArrivalAtPlaceOfDeliveryStartDate': !exists(json, 'expectedArrivalAtPlaceOfDeliveryStartDate') ? undefined : json['expectedArrivalAtPlaceOfDeliveryStartDate'],
        'expectedArrivalAtPlaceOfDeliveryEndDate': !exists(json, 'expectedArrivalAtPlaceOfDeliveryEndDate') ? undefined : json['expectedArrivalAtPlaceOfDeliveryEndDate'],
        'carrierExportVoyageNumber': !exists(json, 'carrierExportVoyageNumber') ? undefined : json['carrierExportVoyageNumber'],
        'communicationChannelCode': !exists(json, 'communicationChannelCode') ? undefined : json['communicationChannelCode'],
        'incoTerms': !exists(json, 'incoTerms') ? undefined : json['incoTerms'],
        'modeOfTransport': !exists(json, 'modeOfTransport') ? undefined : json['modeOfTransport'],
        'paymentTermCode': !exists(json, 'paymentTermCode') ? undefined : json['paymentTermCode'],
        'vesselName': !exists(json, 'vesselName') ? undefined : json['vesselName'],
        'vesselIMONumber': !exists(json, 'vesselIMONumber') ? undefined : json['vesselIMONumber'],
        'charterPartyDate': !exists(json, 'charterPartyDate') ? undefined : json['charterPartyDate'],
        'eblType': !exists(json, 'eblType') ? undefined : json['eblType'],
    };
}

export function BookingRequestToJSON(value?: BookingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipmentId': value.shipmentId,
        'receiptTypeAtOrigin': value.receiptTypeAtOrigin,
        'deliveryTypeAtDestination': value.deliveryTypeAtDestination,
        'cargoMovementTypeAtOrigin': value.cargoMovementTypeAtOrigin,
        'cargoMovementTypeAtDestination': value.cargoMovementTypeAtDestination,
        'serviceContractReference': value.serviceContractReference,
        'isPartialLoadAllowed': value.isPartialLoadAllowed,
        'isExportDeclarationRequired': value.isExportDeclarationRequired,
        'isImportLicenseRequired': value.isImportLicenseRequired,
        'isEquipmentSubstitutionAllowed': value.isEquipmentSubstitutionAllowed,
        'exportDeclarationReference': value.exportDeclarationReference,
        'importLicenseReference': value.importLicenseReference,
        'contractQuotationReference': value.contractQuotationReference,
        'expectedDepartureDate': value.expectedDepartureDate,
        'expectedArrivalAtPlaceOfDeliveryStartDate': value.expectedArrivalAtPlaceOfDeliveryStartDate,
        'expectedArrivalAtPlaceOfDeliveryEndDate': value.expectedArrivalAtPlaceOfDeliveryEndDate,
        'carrierExportVoyageNumber': value.carrierExportVoyageNumber,
        'communicationChannelCode': value.communicationChannelCode,
        'incoTerms': value.incoTerms,
        'modeOfTransport': value.modeOfTransport,
        'paymentTermCode': value.paymentTermCode,
        'vesselName': value.vesselName,
        'vesselIMONumber': value.vesselIMONumber,
        'charterPartyDate': value.charterPartyDate,
        'eblType': value.eblType,
    };
}

