import { ReactElement } from 'react';
import StepForm from '../components/StepForm/StepForm';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import { useLocation } from 'react-router';
import { ShipmentResponse } from '../generated';
import { NotFound } from './NotFound';
import { FormProvider, useForm } from 'react-hook-form';

export const BookingRequest = (): ReactElement => {
  const location = useLocation();
  const methods = useForm();

  if (location.state == null) {
    return <NotFound />;
  }

  const data = location.state.shipment as ShipmentResponse;

  return (
    <>
      <Breadcrumbs style={{ fontSize: 35 }} aria-label="breadcrumb">
        <Link underline="hover" component={RouterLink} color="inherit" to="/">
          Shipments
        </Link>
        <Link
          underline="hover"
          component={RouterLink}
          color="text.primary"
          to={`/shipments/${data.id}`}
        >
          {data.name}
        </Link>
      </Breadcrumbs>

      <Container component="main" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <FormProvider {...methods}>
            <StepForm {...data} />
          </FormProvider>
        </Paper>
      </Container>
    </>
  );
};
