/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartyData } from './PartyData';
import {
    PartyDataFromJSON,
    PartyDataFromJSONTyped,
    PartyDataToJSON,
} from './PartyData';
import type { PartyFunction } from './PartyFunction';
import {
    PartyFunctionFromJSON,
    PartyFunctionFromJSONTyped,
    PartyFunctionToJSON,
} from './PartyFunction';

/**
 * 
 * @export
 * @interface DocumentPartyRequest
 */
export interface DocumentPartyRequest {
    /**
     * 
     * @type {PartyData}
     * @memberof DocumentPartyRequest
     */
    party: PartyData;
    /**
     * 
     * @type {PartyFunction}
     * @memberof DocumentPartyRequest
     */
    partyFunction: PartyFunction;
    /**
     * If switch to paper is needed then the `displayedAddress` object MUST be provided. 
     * In case it is missing it is not possible to switch the B/L to paper later in the process.
     * @type {Array<string>}
     * @memberof DocumentPartyRequest
     */
    displayedAddress?: Array<string>;
    /**
     * Used to decide whether the party will be notified of the arrival of the cargo.
     * @type {boolean}
     * @memberof DocumentPartyRequest
     */
    isToBeNotified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentPartyRequest
     */
    bookingId?: string;
}

/**
 * Check if a given object implements the DocumentPartyRequest interface.
 */
export function instanceOfDocumentPartyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "party" in value;
    isInstance = isInstance && "partyFunction" in value;

    return isInstance;
}

export function DocumentPartyRequestFromJSON(json: any): DocumentPartyRequest {
    return DocumentPartyRequestFromJSONTyped(json, false);
}

export function DocumentPartyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentPartyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'party': PartyDataFromJSON(json['party']),
        'partyFunction': PartyFunctionFromJSON(json['partyFunction']),
        'displayedAddress': !exists(json, 'displayedAddress') ? undefined : json['displayedAddress'],
        'isToBeNotified': !exists(json, 'isToBeNotified') ? undefined : json['isToBeNotified'],
        'bookingId': !exists(json, 'bookingId') ? undefined : json['bookingId'],
    };
}

export function DocumentPartyRequestToJSON(value?: DocumentPartyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'party': PartyDataToJSON(value.party),
        'partyFunction': PartyFunctionToJSON(value.partyFunction),
        'displayedAddress': value.displayedAddress,
        'isToBeNotified': value.isToBeNotified,
        'bookingId': value.bookingId,
    };
}

