/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocumentResponse,
} from '../models';
import {
    DocumentResponseFromJSON,
    DocumentResponseToJSON,
} from '../models';

export interface VaultDeleteRequest {
    id: string;
    shipmentId?: string;
}

export interface VaultGetRequest {
    id: string;
}

export interface VaultPostRequest {
    shipmentId?: string;
    docType?: string;
    file?: Blob;
}

/**
 * 
 */
export class VaultApi extends runtime.BaseAPI {

    /**
     * Delete a single document
     */
    async vaultDeleteRaw(requestParameters: VaultDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vaultDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.shipmentId !== undefined) {
            queryParameters['shipmentId'] = requestParameters.shipmentId;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/vault`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single document
     */
    async vaultDelete(requestParameters: VaultDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vaultDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve a single document
     */
    async vaultGetRaw(requestParameters: VaultGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vaultGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/vault`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a single document
     */
    async vaultGet(requestParameters: VaultGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentResponse> {
        const response = await this.vaultGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new document to the vault
     */
    async vaultPostRaw(requestParameters: VaultPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.shipmentId !== undefined) {
            queryParameters['shipmentId'] = requestParameters.shipmentId;
        }

        if (requestParameters.docType !== undefined) {
            queryParameters['docType'] = requestParameters.docType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/vault`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a new document to the vault
     */
    async vaultPost(requestParameters: VaultPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.vaultPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
