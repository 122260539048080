import { ReactElement, useState } from 'react';
import { partiesApi } from '../../api/partiesApi';
import { SubmitHandler, useForm, UseFormRegister } from 'react-hook-form';
import { PartyRequest, PartyResponse } from '../../generated';
import { NotFound } from '../../pages/NotFound';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Breadcrumbs, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { getAuthHeader } from '../../utils/getAuthHeader';

async function fetchParty(id: string): Promise<PartyResponse> {
  const authHeader = await getAuthHeader();
  return partiesApi.partiesIdGet({ id }, { headers: authHeader });
}

async function fetchMyParties(): Promise<Array<PartyResponse>> {
  const authHeader = await getAuthHeader();
  return partiesApi.partiesGet({ headers: authHeader });
}

export const PartyEdit = (): ReactElement => {
  // This id could either be a public key (e.g. ethereum address) or the actual id of the party.
  // We need to be able to handle both here.
  // In a subsequent call to fetchParty, we will obtain the actual party id.
  const { idOrAddress } = useParams();
  let partyIdOrAddress = '';
  if (idOrAddress !== undefined) {
    partyIdOrAddress = idOrAddress;
  }
  const [partyId, setPartyId] = useState<string>('');

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PartyRequest>();

  const {
    isError: partyError,
    isLoading: partyIsLoading,
    data: partyData,
  } = useQuery(['party', partyIdOrAddress], async () => {
    // Fetch party data
    const data = await fetchParty(partyIdOrAddress);

    setPartyId(data.id);
    // Set form values
    setValue('address', data.address);
    setValue('identifyingCodes', data.identifyingCodes || []);
    setValue('partyContactDetails', data.partyContactDetails || []);
    setValue('partyName', data.partyName);
    setValue('taxReference1', data.taxReference1);
    setValue('taxReference2', data.taxReference2);
    setValue('publicKey', data.publicKey);

    return data;
  });

  const navigate = useNavigate();
  if (partyId === undefined) {
    return <p>Party not found</p>;
  }

  if (partyIsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }
  if (partyError) {
    return <NotFound />;
  }

  const onSubmit: SubmitHandler<PartyRequest> = async (newPartyData) => {
    try {
      const myParties = await fetchMyParties();
      const partyWithSamePublicKey = myParties.find(
        (party) => party.publicKey === newPartyData.publicKey,
      );

      if (partyWithSamePublicKey && partyId !== partyWithSamePublicKey.id) {
        toast.error('A party with the same publicKey already exists');
      } else {
        try {
          const authHeader = await getAuthHeader();
          await partiesApi.partiesIdPut(
            { id: partyId, partyRequest: newPartyData },
            { headers: authHeader },
          );
          toast.success('Successfully updated party details');
          queryClient.invalidateQueries(['party', partyId]);
          queryClient.invalidateQueries(['parties']);
          navigate('/address-book');
        } catch (e) {
          toast.error('An error occured during updating party');
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const renderTextField = (
    // eslint-disable-next-line
    register: UseFormRegister<any>,
    id: string,
    label: string,
    required = false,
  ) => (
    <TextField
      {...register(id, { required })}
      margin="dense"
      id={id}
      label={label}
      fullWidth
      variant="standard"
      InputLabelProps={{ shrink: true }}
    />
  );

  const editForm = (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Party details</Typography>
        <TextField
          {...register('partyName', { required: true })}
          margin="dense"
          id="partyName"
          label="Party Name"
          fullWidth
          variant="standard"
          InputLabelProps={{ shrink: true }}
          error={!!errors.partyName}
          helperText={errors.partyName && 'Field is required'}
        />
        {renderTextField(register, 'taxReference1', 'Tax Reference 1')}
        {renderTextField(register, 'taxReference2', 'Tax Reference 2')}
        {renderTextField(register, 'publicKey', 'Public Key')}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Address</Typography>
        {renderTextField(register, 'address.addressName', 'Address Name')}
        {renderTextField(register, 'address.streetName', 'Street Name')}
        {renderTextField(register, 'address.streetNumber', 'Street Number')}
        {renderTextField(register, 'address.floor', 'Floor')}
        {renderTextField(register, 'address.postCode', 'Post Code')}
        {renderTextField(register, 'address.cityName', 'City')}
        {renderTextField(register, 'address.stateRegion', 'State/Region')}
        {renderTextField(register, 'address.country', 'Country')}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Contact Details</Typography>

        <TextField
          {...register('partyContactDetails.0.name', { required: true })}
          margin="dense"
          id="partyContactDetails.0.name"
          label="Name"
          InputLabelProps={{ shrink: true }}
          error={!!errors.partyContactDetails?.[0]?.name}
          helperText={errors.partyContactDetails?.[0]?.name && 'Field is required'}
          fullWidth
          variant="standard"
        />
        {renderTextField(register, 'partyContactDetails.0.phone', 'Phone', false)}
        {renderTextField(register, 'partyContactDetails.0.email', 'Email', false)}
        {renderTextField(register, 'partyContactDetails.0.url', 'URL', false)}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Identifying codes</Typography>

        {renderTextField(
          register,
          `identifyingCodes.0.dCSAResponsibleAgencyCode`,
          'DCSA Responsible Agency Code',
          false,
        )}
        {renderTextField(register, `identifyingCodes.0.partyCode`, 'Party Code', false)}
        {renderTextField(register, `identifyingCodes.0.codeListName`, 'Codelist Name', false)}
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" type="submit">
          Save Party
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Breadcrumbs style={{ fontSize: 35 }} aria-label="breadcrumb">
        <Link underline="hover" component={RouterLink} color="inherit" to="/address-book">
          Address book
        </Link>
        <Link underline="none" color="text.primary">
          Editing document party
        </Link>
        <Link underline="none" color="text.primary">
          {partyData.partyName}
        </Link>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onSubmit)}>{editForm}</form>
    </>
  );
};
