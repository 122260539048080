import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { BookingResponse, ShipmentLocationRequest } from '../../generated';
import { toast } from 'react-toastify';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { shipmentLocationsApi } from '../../api/shipmentLocationsApi';
import { shipmentLocationTypeCodes } from '../../utils/shipmentLocationTypeCode';
import { ShipmentLocationsDataGrid } from '../ShipmentLocationsDataGrid/ShipmentLocationsDataGrid';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const AddShipmentLocationsForm = ({
  booking,
}: {
  booking: BookingResponse;
}): ReactElement => {
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ShipmentLocationRequest>({ mode: 'onChange' });

  const addShipmentLocation = useMutation(
    async (shipmentLocationData: ShipmentLocationRequest) => {
      const authHeader = await getAuthHeader();
      return shipmentLocationsApi.shipmentLocationsPost(
        { shipmentLocationRequest: shipmentLocationData },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipment']);
        toast.success('Successfully added shipment location');
        handleFormClick();
        // reset form values
        reset();
      },
    },
  );

  const onSubmit: SubmitHandler<ShipmentLocationRequest> = (newShipmentLocationsData) => {
    newShipmentLocationsData.bookingId = booking.id;
    addShipmentLocation.mutate(newShipmentLocationsData);
  };

  const handleFormClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={handleFormClick}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Add shipment location</DialogTitle>
          <DialogContent>
            <Grid container direction={'column'} spacing={3}>
              <Grid item>
                <TextField
                  {...register('location.locationName')}
                  margin="dense"
                  id="location"
                  label="Location name"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item>
                <TextField
                  {...register('location.uNLocationCode', { maxLength: 5 })}
                  margin="dense"
                  id="location"
                  label="UN location code"
                  fullWidth
                  error={Boolean(errors.location?.uNLocationCode)}
                  helperText={
                    errors.location?.uNLocationCode && 'Max length of 5 characters (e.g. NLRTM)'
                  }
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item>
                <TextField
                  select
                  fullWidth
                  defaultValue=""
                  label="Shipment location type code"
                  inputProps={register('shipmentLocationTypeCode', {
                    required: true,
                  })}
                  InputLabelProps={{ shrink: true }}
                >
                  {shipmentLocationTypeCodes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFormClick}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Grid item xs={4}>
        <Typography sx={{ mt: 4 }} variant="h5" gutterBottom>
          Shipment locations{' '}
          {booking.status === 'DRFT' && (
            <IconButton
              style={{
                position: 'relative',
                bottom: '2px',
                right: '10px',
              }}
              color="primary"
              onClick={handleFormClick}
            >
              <AddCircleOutlineOutlined />
            </IconButton>
          )}
        </Typography>

        {booking.shipmentLocations !== undefined ? (
          <ShipmentLocationsDataGrid booking={booking}></ShipmentLocationsDataGrid>
        ) : (
          <Typography>(No shipment locations attached yet)</Typography>
        )}
      </Grid>
    </>
  );
};
