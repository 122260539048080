import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { ReactElement } from 'react';
import { shipmentsApi } from '../../api/shipmentsApi';
import { ShipmentRequest } from '../../generated';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const ShipmentsFooter = (): ReactElement => {
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const createShipment = useMutation(
    async (shipment: ShipmentRequest) => {
      const authHeader = await getAuthHeader();

      return shipmentsApi.shipmentsPost({ shipmentRequest: shipment }, { headers: authHeader });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipments']);
      },
    },
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      newName: { value: string };
    };

    const shipment: ShipmentRequest = {
      name: target.newName.value,
    };
    createShipment.mutate(shipment);

    setOpen(false);
  };

  return (
    <GridFooterContainer>
      <Button sx={{ margin: 1 }} variant="contained" onClick={handleClickOpen}>
        Add new shipment
      </Button>
      <Dialog open={open} onClose={handleCancel}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create Shipment</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a shipment please enter the shipment&apos;s name and submit the form.
            </DialogContentText>
            <TextField
              margin="dense"
              name="newName"
              label="Shipment Name"
              type="text"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="submit">Create</Button>
          </DialogActions>
        </form>
      </Dialog>

      <GridFooter
        sx={{
          border: 'none',
        }}
      />
    </GridFooterContainer>
  );
};
