import React, { ReactElement, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DataGrid, GridColDef, GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import { BookingResponse, DocumentPartyRequest, DocumentPartyResponse } from '../../generated';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { documentPartiesApi } from '../../api/documentPartiesApi';
import { AddressBookSelect } from '../AddressBookSelect';
import { NotFound } from '../../pages/NotFound';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const DcsaDocumentParties = ({ booking }: { booking: BookingResponse }): ReactElement => {
  const [creating, setCreate] = useState(false);
  const [idc, setIdc] = React.useState(false);

  const methods = useForm<DocumentPartyRequest>();
  const register = methods.register;
  const handleSubmit = methods.handleSubmit;
  const reset = methods.reset;

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const {
    data: documentParties,
    isError: documentPartiesError,
    isLoading: documentPartiesIsLoading,
  } = useQuery(['documentparties', booking.id], () => fetchDocumentParties(booking.id));

  const createDocumentParty = useMutation(
    async (documentPartyData: DocumentPartyRequest) => {
      const authHeader = await getAuthHeader();
      return documentPartiesApi.documentPartiesPost(
        { documentPartyRequest: documentPartyData },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => {
        toast.success('Successfully created document party');
        setCreate(!creating);
        queryClient.invalidateQueries(['documentparties']);
      },
      onError: () => {
        toast.error('There was an error creating this document party');
      },
    },
  );

  const deleteDocumentParty = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();
      return documentPartiesApi.documentPartiesIdDelete({ id: id }, { headers: authHeader });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['documentparties']);
        toast.success('Successfully deleted document party');
      },
    },
  );

  if (documentPartiesIsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }
  if (documentPartiesError) {
    return <NotFound />;
  }

  const columns: GridColDef[] = [
    {
      field: 'partyName',
      headerName: 'Party Name',
      width: 180,
      valueGetter: (params) => {
        return params.row.party.partyName;
      },
    },
    { field: 'partyFunction', headerName: 'Party Function', width: 180 },
    {
      field: 'contactPerson',
      headerName: 'Contact Person',
      width: 180,
      valueGetter: (params) => {
        return params.row.party.partyContactDetails[0].name;
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const deleteOnClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          deleteDocumentParty.mutate(params.row.id);
        };

        const viewOnClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          handleViewClick(params.row.id);
        };

        return (
          <>
            <Button
              style={{
                marginRight: '20px',
              }}
              variant="contained"
              onClick={viewOnClick}
            >
              View
            </Button>

            <Button variant="contained" color="error" onClick={deleteOnClick}>
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  const onSubmit: SubmitHandler<DocumentPartyRequest> = (newDocumentPartyData) => {
    newDocumentPartyData.bookingId = booking.id;
    if (!newDocumentPartyData.isToBeNotified) {
      newDocumentPartyData.isToBeNotified = false;
    }
    createDocumentParty.mutate(newDocumentPartyData);
    reset();
  };

  const handleViewClick = (id: string) => {
    navigate('/document-parties/' + id);
  };

  const handleReturnClick = () => {
    navigate(-1);
  };

  const handleClickCreate = () => {
    setCreate(!creating);
  };

  const handleResetClick = () => {
    reset();
  };

  const handleChange = () => {
    setIdc(!idc);
  };

  const footer = (): ReactElement => {
    return (
      <>
        <GridFooterContainer>
          <Button sx={{ margin: 1 }} variant="contained" onClick={handleClickCreate}>
            Create New Document Party
          </Button>
          <GridFooter
            sx={{
              border: 'none',
            }}
          />
        </GridFooterContainer>
      </>
    );
  };

  return (
    <>
      {creating ? (
        <>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container direction="row" rowSpacing={3}>
                <Grid item xs={3}>
                  Select from Address Book:
                  <AddressBookSelect index={-1} />
                </Grid>
                <Grid item xs={9}></Grid>
              </Grid>
              <Grid container direction="row" spacing={3}>
                <Grid item xs={2}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography>Party Details:</Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.partyName')}
                        required
                        margin="dense"
                        id="partyName"
                        label="Party Name"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.taxReference1')}
                        margin="dense"
                        id="taxReference1"
                        label="Tax Reference 1"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.taxReference2')}
                        margin="dense"
                        id="taxReference2"
                        label="Tax Reference 2"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.publicKey')}
                        margin="dense"
                        id="publicKey"
                        label="Public Key"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleClickCreate}>
                      Return
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography>Contact Details:</Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register(`party.partyContactDetails.${0}.name`)}
                        required
                        margin="dense"
                        id="contactName"
                        label="Name"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register(`party.partyContactDetails.${0}.email`)}
                        margin="dense"
                        id="contactEmail"
                        label="Email"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register(`party.partyContactDetails.${0}.phone`)}
                        margin="dense"
                        id="contactPhone"
                        label="Phone"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register(`party.partyContactDetails.${0}.url`)}
                        margin="dense"
                        id="contactUrl"
                        label="URL"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography>Address Details:</Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.address.addressName')}
                        margin="dense"
                        id="addressName"
                        label="Address Name"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.address.streetName')}
                        margin="dense"
                        id="streetName"
                        label="Street Name"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.address.streetNumber')}
                        margin="dense"
                        id="streetNumber"
                        label="Street Number"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.address.floor')}
                        margin="dense"
                        id="floor"
                        label="Floor"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.address.postCode')}
                        margin="dense"
                        id="postCode"
                        label="Post Code"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.address.cityName')}
                        margin="dense"
                        id="cityName"
                        label="City"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.address.stateRegion')}
                        margin="dense"
                        id="stateRegion"
                        label="State/Region"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('party.address.country')}
                        margin="dense"
                        id="country"
                        label="Country"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register(`displayedAddress.${0}`)}
                        margin="dense"
                        id="displayedAddress"
                        label="Single line address"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction="column">
                    <Grid item>
                      <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            sx={{
                              marginTop: -1,
                            }}
                            color="primary"
                            onChange={handleChange}
                          />
                        }
                        label="Add Identifying Codes?"
                      />
                      {idc && (
                        <>
                          <TextField
                            {...register(`party.identifyingCodes.${0}.dCSAResponsibleAgencyCode`)}
                            required
                            margin="dense"
                            id="dcsaCode"
                            label="DCSA Responsible Agency"
                            fullWidth
                            variant="standard"
                          />
                          <TextField
                            {...register(`party.identifyingCodes.${0}.partyCode`)}
                            required
                            margin="dense"
                            id="partyCode"
                            label="Party Code"
                            fullWidth
                            variant="standard"
                          />
                          <TextField
                            {...register(`party.identifyingCodes.${0}.codeListName`)}
                            margin="dense"
                            id="codeListName"
                            label="Code List Name"
                            fullWidth
                            variant="standard"
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography>Miscellaneous:</Typography>
                      <TextField
                        {...register(`partyFunction`)}
                        required
                        margin="dense"
                        id="partyFunction"
                        label="Party Function"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...register('isToBeNotified')}
                            id="isToBeNotified"
                            color="primary"
                          />
                        }
                        label="Party is to be notified?"
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained" onClick={handleResetClick}>
                        Reset Form
                      </Button>{' '}
                      <Button type="submit" variant="contained">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </>
      ) : (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Box sx={{ height: 500 }}>
              <DataGrid
                rows={documentParties}
                getRowId={(row) => row.id}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                localeText={{
                  noRowsLabel: 'This booking does not contain any document parties',
                }}
                components={{ Footer: footer }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleReturnClick}>
              Return
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

async function fetchDocumentParties(id: string): Promise<Array<DocumentPartyResponse>> {
  const authHeader = await getAuthHeader();
  return documentPartiesApi.documentPartiesBookingRequestIdGet({ id }, { headers: authHeader });
}
