import React, { ReactElement } from 'react';
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { bookingStatusToColour, formatBookingStatus } from '../../utils/bookingStatus';

export const ShipmentStatus = (props: shipmentStatusProps): ReactElement => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>Booking status</strong>
            </TableCell>
            <TableCell>
              <Chip
                color={bookingStatusToColour(props.bookingStatus)}
                label={formatBookingStatus(props.bookingStatus || '(N.A.)')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>eBL status</strong>
            </TableCell>
            <TableCell>
              <Chip
                color={bookingStatusToColour(props.eblStatus)}
                label={formatBookingStatus(props.eblStatus || '(N.A.)')}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface shipmentStatusProps {
  bookingStatus?: string;
  eblStatus?: string;
}
