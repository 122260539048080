import { ReactElement } from 'react';
import { BookingResponse } from '../../generated';
import { canBookingBeEdited } from '../../utils/bookingStatus';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';
import { Contacts } from '@mui/icons-material';

export const AddDocumentParties = ({ booking }: { booking: BookingResponse }): ReactElement => {
  const navigate = useNavigate();

  function handleNavigate() {
    navigate(`/booking-requests/` + booking.id + `/manage-document-parties`);
  }

  return (
    <>
      {canBookingBeEdited(booking) && (
        <Button
          onClick={handleNavigate}
          color="primary"
          variant="outlined"
          startIcon={<Contacts />}
        >
          Manage Document Parties
        </Button>
      )}
    </>
  );
};
