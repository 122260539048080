/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PartyRequest,
  PartyResponse,
} from '../models';
import {
    PartyRequestFromJSON,
    PartyRequestToJSON,
    PartyResponseFromJSON,
    PartyResponseToJSON,
} from '../models';

export interface PartiesIdDeleteRequest {
    id: string;
}

export interface PartiesIdGetRequest {
    id: string;
}

export interface PartiesIdPutRequest {
    id: string;
    partyRequest?: PartyRequest;
}

export interface PartiesPostRequest {
    partyRequest?: PartyRequest;
}

/**
 * 
 */
export class PartyApi extends runtime.BaseAPI {

    /**
     * Get all parties that the user has view access for. 
     */
    async partiesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PartyResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/parties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PartyResponseFromJSON));
    }

    /**
     * Get all parties that the user has view access for. 
     */
    async partiesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PartyResponse>> {
        const response = await this.partiesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete a Party from the database
     */
    async partiesIdDeleteRaw(requestParameters: PartiesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partiesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/parties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Party from the database
     */
    async partiesIdDelete(requestParameters: PartiesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.partiesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get Party by id or public key 
     */
    async partiesIdGetRaw(requestParameters: PartiesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PartyResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partiesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/parties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartyResponseFromJSON(jsonValue));
    }

    /**
     * Get Party by id or public key 
     */
    async partiesIdGet(requestParameters: PartiesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PartyResponse> {
        const response = await this.partiesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specified Party in the database
     */
    async partiesIdPutRaw(requestParameters: PartiesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partiesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/parties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartyRequestToJSON(requestParameters.partyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the specified Party in the database
     */
    async partiesIdPut(requestParameters: PartiesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.partiesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new Party to the database
     */
    async partiesPostRaw(requestParameters: PartiesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/parties`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PartyRequestToJSON(requestParameters.partyRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a new Party to the database
     */
    async partiesPost(requestParameters: PartiesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.partiesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
