/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingRequestsIdCancelPostRequest
 */
export interface BookingRequestsIdCancelPostRequest {
    /**
     * Short description of the reason for cancellation
     * @type {string}
     * @memberof BookingRequestsIdCancelPostRequest
     */
    reason?: string | null;
}

/**
 * Check if a given object implements the BookingRequestsIdCancelPostRequest interface.
 */
export function instanceOfBookingRequestsIdCancelPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BookingRequestsIdCancelPostRequestFromJSON(json: any): BookingRequestsIdCancelPostRequest {
    return BookingRequestsIdCancelPostRequestFromJSONTyped(json, false);
}

export function BookingRequestsIdCancelPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingRequestsIdCancelPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function BookingRequestsIdCancelPostRequestToJSON(value?: BookingRequestsIdCancelPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
    };
}

