/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingResponseAllOfCarrierMessages } from './BookingResponseAllOfCarrierMessages';
import {
    BookingResponseAllOfCarrierMessagesFromJSON,
    BookingResponseAllOfCarrierMessagesFromJSONTyped,
    BookingResponseAllOfCarrierMessagesToJSON,
} from './BookingResponseAllOfCarrierMessages';
import type { CommodityResponse } from './CommodityResponse';
import {
    CommodityResponseFromJSON,
    CommodityResponseFromJSONTyped,
    CommodityResponseToJSON,
} from './CommodityResponse';
import type { DocumentParty1 } from './DocumentParty1';
import {
    DocumentParty1FromJSON,
    DocumentParty1FromJSONTyped,
    DocumentParty1ToJSON,
} from './DocumentParty1';
import type { DocumentStatus } from './DocumentStatus';
import {
    DocumentStatusFromJSON,
    DocumentStatusFromJSONTyped,
    DocumentStatusToJSON,
} from './DocumentStatus';
import type { ShipmentLocationResponse } from './ShipmentLocationResponse';
import {
    ShipmentLocationResponseFromJSON,
    ShipmentLocationResponseFromJSONTyped,
    ShipmentLocationResponseToJSON,
} from './ShipmentLocationResponse';
import type { UserSummary } from './UserSummary';
import {
    UserSummaryFromJSON,
    UserSummaryFromJSONTyped,
    UserSummaryToJSON,
} from './UserSummary';

/**
 * 
 * @export
 * @interface BookingResponse
 */
export interface BookingResponse {
    /**
     * 
     * @type {string}
     * @memberof BookingResponse
     */
    shipmentId: string;
    /**
     * Indicates the type of service offered at Origin. Options are defined in the Receipt/Delivery entity.
     * - CY (Container yard (incl. rail ramp))
     * - SD (Store Door)
     * - CFS (Container Freight Station)
     * @type {string}
     * @memberof BookingResponse
     */
    receiptTypeAtOrigin?: BookingResponseReceiptTypeAtOriginEnum;
    /**
     * Indicates the type of service offered at Destination. Options are defined in the Receipt/Delivery entity.
     * - CY (Container yard (incl. rail ramp))
     * - SD (Store Door)
     * - CFS (Container Freight Station)
     * @type {string}
     * @memberof BookingResponse
     */
    deliveryTypeAtDestination?: BookingResponseDeliveryTypeAtDestinationEnum;
    /**
     * Refers to the shipment term at the loading of the cargo into the container. Options are defined in the Cargo Movement Type entity.
     * - FCL (Full Container Load)
     * - LCL (Less than Container Load)
     * - BB (Break Bulk)
     * @type {string}
     * @memberof BookingResponse
     */
    cargoMovementTypeAtOrigin?: BookingResponseCargoMovementTypeAtOriginEnum;
    /**
     * Refers to the shipment term at the unloading of the cargo out of the container. Options are defined in the Cargo Movement Type entity.
     * - FCL (Full Container Load)
     * - LCL (Less than Container Load)
     * - BB (Break Bulk)
     * @type {string}
     * @memberof BookingResponse
     */
    cargoMovementTypeAtDestination?: BookingResponseCargoMovementTypeAtDestinationEnum;
    /**
     * Reference number for agreement between shipper and carrier through which the shipper commits to provide a certain minimum quantity of cargo over a fixed period, and the carrier commits to a certain rate or rate schedule.
     * @type {string}
     * @memberof BookingResponse
     */
    serviceContractReference?: string;
    /**
     * Indication whether the shipper agrees to load part of the shipment in case where not all of the cargo is delivered within cut-off.
     * @type {boolean}
     * @memberof BookingResponse
     */
    isPartialLoadAllowed?: boolean | null;
    /**
     * Information provided by the shipper whether an export declaration is required for this particular shipment/commodity/destination.
     * @type {boolean}
     * @memberof BookingResponse
     */
    isExportDeclarationRequired?: boolean | null;
    /**
     * Information provided by the shipper whether an import permit or license is required for this particular shipment/commodity/destination.
     * @type {boolean}
     * @memberof BookingResponse
     */
    isImportLicenseRequired?: boolean | null;
    /**
     * Indicates if an alternate equipment type can be provided by the carrier.
     * @type {boolean}
     * @memberof BookingResponse
     */
    isEquipmentSubstitutionAllowed?: boolean | null;
    /**
     * A government document permitting designated goods to be shipped out of the country.  Reference number assigned by an issuing authority to an Export License. The export license must be valid at time of departure. Required if Export declaration required is true.
     * @type {string}
     * @memberof BookingResponse
     */
    exportDeclarationReference?: string;
    /**
     * A certificate, issued by countries exercising import controls, that permits importation of the articles stated in the license. Reference number assigned by an issuing authority to an Import License. The import license number must be valid at time of arrival. Required if import license required is true.
     * @type {string}
     * @memberof BookingResponse
     */
    importLicenseReference?: string;
    /**
     * Information provided by the shipper to identify whether pricing for the shipment has been agreed via a contract or a quotation reference. Mandatory if service contract (owner) is not provided.
     * @type {string}
     * @memberof BookingResponse
     */
    contractQuotationReference?: string;
    /**
     * The date when the shipment is expected to be loaded on board a vessel as provided by the shipper or its agent. If vessel/voyage or expected date of arrival is not provided, this is mandatory
     * @type {string}
     * @memberof BookingResponse
     */
    expectedDepartureDate?: string;
    /**
     * The start date (provided as a range together with `expectedArrivalAtPlaceOfDeliveryEndDate`) for when the shipment is expected to arrive at final destination. If vessel/voyage or `expectedDepartureDate` is not provided, this is mandatory together with `expectedArrivalAtPlaceOfDeliveryEndDate`
     * @type {string}
     * @memberof BookingResponse
     */
    expectedArrivalAtPlaceOfDeliveryStartDate?: string;
    /**
     * The end date (provided as a range together with `expectedArrivalAtPlaceOfDeliveryStartDate`) for when the shipment is expected to arrive at final destination. If vessel/voyage or `expectedDepartureDate` is not provided, this is mandatory together with `expectedArrivalAtPlaceOfDeliveryStartDate`
     * @type {string}
     * @memberof BookingResponse
     */
    expectedArrivalAtPlaceOfDeliveryEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingResponse
     */
    carrierExportVoyageNumber?: string;
    /**
     * Specifying which communication channel is to be used for this booking e.g.
     * - EI (EDI transmission)
     * - EM (Email)
     * - AO (API)
     * @type {string}
     * @memberof BookingResponse
     */
    communicationChannelCode?: BookingResponseCommunicationChannelCodeEnum;
    /**
     * Transport obligations, costs and risks as agreed between buyer and seller.
     * - FCA (Free Carrier)
     * - FOB (Free on Board)
     * @type {string}
     * @memberof BookingResponse
     */
    incoTerms?: BookingResponseIncoTermsEnum;
    /**
     * The mode of transport as defined by DCSA.
     * @type {string}
     * @memberof BookingResponse
     */
    modeOfTransport?: BookingResponseModeOfTransportEnum;
    /**
     * Indicates whether freight & charges are due for payment before the shipment is effected, practically before the transport document is released to shipper (Prepaid) or before the shipment is finalized meaning cargo released to consignee (Collect)
     * - PRE (Prepaid)
     * - COL (Collect)
     * @type {string}
     * @memberof BookingResponse
     */
    paymentTermCode?: BookingResponsePaymentTermCodeEnum;
    /**
     * The name of the Vessel given by the Vessel Operator and registered with IMO.
     * @type {string}
     * @memberof BookingResponse
     */
    vesselName?: string;
    /**
     * The unique reference for a registered Vessel. The reference is the International Maritime Organisation (IMO) number, also sometimes known as the Lloyd&apos;s register code, which does not change during the lifetime of the vessel
     * @type {string}
     * @memberof BookingResponse
     */
    vesselIMONumber?: string;
    /**
     * The date of the voyage charter party under which the bill of lading is issued.
     * @type {string}
     * @memberof BookingResponse
     */
    charterPartyDate?: string;
    /**
     * Specifying which eBL type will be targeted
     * - BIMCO 
     * - DCSA
     * @type {string}
     * @memberof BookingResponse
     */
    eblType?: BookingResponseEblTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingResponse
     */
    id: string;
    /**
     * 
     * @type {UserSummary}
     * @memberof BookingResponse
     */
    creator: UserSummary;
    /**
     * 
     * @type {number}
     * @memberof BookingResponse
     */
    creatorId: number;
    /**
     * 
     * @type {string}
     * @memberof BookingResponse
     */
    carrierRef?: string;
    /**
     * 
     * @type {Date}
     * @memberof BookingResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BookingResponse
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BookingResponse
     */
    deletedAt?: Date;
    /**
     * 
     * @type {DocumentStatus}
     * @memberof BookingResponse
     */
    status: DocumentStatus;
    /**
     * 
     * @type {Array<BookingResponseAllOfCarrierMessages>}
     * @memberof BookingResponse
     */
    carrierMessages?: Array<BookingResponseAllOfCarrierMessages>;
    /**
     * 
     * @type {Array<CommodityResponse>}
     * @memberof BookingResponse
     */
    commodities?: Array<CommodityResponse>;
    /**
     * 
     * @type {Array<ShipmentLocationResponse>}
     * @memberof BookingResponse
     */
    shipmentLocations?: Array<ShipmentLocationResponse>;
    /**
     * 
     * @type {Array<DocumentParty1>}
     * @memberof BookingResponse
     */
    documentParties?: Array<DocumentParty1>;
}


/**
 * @export
 */
export const BookingResponseReceiptTypeAtOriginEnum = {
    Cy: 'CY',
    Sd: 'SD',
    Cfs: 'CFS'
} as const;
export type BookingResponseReceiptTypeAtOriginEnum = typeof BookingResponseReceiptTypeAtOriginEnum[keyof typeof BookingResponseReceiptTypeAtOriginEnum];

/**
 * @export
 */
export const BookingResponseDeliveryTypeAtDestinationEnum = {
    Cy: 'CY',
    Sd: 'SD',
    Cfs: 'CFS'
} as const;
export type BookingResponseDeliveryTypeAtDestinationEnum = typeof BookingResponseDeliveryTypeAtDestinationEnum[keyof typeof BookingResponseDeliveryTypeAtDestinationEnum];

/**
 * @export
 */
export const BookingResponseCargoMovementTypeAtOriginEnum = {
    Fcl: 'FCL',
    Lcl: 'LCL',
    Bb: 'BB'
} as const;
export type BookingResponseCargoMovementTypeAtOriginEnum = typeof BookingResponseCargoMovementTypeAtOriginEnum[keyof typeof BookingResponseCargoMovementTypeAtOriginEnum];

/**
 * @export
 */
export const BookingResponseCargoMovementTypeAtDestinationEnum = {
    Fcl: 'FCL',
    Lcl: 'LCL',
    Bb: 'BB'
} as const;
export type BookingResponseCargoMovementTypeAtDestinationEnum = typeof BookingResponseCargoMovementTypeAtDestinationEnum[keyof typeof BookingResponseCargoMovementTypeAtDestinationEnum];

/**
 * @export
 */
export const BookingResponseCommunicationChannelCodeEnum = {
    Ei: 'EI',
    Em: 'EM',
    Ao: 'AO'
} as const;
export type BookingResponseCommunicationChannelCodeEnum = typeof BookingResponseCommunicationChannelCodeEnum[keyof typeof BookingResponseCommunicationChannelCodeEnum];

/**
 * @export
 */
export const BookingResponseIncoTermsEnum = {
    Fca: 'FCA',
    Fob: 'FOB'
} as const;
export type BookingResponseIncoTermsEnum = typeof BookingResponseIncoTermsEnum[keyof typeof BookingResponseIncoTermsEnum];

/**
 * @export
 */
export const BookingResponseModeOfTransportEnum = {
    Vessel: 'VESSEL',
    Rail: 'RAIL',
    Truck: 'TRUCK',
    Barge: 'BARGE'
} as const;
export type BookingResponseModeOfTransportEnum = typeof BookingResponseModeOfTransportEnum[keyof typeof BookingResponseModeOfTransportEnum];

/**
 * @export
 */
export const BookingResponsePaymentTermCodeEnum = {
    Pre: 'PRE',
    Col: 'COL'
} as const;
export type BookingResponsePaymentTermCodeEnum = typeof BookingResponsePaymentTermCodeEnum[keyof typeof BookingResponsePaymentTermCodeEnum];

/**
 * @export
 */
export const BookingResponseEblTypeEnum = {
    Bimco: 'BIMCO',
    Dcsa: 'DCSA'
} as const;
export type BookingResponseEblTypeEnum = typeof BookingResponseEblTypeEnum[keyof typeof BookingResponseEblTypeEnum];


/**
 * Check if a given object implements the BookingResponse interface.
 */
export function instanceOfBookingResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shipmentId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "creatorId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function BookingResponseFromJSON(json: any): BookingResponse {
    return BookingResponseFromJSONTyped(json, false);
}

export function BookingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'receiptTypeAtOrigin': !exists(json, 'receiptTypeAtOrigin') ? undefined : json['receiptTypeAtOrigin'],
        'deliveryTypeAtDestination': !exists(json, 'deliveryTypeAtDestination') ? undefined : json['deliveryTypeAtDestination'],
        'cargoMovementTypeAtOrigin': !exists(json, 'cargoMovementTypeAtOrigin') ? undefined : json['cargoMovementTypeAtOrigin'],
        'cargoMovementTypeAtDestination': !exists(json, 'cargoMovementTypeAtDestination') ? undefined : json['cargoMovementTypeAtDestination'],
        'serviceContractReference': !exists(json, 'serviceContractReference') ? undefined : json['serviceContractReference'],
        'isPartialLoadAllowed': !exists(json, 'isPartialLoadAllowed') ? undefined : json['isPartialLoadAllowed'],
        'isExportDeclarationRequired': !exists(json, 'isExportDeclarationRequired') ? undefined : json['isExportDeclarationRequired'],
        'isImportLicenseRequired': !exists(json, 'isImportLicenseRequired') ? undefined : json['isImportLicenseRequired'],
        'isEquipmentSubstitutionAllowed': !exists(json, 'isEquipmentSubstitutionAllowed') ? undefined : json['isEquipmentSubstitutionAllowed'],
        'exportDeclarationReference': !exists(json, 'exportDeclarationReference') ? undefined : json['exportDeclarationReference'],
        'importLicenseReference': !exists(json, 'importLicenseReference') ? undefined : json['importLicenseReference'],
        'contractQuotationReference': !exists(json, 'contractQuotationReference') ? undefined : json['contractQuotationReference'],
        'expectedDepartureDate': !exists(json, 'expectedDepartureDate') ? undefined : json['expectedDepartureDate'],
        'expectedArrivalAtPlaceOfDeliveryStartDate': !exists(json, 'expectedArrivalAtPlaceOfDeliveryStartDate') ? undefined : json['expectedArrivalAtPlaceOfDeliveryStartDate'],
        'expectedArrivalAtPlaceOfDeliveryEndDate': !exists(json, 'expectedArrivalAtPlaceOfDeliveryEndDate') ? undefined : json['expectedArrivalAtPlaceOfDeliveryEndDate'],
        'carrierExportVoyageNumber': !exists(json, 'carrierExportVoyageNumber') ? undefined : json['carrierExportVoyageNumber'],
        'communicationChannelCode': !exists(json, 'communicationChannelCode') ? undefined : json['communicationChannelCode'],
        'incoTerms': !exists(json, 'incoTerms') ? undefined : json['incoTerms'],
        'modeOfTransport': !exists(json, 'modeOfTransport') ? undefined : json['modeOfTransport'],
        'paymentTermCode': !exists(json, 'paymentTermCode') ? undefined : json['paymentTermCode'],
        'vesselName': !exists(json, 'vesselName') ? undefined : json['vesselName'],
        'vesselIMONumber': !exists(json, 'vesselIMONumber') ? undefined : json['vesselIMONumber'],
        'charterPartyDate': !exists(json, 'charterPartyDate') ? undefined : json['charterPartyDate'],
        'eblType': !exists(json, 'eblType') ? undefined : json['eblType'],
        'id': json['id'],
        'creator': UserSummaryFromJSON(json['creator']),
        'creatorId': json['creatorId'],
        'carrierRef': !exists(json, 'carrierRef') ? undefined : json['carrierRef'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'status': DocumentStatusFromJSON(json['status']),
        'carrierMessages': !exists(json, 'carrierMessages') ? undefined : ((json['carrierMessages'] as Array<any>).map(BookingResponseAllOfCarrierMessagesFromJSON)),
        'commodities': !exists(json, 'commodities') ? undefined : ((json['commodities'] as Array<any>).map(CommodityResponseFromJSON)),
        'shipmentLocations': !exists(json, 'shipmentLocations') ? undefined : ((json['shipmentLocations'] as Array<any>).map(ShipmentLocationResponseFromJSON)),
        'documentParties': !exists(json, 'documentParties') ? undefined : ((json['documentParties'] as Array<any>).map(DocumentParty1FromJSON)),
    };
}

export function BookingResponseToJSON(value?: BookingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipmentId': value.shipmentId,
        'receiptTypeAtOrigin': value.receiptTypeAtOrigin,
        'deliveryTypeAtDestination': value.deliveryTypeAtDestination,
        'cargoMovementTypeAtOrigin': value.cargoMovementTypeAtOrigin,
        'cargoMovementTypeAtDestination': value.cargoMovementTypeAtDestination,
        'serviceContractReference': value.serviceContractReference,
        'isPartialLoadAllowed': value.isPartialLoadAllowed,
        'isExportDeclarationRequired': value.isExportDeclarationRequired,
        'isImportLicenseRequired': value.isImportLicenseRequired,
        'isEquipmentSubstitutionAllowed': value.isEquipmentSubstitutionAllowed,
        'exportDeclarationReference': value.exportDeclarationReference,
        'importLicenseReference': value.importLicenseReference,
        'contractQuotationReference': value.contractQuotationReference,
        'expectedDepartureDate': value.expectedDepartureDate,
        'expectedArrivalAtPlaceOfDeliveryStartDate': value.expectedArrivalAtPlaceOfDeliveryStartDate,
        'expectedArrivalAtPlaceOfDeliveryEndDate': value.expectedArrivalAtPlaceOfDeliveryEndDate,
        'carrierExportVoyageNumber': value.carrierExportVoyageNumber,
        'communicationChannelCode': value.communicationChannelCode,
        'incoTerms': value.incoTerms,
        'modeOfTransport': value.modeOfTransport,
        'paymentTermCode': value.paymentTermCode,
        'vesselName': value.vesselName,
        'vesselIMONumber': value.vesselIMONumber,
        'charterPartyDate': value.charterPartyDate,
        'eblType': value.eblType,
        'id': value.id,
        'creator': UserSummaryToJSON(value.creator),
        'creatorId': value.creatorId,
        'carrierRef': value.carrierRef,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'status': DocumentStatusToJSON(value.status),
        'carrierMessages': value.carrierMessages === undefined ? undefined : ((value.carrierMessages as Array<any>).map(BookingResponseAllOfCarrierMessagesToJSON)),
        'commodities': value.commodities === undefined ? undefined : ((value.commodities as Array<any>).map(CommodityResponseToJSON)),
        'shipmentLocations': value.shipmentLocations === undefined ? undefined : ((value.shipmentLocations as Array<any>).map(ShipmentLocationResponseToJSON)),
        'documentParties': value.documentParties === undefined ? undefined : ((value.documentParties as Array<any>).map(DocumentParty1ToJSON)),
    };
}

