export const weightUnitOptions = [
  {
    value: 'KGM',
    label: 'Kilograms',
  },
  {
    value: 'LBR',
    label: 'Pounds',
  },
];

export const volumeUnitOptions = [
  {
    value: 'MTQ',
    label: 'Cubic meter',
  },
  {
    value: 'FTQ',
    label: 'Cubic foot',
  },
];
