import { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const getLoginCookie = (value: string) => fetch(`/api/auth/return?code=${value}`);

export function LoginRedirect(): ReactElement {
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code') || '';
    getLoginCookie(code)
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);

        window.location.href = '/';
      });
  }, [searchParams]);

  if (loading) {
    return <p>Logging in...</p>;
  }

  if (!loading) {
    window.location.href = '/';
  }

  return (
    <div
      style={{
        padding: '1rem',
        textAlign: 'center',
      }}
    >
      Logged in
    </div>
  );
}
