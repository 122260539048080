/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Refers to the shipment term at the loading of the cargo into the container. Options are defined in the Cargo Movement Type entity.
 * - FCL (Full Container Load)
 * - LCL (Less than Container Load)
 * - BB (Break Bulk)
 * @export
 */
export const CargoMovementTypeAtOrigin = {
    Fcl: 'FCL',
    Lcl: 'LCL',
    Bb: 'BB'
} as const;
export type CargoMovementTypeAtOrigin = typeof CargoMovementTypeAtOrigin[keyof typeof CargoMovementTypeAtOrigin];


export function CargoMovementTypeAtOriginFromJSON(json: any): CargoMovementTypeAtOrigin {
    return CargoMovementTypeAtOriginFromJSONTyped(json, false);
}

export function CargoMovementTypeAtOriginFromJSONTyped(json: any, ignoreDiscriminator: boolean): CargoMovementTypeAtOrigin {
    return json as CargoMovementTypeAtOrigin;
}

export function CargoMovementTypeAtOriginToJSON(value?: CargoMovementTypeAtOrigin | null): any {
    return value as any;
}

