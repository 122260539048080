import {
  Article,
  AssignmentIndOutlined as DocumentIcon,
  AutoStories,
  BarChartOutlined as DashboardIcon,
  DirectionsBoat,
  FileUploadOutlined,
} from '@mui/icons-material';

import { Route } from '../types';
import { LoginRedirect } from '../pages/LoginRedirect';
import { VaultUpload } from '../pages/VaultUpload';
import { MyDocs } from '../pages/MyDocs';
import { MyShipments } from '../pages/MyShipments';
import { MyAddressBook } from '../pages/MyAddressBook';
import { Shipment } from '../pages/Shipment';
import { ViewBookingRequest } from '../components/Booking/ViewBookingRequest';
import { CreateParty } from '../pages/CreateParty';
import { ManageDocumentParty } from '../pages/ManageDocumentParty';
import { DocumentPartiesEdit } from '../components/BookingManageDocumentParties/DocumentPartiesEdit';
import { ManageTitleTransfer } from '../pages/ManageTitleTransfer';
import { PartyEdit } from '../components/PartiesDataTable/updateParty';
import { BookingRequest } from '../pages/BookingRequest';
import { TokenHistory } from '../components/TTManagement/TokenHistory';

const routes: Array<Route> = [
  {
    key: 'shipments',
    title: 'Shipments',
    description: 'Shipments',
    component: <MyShipments />,
    path: '/',
    enabled: true,
    icon: DirectionsBoat,
    appendDivider: true,
  },
  {
    key: 'address-book',
    title: 'Address book',
    description: 'Address book',
    path: '/address-book',
    component: <MyAddressBook />,
    enabled: true,
    icon: AutoStories,
  },
  {
    key: 'create-party',
    title: 'Create New Party',
    description: 'Create new parties',
    path: '/create-party',
    component: <CreateParty />,
    enabled: true,
    isHidden: true,
  },
  {
    key: 'edit-party',
    title: 'Edit party',
    description: 'Edit party',
    path: '/address-book/:idOrAddress/edit',
    component: <PartyEdit />,
    enabled: true,
    isHidden: true,
  },
  {
    key: 'login-redirect',
    title: 'Redirecting',
    description: 'LoginRedirect',
    component: <LoginRedirect />,
    path: '/login-redirect',
    enabled: true,
    isHidden: true,
    icon: DashboardIcon,
  },
  {
    key: 'router-individual-shipment',
    title: 'Shipment',
    description: 'shipment by id',
    path: '/shipments/:id',
    isHidden: true,
    component: <Shipment />,
    enabled: true,
  },
  {
    key: 'attach-booking-request',
    title: 'Attach Booking Request',
    description: 'Attach Booking Request',
    path: '/shipments/:id/attach-booking-request',
    isHidden: true,
    component: <BookingRequest />,
    enabled: true,
  },
  {
    key: 'edit-booking-request',
    title: 'Edit Booking Request',
    description: 'Edit Booking Request',
    path: '/shipments/:id/edit-booking-request',
    isHidden: true,
    component: <BookingRequest />,
    enabled: true,
  },
  {
    key: 'manage-document-parties',
    title: 'Manage Document Parties',
    description: 'Manage Document Parties',
    path: '/booking-requests/:id/manage-document-parties',
    isHidden: true,
    component: <ManageDocumentParty />,
    enabled: true,
  },
  {
    key: 'manage-title-transfer',
    title: 'Manage title transfer',
    description: 'Manage title transfer',
    path: '/shipments/:id/manage-title-transfer',
    isHidden: true,
    component: <ManageTitleTransfer />,
    enabled: true,
  },
  {
    key: 'view-booking-request',
    title: 'View booking request',
    description: 'View booking request',
    path: '/shipments/:id/view-booking-request',
    isHidden: true,
    component: <ViewBookingRequest />,
    enabled: true,
  },
  {
    key: 'token-history',
    title: 'Title transfer history',
    description: 'Title transfer history',
    path: '/shipments/:id/token-history',
    isHidden: true,
    component: <TokenHistory />,
    enabled: true,
  },
  {
    key: 'document-party',
    title: 'Document Party by :id',
    description: 'Document Party by ID',
    path: '/document-parties/:id',
    isHidden: true,
    component: <DocumentPartiesEdit />,
    enabled: true,
  },
  {
    key: 'documents',
    title: 'Documents',
    description: 'Documents',
    enabled: true,
    path: '/documents',
    icon: DocumentIcon,
    subRoutes: [
      {
        key: 'vault-upload',
        title: 'Upload document',
        description: 'Upload document',
        component: <VaultUpload />,
        path: '/file-upload',
        enabled: true,
        icon: FileUploadOutlined,
      },
      {
        key: 'my-docs',
        title: 'My documents',
        description: 'My documents',
        component: <MyDocs />,
        path: '/my-docs',
        enabled: true,
        icon: Article,
      },
    ],
  },
];

export default routes;
