/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ShipmentRequest,
  ShipmentResponse,
} from '../models';
import {
    ShipmentRequestFromJSON,
    ShipmentRequestToJSON,
    ShipmentResponseFromJSON,
    ShipmentResponseToJSON,
} from '../models';

export interface ShipmentsIdDeleteRequest {
    id: string;
}

export interface ShipmentsIdGetRequest {
    id: string;
}

export interface ShipmentsIdPutRequest {
    id: string;
    shipmentRequest?: ShipmentRequest;
}

export interface ShipmentsIdRequestBlPostRequest {
    id: string;
    draft: boolean;
}

export interface ShipmentsPostRequest {
    shipmentRequest?: ShipmentRequest;
}

/**
 * 
 */
export class ShipmentsApi extends runtime.BaseAPI {

    /**
     * Get all shipments that the user has view access for. 
     */
    async shipmentsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShipmentResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shipments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShipmentResponseFromJSON));
    }

    /**
     * Get all shipments that the user has view access for. 
     */
    async shipmentsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShipmentResponse>> {
        const response = await this.shipmentsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete a single shipment from the database
     */
    async shipmentsIdDeleteRaw(requestParameters: ShipmentsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling shipmentsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shipments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single shipment from the database
     */
    async shipmentsIdDelete(requestParameters: ShipmentsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shipmentsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get shipment by id 
     */
    async shipmentsIdGetRaw(requestParameters: ShipmentsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShipmentResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling shipmentsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shipments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentResponseFromJSON(jsonValue));
    }

    /**
     * Get shipment by id 
     */
    async shipmentsIdGet(requestParameters: ShipmentsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShipmentResponse> {
        const response = await this.shipmentsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specified shipment in the database
     */
    async shipmentsIdPutRaw(requestParameters: ShipmentsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShipmentResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling shipmentsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/shipments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ShipmentRequestToJSON(requestParameters.shipmentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentResponseFromJSON(jsonValue));
    }

    /**
     * Updates the specified shipment in the database
     */
    async shipmentsIdPut(requestParameters: ShipmentsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShipmentResponse> {
        const response = await this.shipmentsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Shipping Instruction object (see https://app.swaggerhub.com/apis/dcsaorg/DCSA_EBL/2.0.0#/) from the  shipment data. Send this object to the carrier and initiate the BL issuing process. 
     */
    async shipmentsIdRequestBlPostRaw(requestParameters: ShipmentsIdRequestBlPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling shipmentsIdRequestBlPost.');
        }

        if (requestParameters.draft === null || requestParameters.draft === undefined) {
            throw new runtime.RequiredError('draft','Required parameter requestParameters.draft was null or undefined when calling shipmentsIdRequestBlPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.draft !== undefined) {
            queryParameters['draft'] = requestParameters.draft;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shipments/{id}/request-bl`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Shipping Instruction object (see https://app.swaggerhub.com/apis/dcsaorg/DCSA_EBL/2.0.0#/) from the  shipment data. Send this object to the carrier and initiate the BL issuing process. 
     */
    async shipmentsIdRequestBlPost(requestParameters: ShipmentsIdRequestBlPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shipmentsIdRequestBlPostRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new shipment to the database
     */
    async shipmentsPostRaw(requestParameters: ShipmentsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/shipments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShipmentRequestToJSON(requestParameters.shipmentRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a new shipment to the database
     */
    async shipmentsPost(requestParameters: ShipmentsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.shipmentsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
