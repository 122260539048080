import { ReactElement } from 'react';
import { useLocation } from 'react-router';
import { bookingsApi } from '../../api/bookingsApi';
import { BookingResponse } from '../../generated';
import { useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import { NotFound } from '../../pages/NotFound';
import { DcsaDocumentParties } from './DcsaDocumentParties';
import { BimcoDocumentParties } from './BimcoDocumentParties';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const BookingManageDocumentParties = (): ReactElement => {
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const {
    data: booking,
    isError: bookingError,
    isLoading: bookingIsLoading,
  } = useQuery(['booking', id], () => fetchBooking(id));
  if (bookingIsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }
  if (bookingError) {
    return <NotFound />;
  }

  return (
    <>
      {booking.eblType === 'DCSA' && <DcsaDocumentParties booking={booking} />}
      {booking.eblType === 'BIMCO' && <BimcoDocumentParties booking={booking} />}
    </>
  );
};

async function fetchBooking(id: string): Promise<BookingResponse> {
  const authHeader = await getAuthHeader();
  return bookingsApi.bookingRequestsIdGet({ id }, { headers: authHeader });
}
