import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { Delete, Description, Download } from '@mui/icons-material';
import React, { ReactElement } from 'react';
import { DocMetadata } from '../../generated';
import { retrieveFile } from '../../utils/files';
import { useQueryClient } from '@tanstack/react-query';
import { vaultApi } from '../../api/vaultApi';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const ShipmentDocs = (props: props): ReactElement => {
  const queryClient = useQueryClient();

  async function detachFile(vaultId: string, shipmentId: string) {
    const authHeader = await getAuthHeader();

    await vaultApi.vaultDelete(
      {
        id: vaultId,
        shipmentId: shipmentId,
      },
      { headers: authHeader },
    );

    await queryClient.invalidateQueries(['shipment', shipmentId]);
  }

  function shorten(input: string): string {
    if (input.length < 15) return input;
    const start = input.substring(0, 10);
    const end = input.substring(input.length - 6, input.length);
    return start + '...' + end;
  }

  return (
    <List
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Attached documents
        </ListSubheader>
      }
    >
      {props.docs.sort(sortByDate).map((doc) => {
        return (
          <ListItem
            key={doc.id}
            secondaryAction={
              <div>
                <IconButton
                  onClick={() => retrieveFile(doc.vaultId)}
                  edge="end"
                  aria-label="download"
                >
                  <Download />
                </IconButton>
                {doc.docType != 'eBL' && doc.docType != 'draft-eBL' && (
                  <IconButton
                    onClick={() => detachFile(doc.vaultId, props.shipmentId)}
                    edge="end"
                    aria-label="delete"
                  >
                    <Delete />
                  </IconButton>
                )}
              </div>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <Description />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={shorten(doc.fileName)}
              secondary={doc.createdAt?.toUTCString()}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

interface props {
  docs: Array<DocMetadata>;
  shipmentId: string;
}

function sortByDate(a: DocMetadata, b: DocMetadata) {
  if (!a.createdAt || !b.createdAt) {
    return 0;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return -1;
}
