/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressData
 */
export interface AddressData {
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    addressName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    postCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    stateRegion?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressData
     */
    country?: string;
}

/**
 * Check if a given object implements the AddressData interface.
 */
export function instanceOfAddressData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddressDataFromJSON(json: any): AddressData {
    return AddressDataFromJSONTyped(json, false);
}

export function AddressDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressName': !exists(json, 'addressName') ? undefined : json['addressName'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'postCode': !exists(json, 'postCode') ? undefined : json['postCode'],
        'cityName': !exists(json, 'cityName') ? undefined : json['cityName'],
        'stateRegion': !exists(json, 'stateRegion') ? undefined : json['stateRegion'],
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function AddressDataToJSON(value?: AddressData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressName': value.addressName,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
        'floor': value.floor,
        'postCode': value.postCode,
        'cityName': value.cityName,
        'stateRegion': value.stateRegion,
        'country': value.country,
    };
}

