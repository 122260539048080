/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingResponseAllOfCarrierMessages
 */
export interface BookingResponseAllOfCarrierMessages {
    /**
     * 
     * @type {string}
     * @memberof BookingResponseAllOfCarrierMessages
     */
    newStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingResponseAllOfCarrierMessages
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingResponseAllOfCarrierMessages
     */
    carrierId?: string;
    /**
     * 
     * @type {Date}
     * @memberof BookingResponseAllOfCarrierMessages
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof BookingResponseAllOfCarrierMessages
     */
    transportDocumentReference?: string;
}

/**
 * Check if a given object implements the BookingResponseAllOfCarrierMessages interface.
 */
export function instanceOfBookingResponseAllOfCarrierMessages(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BookingResponseAllOfCarrierMessagesFromJSON(json: any): BookingResponseAllOfCarrierMessages {
    return BookingResponseAllOfCarrierMessagesFromJSONTyped(json, false);
}

export function BookingResponseAllOfCarrierMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingResponseAllOfCarrierMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newStatus': !exists(json, 'newStatus') ? undefined : json['newStatus'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'carrierId': !exists(json, 'carrierId') ? undefined : json['carrierId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'transportDocumentReference': !exists(json, 'transportDocumentReference') ? undefined : json['transportDocumentReference'],
    };
}

export function BookingResponseAllOfCarrierMessagesToJSON(value?: BookingResponseAllOfCarrierMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newStatus': value.newStatus,
        'reason': value.reason,
        'carrierId': value.carrierId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'transportDocumentReference': value.transportDocumentReference,
    };
}

