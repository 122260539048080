/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Specifies the role of the party in the context of the given Shipping Instruction.
 * - OS (Original shipper)
 * - CN (Consignee)
 * - COW (Invoice payer on behalf of the consignor (shipper))
 * - COX (Invoice payer on behalf of the consignee)
 * - MS (Document/message issuer/sender)
 * - N1 (First Notify Party)
 * - N2 (Second Notify Party)
 * - NI (Other Notify Party)
 * - DDR (Consignor's freight forwarder)
 * - DDS (Consignee's freight forwarder)
 * - HE (Carrier booking office (transportation office))
 * - SCO (Service contract owner - Defined by DCSA)
 * - BA (Booking Agency)
 * - ENR (Envelope Receiver)
 * @export
 */
export const PartyFunction = {
    Os: 'OS',
    Cn: 'CN',
    Cow: 'COW',
    Cox: 'COX',
    Ms: 'MS',
    N1: 'N1',
    N2: 'N2',
    Ni: 'NI',
    Ddr: 'DDR',
    Dds: 'DDS',
    He: 'HE',
    Sco: 'SCO',
    Ba: 'BA',
    Enr: 'ENR'
} as const;
export type PartyFunction = typeof PartyFunction[keyof typeof PartyFunction];


export function PartyFunctionFromJSON(json: any): PartyFunction {
    return PartyFunctionFromJSONTyped(json, false);
}

export function PartyFunctionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartyFunction {
    return json as PartyFunction;
}

export function PartyFunctionToJSON(value?: PartyFunction | null): any {
    return value as any;
}

