import { getAuth } from 'firebase/auth';

export const getAuthHeader = async () => {
  const auth = getAuth();
  const token = auth.currentUser ? await auth.currentUser.getIdToken() : null;

  return {
    Authorization: `Bearer ${token}`,
  };
};
