import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Breadcrumbs } from '@mui/material';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { TokenHistoryInner } from './TokenHistoryInner';
import { useParams } from 'react-router';
import { ShipmentResponse } from '../../generated/models/ShipmentResponse';
import { getAuthHeader } from '../../utils/getAuthHeader';
import { shipmentsApi } from '../../api/shipmentsApi';
import { useQuery } from '@tanstack/react-query';

export const TokenHistory = (): ReactElement => {
  const { id } = useParams();
  let shipmentId = '';
  if (id !== undefined) {
    shipmentId = id;
  }

  const [shipment, setShipment] = useState<ShipmentResponse>();
  useQuery(['shipment', shipmentId], () => fetchAndSetData(shipmentId), {
    retry: false,
  });

  // Fetch shipment and tradetrust file to propagate data into the token history page
  const fetchAndSetData = useCallback(async (id: string): Promise<ShipmentResponse> => {
    const authHeader = await getAuthHeader();
    const shipmentData = await shipmentsApi.shipmentsIdGet({ id: id }, { headers: authHeader });
    setShipment(shipmentData);
    return shipmentData;
  }, []);

  // Refresh data when network switches
  useEffect(() => {
    fetchAndSetData(shipmentId);
  }, [fetchAndSetData, shipmentId]);

  return (
    <>
      <Breadcrumbs style={{ fontSize: 35 }} aria-label="breadcrumb">
        <Link underline="hover" component={RouterLink} color="inherit" to="/">
          Shipments{' '}
        </Link>
        <Link
          underline="hover"
          component={RouterLink}
          color="text.primary"
          to={`/shipments/${shipmentId}`}
        >
          {shipment?.name}
        </Link>
        <Link underline="none" color="text.primary">
          View token history
        </Link>
      </Breadcrumbs>

      <TokenHistoryInner />
    </>
  );
};
