import { ReactElement } from 'react';
import { FileUploader } from '../components/FileUploader';
import { vaultApi } from '../api/vaultApi';
import { getAuthHeader } from '../utils/getAuthHeader';

export function VaultUpload(): ReactElement {
  async function submitFile(file: File): Promise<string> {
    const authHeader = await getAuthHeader();

    return await vaultApi.vaultPost(
      {
        file: file,
      },
      { headers: authHeader },
    );
  }

  return <FileUploader onFileSubmit={submitFile} />;
}
