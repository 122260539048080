import { WrappedDocument } from '@govtechsg/open-attestation';
import { vaultApi } from '../api/vaultApi';
import { getAuthHeader } from '../utils/getAuthHeader';

const oaVerifyHost = process.env.REACT_APP_OA_VERIFY_HOST || 'https://oa-verify.ebl.dev';

function getVerifyEndpoint(chainId: number): string {
  // By default, we verify the doc on the ethereum network
  let network = 'ethereum';
  if (chainId === 11155111) {
    network = 'sepolia';
  }
  if (chainId === 80001) {
    network = 'maticmum';
  }

  return `${oaVerifyHost}/verify?network=${network}`;
}

export async function verifyDoc(vaultId: string, chainId: number): Promise<boolean> {
  const authHeader = await getAuthHeader();
  const doc = await vaultApi.vaultGet({ id: vaultId }, { headers: authHeader });

  if (doc.contentType !== 'application/json') {
    console.error('Document to be verified is not in JSON format');
    return false;
  }

  const decodedString = window.atob(doc.file);
  const byteArray = new Uint8Array(decodedString.length);

  for (let i = 0; i < decodedString.length; ++i) {
    byteArray[i] = decodedString.charCodeAt(i);
  }

  // eslint-disable-next-line
  const wrappedDoc: WrappedDocument<any> = JSON.parse(new TextDecoder('utf-8').decode(byteArray));

  const verificationResponse = await tryVerify(3, 1500, wrappedDoc, chainId);

  // Check for verification errors and throw an error if any are found
  if (!verificationResponse.summary.documentIntegrity) {
    throw new Error('Invalid document: Document has been tampered with');
  }

  if (!verificationResponse.summary.issuerIdentity) {
    throw new Error('Invalid document: Matching DNS check not found');
  }

  if (!verificationResponse.summary.documentStatus) {
    throw new Error('Invalid document: Document has not been issued under token registry');
  }

  if (!verificationResponse.summary) {
    throw new Error('Invalid document: Document not issued');
  }

  // Return true if all verifications pass, otherwise return false
  return verificationResponse.summary.all;
}

/**
 * Verify a document with a number of tries (number of calls to the oa-verify api)
 * @param tries amount of tries to fetch a successful response
 * @param timeoutMs the timeout in-between tries
 * @param wrappedDoc the document to verify
 * @param chainId the ethereum network id over which the token is verified
 */
async function tryVerify(
  tries: number,
  timeoutMs: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrappedDoc: WrappedDocument<any>,
  chainId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const response = await fetch(getVerifyEndpoint(chainId), {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      document: wrappedDoc,
    }),
  });

  const verificationResponse = await response.json();
  if (verificationResponse?.summary?.all) {
    return verificationResponse;
  } else {
    if (tries < 2) {
      return verificationResponse;
    }

    await new Promise((resolve) => setTimeout(resolve, timeoutMs));

    return tryVerify(tries - 1, timeoutMs, wrappedDoc, chainId);
  }
}
