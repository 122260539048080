import React, { ReactElement } from 'react';
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AddressRequest, AddressResponse, PartyRequest, PartyResponse } from '../generated';
import { partiesApi } from '../api/partiesApi';
import { useNavigate } from 'react-router';
import { SubmitHandler, useForm, UseFormRegister } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { toast } from 'react-toastify';
import { getAuthHeader } from '../utils/getAuthHeader';

async function fetchMyParties(): Promise<Array<PartyResponse>> {
  const authHeader = await getAuthHeader();
  return partiesApi.partiesGet({ headers: authHeader });
}

export const CreateParty = (): ReactElement => {
  const [idc, setIdc] = React.useState(false);
  const { register: addressRegister, getValues: addressGetValues } = useForm<AddressResponse>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PartyRequest>();

  const navigate = useNavigate();
  const navigateToAddressBook = () => {
    navigate(`/address-book`);
  };

  const { data: existingParties } = useQuery(['parties'], fetchMyParties); //

  const postParty = useMutation(
    async (party: PartyRequest) => {
      const authHeader = await getAuthHeader();
      return partiesApi.partiesPost({ partyRequest: party }, { headers: authHeader });
    },
    {
      onSuccess: () => {
        navigateToAddressBook();
      },
    },
  );

  const onPartySubmit: SubmitHandler<PartyRequest> = (p) => {
    // Check if the publicKey already exists in the existing parties list
    const publicKeyToCheck = p.publicKey;
    const isPublicKeyDuplicate = existingParties?.some(
      (party) => party.publicKey === publicKeyToCheck,
    );

    if (isPublicKeyDuplicate) {
      // Handle the case where the publicKey already exists, for example, show an error message
      toast.error('A party with the same publicKey already exists');
    } else {
      // Proceed with the submit logic here
      toast.success('Successfully created document party');
      p.address = addressGetValues() as AddressRequest;
      postParty.mutate(p);
    }
  };

  const handleChange = () => {
    setIdc(!idc);
  };

  const renderTextField = (
    // eslint-disable-next-line
    register: UseFormRegister<any>,
    id: string,
    label: string,
    required = false,
  ) => (
    <TextField
      {...register(id, { required })}
      margin="dense"
      id={id}
      label={label}
      fullWidth
      variant="standard"
    />
  );

  return (
    <>
      <Breadcrumbs style={{ fontSize: 35 }} aria-label="breadcrumb">
        <Link underline="hover" component={RouterLink} color="inherit" to="/address-book">
          Address book
        </Link>
        <Link underline="none" color="text.primary">
          Create document party
        </Link>
      </Breadcrumbs>
      <form onSubmit={handleSubmit(onPartySubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Party</Typography>
            <TextField
              {...register('partyName', { required: true })}
              margin="dense"
              id="partyName"
              label="Party Name"
              fullWidth
              variant="standard"
              error={!!errors.partyName}
              helperText={errors.partyName && 'Field is required'}
            />
            {renderTextField(register, 'taxReference1', 'Tax Reference 1')}
            {renderTextField(register, 'taxReference2', 'Tax Reference 2')}
            {renderTextField(register, 'publicKey', 'Public Key')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Address</Typography>
            {renderTextField(addressRegister, 'addressName', 'Address Name')}
            {renderTextField(addressRegister, 'streetName', 'Street Name')}
            {renderTextField(addressRegister, 'streetNumber', 'Street Number')}
            {renderTextField(addressRegister, 'floor', 'Floor')}
            {renderTextField(addressRegister, 'postCode', 'Post Code')}
            {renderTextField(addressRegister, 'cityName', 'City')}
            {renderTextField(addressRegister, 'stateRegion', 'State/Region')}
            {renderTextField(addressRegister, 'country', 'Country')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Contact Details</Typography>
            <TextField
              {...register('partyContactDetails.0.name', { required: true })}
              margin="dense"
              id="partyContactDetails.0.name"
              label="Name"
              error={!!errors.partyContactDetails?.[0]?.name}
              helperText={errors.partyContactDetails?.[0]?.name && 'Field is required'}
              fullWidth
              variant="standard"
            />
            {renderTextField(register, 'partyContactDetails.0.phone', 'Phone')}
            {renderTextField(register, 'partyContactDetails.0.email', 'Email')}
            {renderTextField(register, 'partyContactDetails.0.url', 'URL')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox color="primary" onChange={handleChange} />}
              label="Add Identifying Codes?"
            />
            {idc && (
              <>
                {renderTextField(
                  register,
                  'identifyingCodes.0.dCSAResponsibleAgencyCode',
                  'DCSA Responsible Agency',
                  true,
                )}
                {renderTextField(register, 'identifyingCodes.0.partyCode', 'Party Code', true)}
                {renderTextField(register, 'identifyingCodes.0.codeListName', 'Code List Name')}
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              Save Party
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
