/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Indicates the type of service offered at Destination. Options are defined in the Receipt/Delivery entity.
 * - CY (Container yard (incl. rail ramp))
 * - SD (Store Door)
 * - CFS (Container Freight Station)
 * @export
 */
export const DeliveryTypeAtDestination = {
    Cy: 'CY',
    Sd: 'SD',
    Cfs: 'CFS'
} as const;
export type DeliveryTypeAtDestination = typeof DeliveryTypeAtDestination[keyof typeof DeliveryTypeAtDestination];


export function DeliveryTypeAtDestinationFromJSON(json: any): DeliveryTypeAtDestination {
    return DeliveryTypeAtDestinationFromJSONTyped(json, false);
}

export function DeliveryTypeAtDestinationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryTypeAtDestination {
    return json as DeliveryTypeAtDestination;
}

export function DeliveryTypeAtDestinationToJSON(value?: DeliveryTypeAtDestination | null): any {
    return value as any;
}

