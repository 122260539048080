/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressResponse } from './AddressResponse';
import {
    AddressResponseFromJSON,
    AddressResponseFromJSONTyped,
    AddressResponseToJSON,
} from './AddressResponse';
import type { IdentifyingCode } from './IdentifyingCode';
import {
    IdentifyingCodeFromJSON,
    IdentifyingCodeFromJSONTyped,
    IdentifyingCodeToJSON,
} from './IdentifyingCode';
import type { PartyContactDetail } from './PartyContactDetail';
import {
    PartyContactDetailFromJSON,
    PartyContactDetailFromJSONTyped,
    PartyContactDetailToJSON,
} from './PartyContactDetail';

/**
 * 
 * @export
 * @interface PartyResponse
 */
export interface PartyResponse {
    /**
     * 
     * @type {string}
     * @memberof PartyResponse
     */
    partyName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyResponse
     */
    taxReference1?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyResponse
     */
    taxReference2?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyResponse
     */
    publicKey?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof PartyResponse
     */
    address?: AddressResponse;
    /**
     * A list of contact details - the list cannot be empty
     * @type {Array<PartyContactDetail>}
     * @memberof PartyResponse
     */
    partyContactDetails?: Array<PartyContactDetail>;
    /**
     * 
     * @type {Array<IdentifyingCode>}
     * @memberof PartyResponse
     */
    identifyingCodes?: Array<IdentifyingCode>;
    /**
     * 
     * @type {string}
     * @memberof PartyResponse
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof PartyResponse
     */
    creatorId?: number;
    /**
     * 
     * @type {Date}
     * @memberof PartyResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PartyResponse
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PartyResponse
     */
    deletedAt?: Date;
}

/**
 * Check if a given object implements the PartyResponse interface.
 */
export function instanceOfPartyResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function PartyResponseFromJSON(json: any): PartyResponse {
    return PartyResponseFromJSONTyped(json, false);
}

export function PartyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partyName': !exists(json, 'partyName') ? undefined : json['partyName'],
        'taxReference1': !exists(json, 'taxReference1') ? undefined : json['taxReference1'],
        'taxReference2': !exists(json, 'taxReference2') ? undefined : json['taxReference2'],
        'publicKey': !exists(json, 'publicKey') ? undefined : json['publicKey'],
        'address': !exists(json, 'address') ? undefined : AddressResponseFromJSON(json['address']),
        'partyContactDetails': !exists(json, 'partyContactDetails') ? undefined : ((json['partyContactDetails'] as Array<any>).map(PartyContactDetailFromJSON)),
        'identifyingCodes': !exists(json, 'identifyingCodes') ? undefined : ((json['identifyingCodes'] as Array<any>).map(IdentifyingCodeFromJSON)),
        'id': json['id'],
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}

export function PartyResponseToJSON(value?: PartyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partyName': value.partyName,
        'taxReference1': value.taxReference1,
        'taxReference2': value.taxReference2,
        'publicKey': value.publicKey,
        'address': AddressResponseToJSON(value.address),
        'partyContactDetails': value.partyContactDetails === undefined ? undefined : ((value.partyContactDetails as Array<any>).map(PartyContactDetailToJSON)),
        'identifyingCodes': value.identifyingCodes === undefined ? undefined : ((value.identifyingCodes as Array<any>).map(IdentifyingCodeToJSON)),
        'id': value.id,
        'creatorId': value.creatorId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}

