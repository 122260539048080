/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Indicates whether freight & charges are due for payment before the shipment is effected, practically before the transport document is released to shipper (Prepaid) or before the shipment is finalized meaning cargo released to consignee (Collect)
 * - PRE (Prepaid)
 * - COL (Collect)
 * @export
 */
export const PaymentTermCode = {
    Pre: 'PRE',
    Col: 'COL'
} as const;
export type PaymentTermCode = typeof PaymentTermCode[keyof typeof PaymentTermCode];


export function PaymentTermCodeFromJSON(json: any): PaymentTermCode {
    return PaymentTermCodeFromJSONTyped(json, false);
}

export function PaymentTermCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTermCode {
    return json as PaymentTermCode;
}

export function PaymentTermCodeToJSON(value?: PaymentTermCode | null): any {
    return value as any;
}

