import { Web3OnboardProvider } from '@web3-onboard/react';
import { User, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { StepsProvider } from './Context';
import Login from './Login';
import { APP_DESCRIPTION } from './utils/constants';
import config from './utils/web3onboard/config';
import { auth } from './Auth';
import CircularProgress from '@mui/material/CircularProgress';

const element = document.getElementById('root') as HTMLElement;
const root = createRoot(element);

const Main = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <React.StrictMode>
      <meta name="description" content={APP_DESCRIPTION} />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <Web3OnboardProvider web3Onboard={config}>
        <StepsProvider>{user ? <App /> : <Login />}</StepsProvider>
      </Web3OnboardProvider>
    </React.StrictMode>
  );
};

root.render(<Main />);
