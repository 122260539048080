import { ReactElement, useState } from 'react';
import {
  BookingResponse,
  DocumentParty,
  DocumentPartyRequest,
  DocumentPartyResponse,
  PartyFunction,
  ShipmentResponse,
} from '../../generated';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { documentPartiesApi } from '../../api/documentPartiesApi';
import { NotFound } from '../../pages/NotFound';
import { AddressBookSelect } from '../AddressBookSelect';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { ExpandMoreOutlined } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { shipmentsApi } from '../../api/shipmentsApi';
import { DataGrid } from '@mui/x-data-grid';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const BimcoDocumentParties = ({ booking }: { booking: BookingResponse }): ReactElement => {
  const [editingShipper, setEditingShipper] = useState(false);
  const [editingConsignee, setEditingConsignee] = useState(false);
  const [editingNotifyParty, setEditingNotifyParty] = useState(false);
  const [shipperIndex, setShipperIndex] = useState(-1);
  const [consigneeIndex, setConsigneeIndex] = useState(-1);
  const [notifyIndex, setNotifyIndex] = useState(-1);
  const [editIndex, setEditIndex] = useState(0);
  const [idc, setIdc] = useState(false);

  const methods = useForm<Array<DocumentParty>>();
  const register = methods.register;
  const handleSubmit = methods.handleSubmit;
  const setValue = methods.setValue;
  const getValues = methods.getValues;
  const reset = methods.reset;

  const queryClient = useQueryClient();

  const [expanded, setExpanded] = useState(false);

  const handleTestChange = () => {
    setExpanded(!expanded);
  };

  interface idDocumentPartyRequest {
    documentPartyRequest: DocumentPartyRequest;
    id: string;
  }

  const initialise = (data: DocumentPartyResponse[]) => {
    data.forEach((documentParty, index) => {
      switch (documentParty.partyFunction) {
        case 'OS':
          setShipperIndex(index);
          setValue(`${index}`, documentParty);
          break;
        case 'CN':
          setConsigneeIndex(index);
          setValue(`${index}`, documentParty);
          break;
        case 'N1':
          setNotifyIndex(index);
          setValue(`${index}`, documentParty);
          break;
      }
    });
  };

  const {
    data: documentParties,
    isError: documentPartiesError,
    isLoading: documentPartiesIsLoading,
  } = useQuery(['documentparties', booking.id], () =>
    fetchDocumentParties(booking.id).then((data) => {
      initialise(data);
      return data;
    }),
  );

  const deleteDocumentParty = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();
      return documentPartiesApi.documentPartiesIdDelete({ id }, { headers: authHeader });
    },
    {
      onSuccess: () => {
        reset();
        queryClient.invalidateQueries(['documentparties']);
        toast.success('Successfully deleted document party');
      },
    },
  );

  const editDocumentParty = useMutation(
    async (idReq: idDocumentPartyRequest) => {
      const authHeader = await getAuthHeader();
      return documentPartiesApi.documentPartiesIdPut(
        { id: idReq.id, documentPartyRequest: idReq.documentPartyRequest },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['documentparties']);
        toast.success('Successfully edited document party');
        handleReturn();
      },
    },
  );

  const createDocumentParty = useMutation(
    async (documentPartyData: DocumentPartyRequest) => {
      const authHeader = await getAuthHeader();
      return documentPartiesApi.documentPartiesPost(
        { documentPartyRequest: documentPartyData },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['documentparties']);
        toast.success('Successfully created document party');
        handleReturn();
      },
    },
  );

  const { data: shipment } = useQuery(
    ['shipment', booking.shipmentId],
    () => fetchShipment(booking.shipmentId),
    {
      retry: false,
    },
  );

  if (documentPartiesIsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }
  if (documentPartiesError) {
    return <NotFound />;
  }

  const handlePartyClick = (partyType: string, index: number) => {
    if (index === -1) {
      setEditIndex(documentParties.length);
    } else {
      setEditIndex(index);
      if (documentParties[index].party.identifyingCodes) {
        setIdc(true);
      }
    }

    switch (partyType) {
      case 'shipper':
        setEditingShipper((prevState) => !prevState);
        break;
      case 'consignee':
        setEditingConsignee((prevState) => !prevState);
        break;
      case 'notify':
        setEditingNotifyParty((prevState) => !prevState);
        break;
      default:
        break;
    }
  };

  const handleReturn = () => {
    setEditIndex(0);
    setIdc(false);

    if (editingShipper) {
      setEditingShipper(false);
    }

    if (editingConsignee) {
      setEditingConsignee(false);
    }

    if (editingNotifyParty) {
      setEditingNotifyParty(false);
    }

    queryClient.invalidateQueries(['documentparties']);
  };

  const handleShipperClick = () => {
    handlePartyClick('shipper', shipperIndex);
  };

  const handleConsigneeClick = () => {
    handlePartyClick('consignee', consigneeIndex);
  };

  const handleNotifyPartyClick = () => {
    handlePartyClick('notify', notifyIndex);
  };

  const onSubmit: SubmitHandler<Array<DocumentPartyRequest>> = (newDocumentPartyData) => {
    setIdc(false);

    const partyFunctions = {
      shipper: 'OS',
      consignee: 'CN',
      notify: 'N1',
    };

    const currentPartyFunction = editingShipper
      ? partyFunctions.shipper
      : editingConsignee
      ? partyFunctions.consignee
      : editingNotifyParty
      ? partyFunctions.notify
      : '';

    newDocumentPartyData[editIndex].partyFunction = currentPartyFunction as PartyFunction;
    newDocumentPartyData[editIndex].bookingId = booking.id;

    const documentPartyRequest = newDocumentPartyData[editIndex];
    const documentPartyId = documentParties[editIndex]?.id;

    if (documentPartyId) {
      const data = { documentPartyRequest, id: documentPartyId } as idDocumentPartyRequest;
      editDocumentParty.mutate(data);
    } else {
      createDocumentParty.mutate(documentPartyRequest);
    }
  };

  const handleChange = () => {
    setValue(`${editIndex}.isToBeNotified`, !getValues(`${editIndex}.isToBeNotified`));
  };

  const setDefault = () => {
    if (documentParties[editIndex]) {
      return documentParties[editIndex].isToBeNotified as boolean;
    } else {
      return false;
    }
  };

  const handleResetClick = () => {
    reset();
  };

  const handleIdcChange = () => {
    setIdc(!idc);
  };

  const editForm = (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Select from Address Book</Typography>
            <AddressBookSelect index={editIndex} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Party Details</Typography>
            <TextField
              {...register(`${editIndex}.party.partyName`, { required: true })}
              required
              margin="dense"
              id="partyName"
              label="Party Name"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              {...register(`${editIndex}.party.taxReference1`)}
              margin="dense"
              id="taxReference1"
              label="Tax Reference 1"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              {...register(`${editIndex}.party.taxReference2`)}
              margin="dense"
              id="taxReference2"
              label="Tax Reference 2"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              {...register(`${editIndex}.party.publicKey`)}
              margin="dense"
              id="publicKey"
              label="Public Key"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Address Details</Typography>
            <Accordion expanded={expanded} onChange={handleTestChange}>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                <VisibilityIcon />
                <Typography> </Typography>
                <Typography>View address details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <TextField
                    {...register(`${editIndex}.party.address.addressName`)}
                    margin="dense"
                    id="addressName"
                    label="Address Name"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    {...register(`${editIndex}.party.address.streetName`)}
                    margin="dense"
                    id="streetName"
                    label="Street Name"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    {...register(`${editIndex}.party.address.streetNumber`)}
                    margin="dense"
                    id="streetNumber"
                    label="Street Number"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    {...register(`${editIndex}.party.address.floor`)}
                    margin="dense"
                    id="floor"
                    label="Floor"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    {...register(`${editIndex}.party.address.postCode`)}
                    margin="dense"
                    id="postCode"
                    label="Post Code"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    {...register(`${editIndex}.party.address.cityName`)}
                    margin="dense"
                    id="cityName"
                    label="City"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    {...register(`${editIndex}.party.address.stateRegion`)}
                    margin="dense"
                    id="stateRegion"
                    label="State/Region"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    {...register(`${editIndex}.party.address.country`)}
                    margin="dense"
                    id="country"
                    label="Country"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    {...register(`${editIndex}.displayedAddress.${0}`)}
                    margin="dense"
                    id="displayedAddress"
                    label="Single line address"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Contact Details</Typography>
            <TextField
              {...register(`${editIndex}.party.partyContactDetails.${0}.name`)}
              required
              margin="dense"
              id="contactName"
              label="Name"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              {...register(`${editIndex}.party.partyContactDetails.${0}.email`)}
              margin="dense"
              id="contactEmail"
              label="Email"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              {...register(`${editIndex}.party.partyContactDetails.${0}.phone`)}
              margin="dense"
              id="contactPhone"
              label="Phone"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              {...register(`${editIndex}.party.partyContactDetails.${0}.url`)}
              margin="dense"
              id="contactUrl"
              label="URL"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Identifying codes</Typography>
            <FormControlLabel
              sx={{ alignItems: 'flex-start' }}
              control={
                <Checkbox
                  sx={{
                    marginTop: -1,
                  }}
                  color="primary"
                  onChange={handleIdcChange}
                  defaultChecked={idc}
                />
              }
              label="Add Identifying Codes?"
            />
            {idc && (
              <>
                <TextField
                  {...register(
                    `${editIndex}.party.identifyingCodes.${0}.dCSAResponsibleAgencyCode`,
                  )}
                  required
                  margin="dense"
                  id="dcsaCode"
                  label="DCSA Responsible Agency"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  {...register(`${editIndex}.party.identifyingCodes.${0}.partyCode`)}
                  required
                  margin="dense"
                  id="partyCode"
                  label="Party Code"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  {...register(`${editIndex}.party.identifyingCodes.${0}.codeListName`)}
                  margin="dense"
                  id="codeListName"
                  label="Code List Name"
                  fullWidth
                  variant="standard"
                />
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Miscellaneous</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  {...register(`${editIndex}.isToBeNotified`)}
                  id="isToBeNotified"
                  color="primary"
                  defaultChecked={setDefault()}
                  onChange={handleChange}
                />
              }
              label="Party is to be notified?"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>{' '}
            <Button variant="outlined" onClick={handleResetClick} style={{ marginRight: '16px' }}>
              Reset Form
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );

  const handleDelete = (partyFunction: string) => {
    switch (partyFunction) {
      case 'OS':
        deleteDocumentParty.mutate(documentParties[shipperIndex].id);
        setShipperIndex(-1);
        break;
      case 'CN':
        deleteDocumentParty.mutate(documentParties[consigneeIndex].id);
        setConsigneeIndex(-1);
        break;
      case 'N1':
        deleteDocumentParty.mutate(documentParties[notifyIndex].id);
        setNotifyIndex(-1);
        break;
    }
  };

  const renderPartyButton = (
    title: string,
    index: number,
    handleClick: () => void,
    handleDelete: (prefix: string) => void,
    prefix: string,
  ) => (
    <>
      {index == -1 ? (
        <>
          <Button variant="contained" onClick={handleClick}>
            Add {title}
          </Button>
        </>
      ) : (
        <>
          <Button variant="contained" onClick={handleClick} sx={{ marginRight: 1 }}>
            Edit {title}
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            variant="contained"
            color="error"
            onClick={() => handleDelete(prefix)}
          >
            Delete
          </Button>
        </>
      )}
    </>
  );

  const columns = [
    { field: 'party', headerName: 'Party', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      sortable: false,
      // eslint-disable-next-line
      renderCell: (params: any) => params.value,
    },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
  ];

  const rows = [
    {
      id: '1',
      party: 'Shipper',
      name: getValues(`${shipperIndex}.party.partyContactDetails.${0}.name`),
      email: getValues(`${shipperIndex}.party.partyContactDetails.${0}.email`),
      actions: renderPartyButton('Shipper', shipperIndex, handleShipperClick, handleDelete, 'OS'),
    },
    {
      id: '2',
      party: 'Consignee',
      name: getValues(`${consigneeIndex}.party.partyContactDetails.${0}.name`),
      email: getValues(`${consigneeIndex}.party.partyContactDetails.${0}.email`),
      actions: renderPartyButton(
        'Consignee',
        consigneeIndex,
        handleConsigneeClick,
        handleDelete,
        'CN',
      ),
    },
    {
      id: '3',
      party: 'Notify Party',
      name: getValues(`${notifyIndex}.party.partyContactDetails.${0}.name`),
      email: getValues(`${notifyIndex}.party.partyContactDetails.${0}.email`),
      actions: renderPartyButton(
        'Notify Party',
        notifyIndex,
        handleNotifyPartyClick,
        handleDelete,
        'N1',
      ),
    },
  ];

  return (
    <>
      <Breadcrumbs style={{ fontSize: 35 }} aria-label="breadcrumb">
        <Link underline="hover" component={RouterLink} color="inherit" to="/">
          Shipments
        </Link>
        <Link
          underline="hover"
          component={RouterLink}
          color="text.primary"
          to={`/shipments/${booking.shipmentId}`}
        >
          {shipment?.name}
        </Link>
        <Link
          underline="hover"
          component={RouterLink}
          color="text.primary"
          to={`/booking-requests/${booking.id}/manage-document-parties`}
          onClick={() => {
            setEditingConsignee(false);
            setEditingNotifyParty(false);
            setEditingShipper(false);
          }}
        >
          Document Parties
        </Link>
        {editingShipper ? (
          <Typography style={{ fontSize: 35 }}>Shipper</Typography>
        ) : editingConsignee ? (
          <Typography style={{ fontSize: 35 }}>Consignee</Typography>
        ) : editingNotifyParty ? (
          <Typography style={{ fontSize: 35 }}>Notify Party</Typography>
        ) : null}
      </Breadcrumbs>
      {!editingShipper && !editingConsignee && !editingNotifyParty ? (
        <>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
          />
        </>
      ) : (
        <>{editForm}</>
      )}
    </>
  );
};

async function fetchShipment(id: string): Promise<ShipmentResponse> {
  const authHeader = await getAuthHeader();
  return shipmentsApi.shipmentsIdGet({ id }, { headers: authHeader });
}

async function fetchDocumentParties(id: string): Promise<Array<DocumentPartyResponse>> {
  const authHeader = await getAuthHeader();
  return documentPartiesApi.documentPartiesBookingRequestIdGet({ id }, { headers: authHeader });
}
