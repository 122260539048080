import { ComponentType, ReactElement } from 'react';
import { Badge, Icon } from '@mui/material';

interface ActionIconProps {
  badgeContent?: number;
  icon: ComponentType;
}

export const ActionIcon = ({ badgeContent, icon }: ActionIconProps): ReactElement => {
  return badgeContent ? (
    <Badge badgeContent={badgeContent} color={'primary'}>
      <Icon component={icon} />
    </Badge>
  ) : (
    <Icon component={icon} />
  );
};
