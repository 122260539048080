import React, { ReactElement, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  BookingResponse,
  ShipmentLocationRequest,
  ShipmentLocationResponse,
} from '../../generated';
import { shipmentLocationsApi } from '../../api/shipmentLocationsApi';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  formatShipmentLocationType,
  shipmentLocationTypeCodes,
} from '../../utils/shipmentLocationTypeCode';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const ShipmentLocationsDataGrid = ({
  booking,
}: {
  booking: BookingResponse;
}): ReactElement => {
  const [shipmentLocationId, setShipmentLocationId] = useState('');
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ShipmentLocationRequest>({ mode: 'onChange' });
  const queryClient = useQueryClient();
  const shipmentLocations = booking.shipmentLocations as ShipmentLocationResponse[];

  const editShipmentLocation = useMutation(
    async (shipmentLocationData: ShipmentLocationRequest) => {
      const authHeader = await getAuthHeader();
      return shipmentLocationsApi.shipmentLocationsIdPut(
        { id: shipmentLocationId, shipmentLocationRequest: shipmentLocationData },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipment']);
        handleFormClick();
        toast.success('Successfully edited shipment location');
      },
    },
  );

  const deleteShipmentLocation = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();
      return shipmentLocationsApi.shipmentLocationsIdDelete({ id }, { headers: authHeader });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['shipment']);
        toast.success('Successfully deleted shipment location');
      },
    },
  );

  const setShipmentLocationValue = (id: string) => {
    const findShipmentLocationById = shipmentLocations.find((c) => c.id === id);
    const shipmentLocation = findShipmentLocationById as ShipmentLocationResponse;
    setShipmentLocationId(shipmentLocation.id as string);
    setValue('location', shipmentLocation.location);
    setValue('shipmentLocationTypeCode', shipmentLocation.shipmentLocationTypeCode);
  };

  const columns: GridColDef[] = [
    {
      field: 'location',
      headerName: 'Location name',
      width: 150,
      valueFormatter: ({ value }) => value.locationName,
    },
    {
      field: 'shipmentLocationTypeCode',
      headerName: 'Type code',
      width: 150,
      valueFormatter: ({ value }) => formatShipmentLocationType(value as string),
    },

    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const editOnClick = () => {
          handleFormClick();
          setShipmentLocationValue(params.row.id as string);
        };

        const deleteOnClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          deleteShipmentLocation.mutate(params.row.id);
        };
        return (
          <>
            {booking.status === 'DRFT' ? (
              <>
                <Button
                  style={{
                    marginRight: '20px',
                  }}
                  variant="contained"
                  onClick={editOnClick}
                >
                  Edit
                </Button>
                <Button variant="contained" color="error" onClick={deleteOnClick}>
                  Delete
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    marginRight: '20px',
                  }}
                  variant="contained"
                  onClick={editOnClick}
                >
                  View
                </Button>
                <Tooltip title="Can only be deleted during draft state">
                  <span>
                    <Button
                      variant="contained"
                      color="error"
                      disabled={true}
                      onClick={deleteOnClick}
                    >
                      Delete
                    </Button>
                  </span>
                </Tooltip>
              </>
            )}
          </>
        );
      },
    },
  ];

  const onSubmit: SubmitHandler<ShipmentLocationRequest> = (newShipmentLocationData) => {
    newShipmentLocationData.bookingId = booking.id;
    editShipmentLocation.mutate(newShipmentLocationData);
  };

  const handleFormClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {shipmentLocations !== undefined && (
        <>
          <Box sx={{ height: 500 }}>
            <DataGrid
              rows={shipmentLocations}
              getRowId={(row) => row.id}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </Box>

          <Dialog fullWidth open={open} onClose={handleFormClick}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogTitle>Edit shipment location</DialogTitle>
              <DialogContent>
                <Grid container direction={'column'} spacing={3}>
                  <Grid item>
                    <TextField
                      {...register('location.locationName')}
                      margin="dense"
                      id="location.locationName"
                      label="Location name"
                      fullWidth
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      {...register('location.uNLocationCode', { maxLength: 5 })}
                      margin="dense"
                      id="location.uNLocationCode"
                      label="UN location code"
                      error={Boolean(errors.location?.uNLocationCode)}
                      helperText={
                        errors.location?.uNLocationCode && 'Max length of 5 characters (e.g. NLRTM)'
                      }
                      fullWidth
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      select
                      fullWidth
                      defaultValue={getValues('shipmentLocationTypeCode')}
                      label="Shipment location type code"
                      inputProps={register('shipmentLocationTypeCode', {
                        required: true,
                      })}
                      InputLabelProps={{ shrink: true }}
                    >
                      {shipmentLocationTypeCodes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleFormClick}>Cancel</Button>
                {booking.status === 'DRFT' ? (
                  <Button type="submit">Submit</Button>
                ) : (
                  <Tooltip title="Can only be edited during draft state">
                    <span>
                      <Button type="submit" disabled={true}>
                        Submit
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </DialogActions>
            </form>
          </Dialog>
        </>
      )}
    </>
  );
};
