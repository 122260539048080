import { Menu, MenuItem } from '@mui/material';

import { signOut as firebaseSignOut } from 'firebase/auth';
import { ReactElement } from 'react';
import { auth } from '../../../Auth';
import { SignOut } from '../../Actions';

interface DefaultMenuProps {
  isMenuOpen: boolean;
  handleMenuClose: () => void;
  anchorEl: HTMLElement | null;
}

async function signOut() {
  try {
    await firebaseSignOut(auth);
    document.location.href = '/';
    // eslint-disable-next-line
  } catch (error: any) {
    console.error('Error signing out:', error.message);
  }
}

export const DefaultMenu = ({
  isMenuOpen,
  handleMenuClose,
  anchorEl,
}: DefaultMenuProps): ReactElement => (
  <Menu
    anchorEl={anchorEl}
    id="primary-search-account-menu"
    keepMounted
    open={isMenuOpen}
    onClose={handleMenuClose}
  >
    <MenuItem onClick={signOut}>
      <SignOut disableTooltip />
      Sign Out
    </MenuItem>
  </Menu>
);
