// Library imports
import { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { toast } from 'react-toastify';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

// Component imports
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

// Internal imports
import { BookingResponse, CommodityRequest, CommodityResponse } from '../../generated';
import { commoditiesApi } from '../../api/commoditiesApi';
import { volumeUnitOptions, weightUnitOptions } from '../../utils/unitOptions';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const CommoditiesManager = ({ booking }: { booking: BookingResponse }): ReactElement => {
  const [commodityId, setCommodityId] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [issueDateValue, setIssueDateValue] = useState<Dayjs | null>(null);
  const [expiryDateValue, setExpiryDateValue] = useState<Dayjs | null>(null);

  const queryClient = useQueryClient();
  const { register, handleSubmit, setValue, reset, getValues } = useForm<CommodityRequest>();
  const commodities = booking.commodities as CommodityResponse[];

  const handleMutationSuccess = (message: string) => {
    queryClient.invalidateQueries(['shipment']);
    toast.success(message);
    toggleForm();
    reset();
    setIssueDateValue(null);
    setExpiryDateValue(null);
  };

  const formatDates = (commodityData: CommodityRequest) => {
    if (issueDateValue) {
      commodityData.exportLicenseIssueDate = dayjs(issueDateValue).format('MM/DD/YYYY');
    }
    if (expiryDateValue) {
      commodityData.exportLicenseExpiryDate = dayjs(expiryDateValue).format('MM/DD/YYYY');
    }
  };

  const addCommodity = useMutation(
    async (commodityData: CommodityRequest) => {
      formatDates(commodityData);
      const authHeader = await getAuthHeader();
      return commoditiesApi.commoditiesPost(
        { commodityRequest: commodityData },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => handleMutationSuccess('Successfully added commodity'),
    },
  );

  const editCommodity = useMutation(
    async (commodityData: CommodityRequest) => {
      if (!commodityId) throw new Error('Commodity ID is not defined');
      formatDates(commodityData);
      const authHeader = await getAuthHeader();
      return commoditiesApi.commoditiesIdPut(
        { id: commodityId, commodityRequest: commodityData },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => handleMutationSuccess('Successfully edited commodity'),
    },
  );

  const deleteCommodity = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();
      return commoditiesApi.commoditiesIdDelete({ id }, { headers: authHeader });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['shipment']);
        toast.success('Successfully deleted commodity');
      },
    },
  );

  const setCommodityValue = (id: string) => {
    const commodity = commodities.find((c) => c.id === id);
    if (!commodity) return;

    setCommodityId(commodity.id as string);
    setValue('commodityName', commodity.commodityName);
    setValue('commodityDescription', commodity.commodityDescription);
    setValue('kindOfPackages', commodity.kindOfPackages);
    setValue('numberOfPackages', commodity.numberOfPackages);
    setValue('hSCode', commodity.hSCode);
    setValue('cargoGrossWeight', commodity.cargoGrossWeight);
    setValue('cargoGrossWeightUnit', commodity.cargoGrossWeightUnit);
    setValue('cargoGrossVolume', commodity.cargoGrossVolume);
    setValue('cargoGrossVolumeUnit', commodity.cargoGrossVolumeUnit);

    if (commodity.exportLicenseIssueDate) {
      setIssueDateValue(dayjs(commodity.exportLicenseIssueDate));
    }
    if (commodity.exportLicenseExpiryDate) {
      setExpiryDateValue(dayjs(commodity.exportLicenseExpiryDate));
    }
  };

  const toggleForm = () => setOpen((prev) => !prev);

  const columns: GridColDef[] = [
    { field: 'commodityName', headerName: 'Commodity type', width: 150 },
    { field: 'cargoGrossWeight', headerName: 'Cargo weight', width: 150 },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const editOnClick = () => {
          handleFormClick();
          setCommodityValue(params.row.id as string);
        };

        const deleteOnClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          deleteCommodity.mutate(params.row.id);
        };

        return (
          <>
            {booking.status === 'DRFT' ? (
              <>
                <Button style={{ marginRight: '20px' }} variant="contained" onClick={editOnClick}>
                  Edit
                </Button>
                <Button variant="contained" color="error" onClick={deleteOnClick}>
                  Delete
                </Button>
              </>
            ) : (
              <>
                <Button style={{ marginRight: '20px' }} variant="contained" onClick={editOnClick}>
                  View
                </Button>
                <Tooltip title="Can only be deleted during draft state">
                  <span>
                    <Button
                      variant="contained"
                      color="error"
                      disabled={true}
                      onClick={deleteOnClick}
                    >
                      Delete
                    </Button>
                  </span>
                </Tooltip>
              </>
            )}
          </>
        );
      },
    },
  ];

  const onSubmit: SubmitHandler<CommodityRequest> = (newCommodityData) => {
    newCommodityData.bookingId = booking.id;
    if (commodityId) {
      // Update logic for editing commodities
      newCommodityData.exportLicenseIssueDate = issueDateValue?.toString();
      newCommodityData.exportLicenseExpiryDate = expiryDateValue?.toString();
      editCommodity.mutate(newCommodityData);
    } else {
      // Add new commodity
      addCommodity.mutate(newCommodityData);
    }
  };

  const handleFormClick = () => {
    setOpen((prev) => !prev);

    // Reset commodityId when the form is closed
    if (!open) {
      setCommodityId(null);
    }

    // Add a return statement
    return null;
  };

  return (
    <>
      <Grid item xs={4}>
        <Typography sx={{ mt: 4 }} variant="h5" gutterBottom>
          Commodities{' '}
          {booking.status === 'DRFT' && (
            <IconButton
              style={{
                position: 'relative',
                bottom: '2px',
                right: '10px',
              }}
              color="primary"
              onClick={handleFormClick}
            >
              <AddCircleOutlineOutlined />
            </IconButton>
          )}
        </Typography>

        {commodities !== undefined ? (
          <Box sx={{ height: 500 }}>
            <DataGrid
              rows={commodities}
              getRowId={(row) => row.id}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </Box>
        ) : (
          <Typography>(No commodities attached yet)</Typography>
        )}

        <Dialog fullWidth open={open} onClose={handleFormClick}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>{commodityId ? 'Edit Commodity' : 'Add Commodity'}</DialogTitle>
            <DialogContent>
              <Grid container direction={'column'} spacing={3}>
                <Grid item>
                  <TextField
                    {...register('commodityName')}
                    required
                    margin="dense"
                    id="commodityName"
                    label="Commodity name"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    {...register('commodityDescription')}
                    required
                    multiline
                    rows={2}
                    margin="dense"
                    id="commodityDescription"
                    label="Commodity description"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {booking.eblType == 'DCSA' && (
                  <Grid item>
                    <TextField
                      {...register('hSCode', { maxLength: 10 })}
                      margin="dense"
                      id="HSCode"
                      label="HS Code"
                      fullWidth
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                )}
                {booking.eblType == 'BIMCO' && (
                  <>
                    <Grid item>
                      <TextField
                        {...register('numberOfPackages', { valueAsNumber: true })}
                        margin="dense"
                        id="numberOfPackages"
                        required
                        type="number"
                        label="Number of packages"
                        fullWidth
                        variant="standard"
                        inputProps={{
                          step: 1,
                          min: 0,
                          precision: 2,
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('kindOfPackages', { maxLength: 100 })}
                        margin="dense"
                        id="kindOfPackages"
                        required
                        label="Kind of packages"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        {...register('cargoGrossWeight', {
                          valueAsNumber: true,
                        })}
                        required
                        margin="dense"
                        id="cargoGrossWeight"
                        label="Cargo gross weight"
                        fullWidth
                        type="number"
                        inputProps={{
                          min: 0,
                          step: 0.01,
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        select
                        fullWidth
                        defaultValue={getValues('cargoGrossWeightUnit')}
                        label="Cargo gross weight unit"
                        inputProps={register('cargoGrossWeightUnit')}
                        required
                        InputLabelProps={{ shrink: true }}
                      >
                        {weightUnitOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item>
                      <TextField
                        {...register('cargoGrossVolume', {
                          valueAsNumber: true,
                        })}
                        required
                        margin="dense"
                        id="cargoGrossVolume"
                        label="Cargo gross volume"
                        fullWidth
                        type="number"
                        inputProps={{
                          step: 1,
                          precision: 2,
                          min: 0,
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid item>
                      <TextField
                        select
                        fullWidth
                        defaultValue={getValues('cargoGrossVolumeUnit')}
                        label="Cargo gross volume unit"
                        inputProps={register('cargoGrossVolumeUnit')}
                        required
                        InputLabelProps={{ shrink: true }}
                      >
                        {volumeUnitOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFormClick}>Cancel</Button>
              <Button type="submit">{commodityId ? 'Update' : 'Submit'}</Button>
            </DialogActions>
          </form>
        </Dialog>
      </Grid>
    </>
  );
};
