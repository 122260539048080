/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSummary
 */
export interface UserSummary {
    /**
     * 
     * @type {number}
     * @memberof UserSummary
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSummary
     */
    uid?: string;
}

/**
 * Check if a given object implements the UserSummary interface.
 */
export function instanceOfUserSummary(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserSummaryFromJSON(json: any): UserSummary {
    return UserSummaryFromJSONTyped(json, false);
}

export function UserSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
    };
}

export function UserSummaryToJSON(value?: UserSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'uid': value.uid,
    };
}

