/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BookingRequest,
  BookingRequestsIdCallbackPutRequest,
  BookingRequestsIdCancelPostRequest,
  BookingResponse,
} from '../models';
import {
    BookingRequestFromJSON,
    BookingRequestToJSON,
    BookingRequestsIdCallbackPutRequestFromJSON,
    BookingRequestsIdCallbackPutRequestToJSON,
    BookingRequestsIdCancelPostRequestFromJSON,
    BookingRequestsIdCancelPostRequestToJSON,
    BookingResponseFromJSON,
    BookingResponseToJSON,
} from '../models';

export interface BookingRequestsIdCallbackPutOperationRequest {
    id: string;
    bookingRequestsIdCallbackPutRequest?: BookingRequestsIdCallbackPutRequest;
}

export interface BookingRequestsIdCancelPostOperationRequest {
    id: string;
    bookingRequestsIdCancelPostRequest?: BookingRequestsIdCancelPostRequest;
}

export interface BookingRequestsIdDeleteRequest {
    id: string;
}

export interface BookingRequestsIdGetRequest {
    id: string;
}

export interface BookingRequestsIdPutRequest {
    id: string;
    reset?: boolean;
    bookingRequest?: BookingRequest;
}

export interface BookingRequestsIdSendPostRequest {
    id: string;
}

export interface BookingRequestsPostRequest {
    bookingRequest?: BookingRequest;
}

/**
 * 
 */
export class BookingsApi extends runtime.BaseAPI {

    /**
     * Get all booking requests that the user has view access for. 
     */
    async bookingRequestsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BookingResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/booking-requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BookingResponseFromJSON));
    }

    /**
     * Get all booking requests that the user has view access for. 
     */
    async bookingRequestsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BookingResponse>> {
        const response = await this.bookingRequestsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the status of a booking or ebl. This should be called by an external carrier service.  Regarding authentication, only requests from whitelisted carrier APIs should be accepted. 
     */
    async bookingRequestsIdCallbackPutRaw(requestParameters: BookingRequestsIdCallbackPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bookingRequestsIdCallbackPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/booking-requests/{id}/callback`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BookingRequestsIdCallbackPutRequestToJSON(requestParameters.bookingRequestsIdCallbackPutRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the status of a booking or ebl. This should be called by an external carrier service.  Regarding authentication, only requests from whitelisted carrier APIs should be accepted. 
     */
    async bookingRequestsIdCallbackPut(requestParameters: BookingRequestsIdCallbackPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bookingRequestsIdCallbackPutRaw(requestParameters, initOverrides);
    }

    /**
     * Shipper initiates cancellation of a booking
     */
    async bookingRequestsIdCancelPostRaw(requestParameters: BookingRequestsIdCancelPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bookingRequestsIdCancelPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/booking-requests/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookingRequestsIdCancelPostRequestToJSON(requestParameters.bookingRequestsIdCancelPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Shipper initiates cancellation of a booking
     */
    async bookingRequestsIdCancelPost(requestParameters: BookingRequestsIdCancelPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bookingRequestsIdCancelPostRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a single booking request from the database
     */
    async bookingRequestsIdDeleteRaw(requestParameters: BookingRequestsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bookingRequestsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/booking-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single booking request from the database
     */
    async bookingRequestsIdDelete(requestParameters: BookingRequestsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bookingRequestsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get booking request by id 
     */
    async bookingRequestsIdGetRaw(requestParameters: BookingRequestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BookingResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bookingRequestsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/booking-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingResponseFromJSON(jsonValue));
    }

    /**
     * Get booking request by id 
     */
    async bookingRequestsIdGet(requestParameters: BookingRequestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BookingResponse> {
        const response = await this.bookingRequestsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specified booking request in the database
     */
    async bookingRequestsIdPutRaw(requestParameters: BookingRequestsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bookingRequestsIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.reset !== undefined) {
            queryParameters['reset'] = requestParameters.reset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/booking-requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BookingRequestToJSON(requestParameters.bookingRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the specified booking request in the database
     */
    async bookingRequestsIdPut(requestParameters: BookingRequestsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bookingRequestsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     * Send the currently attached booking request to the carrier 
     */
    async bookingRequestsIdSendPostRaw(requestParameters: BookingRequestsIdSendPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bookingRequestsIdSendPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/booking-requests/{id}/send`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send the currently attached booking request to the carrier 
     */
    async bookingRequestsIdSendPost(requestParameters: BookingRequestsIdSendPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bookingRequestsIdSendPostRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new booking request to the database
     */
    async bookingRequestsPostRaw(requestParameters: BookingRequestsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/booking-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookingRequestToJSON(requestParameters.bookingRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a new booking request to the database
     */
    async bookingRequestsPost(requestParameters: BookingRequestsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.bookingRequestsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
