import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { TitleEscrow, TradeTrustToken__factory } from '@govtechsg/token-registry/dist/contracts';
import { getTokenRegistryAddress } from '../../tradeTrust/tokenRegistry';
import { defaultIssuingAddress, getWalletSigner } from '../../utils/wallet';

interface Props {
  connectedEscrow: TitleEscrow;
}

function button(disabled: boolean): JSX.Element {
  return (
    <Button
      disabled={disabled}
      style={{ marginLeft: 5 }}
      onClick={() => print()}
      variant="contained"
    >
      Download as PDF
    </Button>
  );
}

// Small component to view and update the current nominee to be the new beneficiary
export function PrintBtn(props: Props): ReactElement {
  const [printable, setPrintable] = useState<boolean>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const signer = getWalletSigner();
        const tokenRegistry = TradeTrustToken__factory.connect(
          getTokenRegistryAddress(parseInt(window.ethereum?.networkVersion)),
          signer,
        );
        const tokenId = await props.connectedEscrow.tokenId();
        const active = await props.connectedEscrow.active();
        const owner = await tokenRegistry.ownerOf(tokenId);

        const surrendered = owner === tokenRegistry.address;

        // The document may only be printed when the eBL process is complete;
        // meaning it is 'surrendered' and/or 'shredded'.
        if (surrendered || !active) {
          const walletAddr = window.ethereum?.selectedAddress;
          if (walletAddr?.toLowerCase() === defaultIssuingAddress.toLowerCase()) {
            setPrintable(true);
          } else {
            setPrintable(false);
          }
        } else {
          setPrintable(false);
        }
      } catch (error) {
        console.error('Failed to fetch owner and holder information');
      }
    };

    window.ethereum.on('accountsChanged', function () {
      fetchData();
    });

    fetchData();
  }, [props.connectedEscrow]);

  return (
    <div>
      {printable ? (
        button(false)
      ) : (
        <Tooltip title="Can only be printed by the original issuer, when eBL is surrendered">
          <span>{button(true)}</span>
        </Tooltip>
      )}
    </div>
  );
}
