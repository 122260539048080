/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An interface used to express a location using a `Un Location Code`
 * @export
 * @interface UnLocationLocation
 */
export interface UnLocationLocation {
    /**
     * 
     * @type {string}
     * @memberof UnLocationLocation
     */
    locationId?: string;
    /**
     * The name of the location.
     * @type {string}
     * @memberof UnLocationLocation
     */
    locationName?: string;
    /**
     * The UN Location code specifying where the place is located.
     * @type {string}
     * @memberof UnLocationLocation
     */
    uNLocationCode: string;
}

/**
 * Check if a given object implements the UnLocationLocation interface.
 */
export function instanceOfUnLocationLocation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uNLocationCode" in value;

    return isInstance;
}

export function UnLocationLocationFromJSON(json: any): UnLocationLocation {
    return UnLocationLocationFromJSONTyped(json, false);
}

export function UnLocationLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnLocationLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'locationName': !exists(json, 'locationName') ? undefined : json['locationName'],
        'uNLocationCode': json['UNLocationCode'],
    };
}

export function UnLocationLocationToJSON(value?: UnLocationLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationId': value.locationId,
        'locationName': value.locationName,
        'UNLocationCode': value.uNLocationCode,
    };
}

