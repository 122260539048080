import { ReactElement, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { documentPartiesApi } from '../../api/documentPartiesApi';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  DocumentPartyRequest,
  DocumentPartyResponse,
  PartyDataIdentifyingCodesInner,
  PartyDataPartyContactDetailsInner,
} from '../../generated';
import { NotFound } from '../../pages/NotFound';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const DocumentPartiesEdit = (): ReactElement => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [codeOpen, setCodeOpen] = useState<boolean[]>([]);
  const [contactOpen, setContactOpen] = useState<boolean[]>([]);
  const [codeDialogOpen, setCodeDialogOpen] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  const queryClient = useQueryClient();

  const location = useLocation();
  const id = location.pathname.split('/')[2];

  const navigate = useNavigate();

  const setDocumentPartyValue = (documentParty: DocumentPartyResponse) => {
    setValue('party', documentParty.party);
    setValue('partyFunction', documentParty.partyFunction);
    setValue('displayedAddress', documentParty.displayedAddress);
    setValue('isToBeNotified', documentParty.isToBeNotified);
  };

  const {
    data: documentParty,
    isError: documentPartyError,
    isLoading: documentPartyIsLoading,
  } = useQuery(['documentParty', id], async () => {
    const data = await fetchDocumentParty(id);
    setDocumentPartyValue(data);
    setStates(data);
    if (!data.party.identifyingCodes) {
      data.party.identifyingCodes = [];
    }
    return data;
  });

  const { register, handleSubmit, setValue, getValues } = useForm<DocumentPartyRequest>();
  const { register: registerContact, handleSubmit: handleSubmitContact } =
    useForm<PartyDataPartyContactDetailsInner>();
  const { register: registerCode, handleSubmit: handleSubmitCode } =
    useForm<PartyDataIdentifyingCodesInner>();

  const editDocumentParty = useMutation(
    async (documentPartyData: DocumentPartyRequest) => {
      const authHeader = await getAuthHeader();
      return documentPartiesApi.documentPartiesIdPut(
        {
          id: id,
          documentPartyRequest: documentPartyData,
        },
        {
          headers: authHeader,
        },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['documentParty', id]);
        toast.success('Successfully edited document party');
        if (dialogOpen) {
          handleDialogSwitch();
        }
        if (codeDialogOpen) {
          setCodeDialogOpen(!codeDialogOpen);
          addState();
        } else if (contactDialogOpen) {
          setContactDialogOpen(!contactDialogOpen);
          addState();
        }
      },
    },
  );

  const handleCodeClick = () => {
    setCodeDialogOpen(!codeDialogOpen);
    setDialogOpen(!dialogOpen);
  };

  const handleContactClick = () => {
    setContactDialogOpen(!contactDialogOpen);
    setDialogOpen(!dialogOpen);
  };

  const setStates = (documentParty: DocumentPartyResponse) => {
    if (documentParty.party) {
      if (documentParty.party.partyContactDetails) {
        documentParty.party.partyContactDetails.forEach(() => {
          contactOpen.push(false);
        });
      }

      if (documentParty.party.identifyingCodes) {
        documentParty.party.identifyingCodes.forEach(() => {
          codeOpen.push(false);
        });
      }
    }
  };

  const addState = () => {
    if (codeDialogOpen) {
      codeOpen.push(false);
    } else if (contactDialogOpen) {
      contactOpen.push(false);
    }
  };

  const handleDialogSwitch = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleContactOpen = (id: number) => {
    const nextStates: boolean[] = [];
    contactOpen.forEach((state, index) => {
      if (index == id - 1) {
        nextStates.push(!state);
      } else {
        nextStates.push(state);
      }
    });
    setContactOpen([...nextStates]);
    handleDialogSwitch();
  };

  const handleCodeOpen = (id: number) => {
    const nextStates: boolean[] = [];
    codeOpen.forEach((state, index) => {
      if (index == id - 1) {
        nextStates.push(!state);
      } else {
        nextStates.push(state);
      }
    });
    setCodeOpen([...nextStates]);
    handleDialogSwitch();
  };

  const handleContactClose = () => {
    const nextStates = contactOpen.map((state) => {
      if (state) {
        return false;
      }
      return state;
    });
    setContactOpen([...nextStates]);
    handleDialogSwitch();
  };

  const handleCodeClose = () => {
    const nextStates = codeOpen.map((state) => {
      if (state) {
        return false;
      }
      return state;
    });
    setCodeOpen([...nextStates]);
    handleDialogSwitch();
  };

  if (documentPartyIsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }
  if (documentPartyError) {
    return <NotFound />;
  }

  const contactDetails: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'url', headerName: 'URL', width: 150 },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      width: 180,
      renderCell: (params) => {
        const deleteOnClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          handleContactDelete(params.row.id);
        };

        const editOnClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          handleContactOpen(params.row.id);
        };

        return (
          <>
            <Button
              style={{
                marginRight: '20px',
              }}
              variant="contained"
              onClick={editOnClick}
            >
              Edit
            </Button>

            <Button variant="contained" color="error" onClick={deleteOnClick}>
              Delete
            </Button>

            <Dialog open={contactOpen[params.row.id - 1]} onClose={handleContactClose}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Update Contact Details {params.row.id - 1}</DialogTitle>
                <DialogContent>
                  <TextField
                    {...register(`party.partyContactDetails.${params.row.id - 1}.name`)}
                    required
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    {...register(`party.partyContactDetails.${params.row.id - 1}.phone`)}
                    margin="dense"
                    id="phone"
                    label="Phone"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    {...register(`party.partyContactDetails.${params.row.id - 1}.email`)}
                    margin="dense"
                    id="email"
                    label="Email"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    {...register(`party.partyContactDetails.${params.row.id - 1}.url`)}
                    margin="dense"
                    id="url"
                    label="URL"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleContactClose}>Cancel</Button>
                  <Button type="submit">Save Changes</Button>
                </DialogActions>
              </form>
            </Dialog>
          </>
        );
      },
    },
  ];

  const identifyingCodes: GridColDef[] = [
    { field: 'dCSAResponsibleAgencyCode', headerName: 'DCSA Responsible Agency Code', width: 150 },
    { field: 'partyCode', headerName: 'Party Code', width: 150 },
    { field: 'codeListName', headerName: 'Code List Name', width: 150 },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      width: 180,
      renderCell: (params) => {
        const deleteOnClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          handleCodeDelete(params.row.id - 1);
        };

        const editOnClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          handleCodeOpen(params.row.id);
        };

        return (
          <>
            <Button
              style={{
                marginRight: '20px',
              }}
              variant="contained"
              onClick={editOnClick}
            >
              Edit
            </Button>

            <Button variant="contained" color="error" onClick={deleteOnClick}>
              Delete
            </Button>

            <Dialog open={codeOpen[params.row.id - 1]} onClose={handleCodeClose}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Update Identifying Codes</DialogTitle>
                <DialogContent>
                  <TextField
                    {...register(
                      `party.identifyingCodes.${params.row.id - 1}.dCSAResponsibleAgencyCode`,
                    )}
                    required
                    margin="dense"
                    id="dcsaResponsibleAgencyCode"
                    label="DCSA Responsible Agency Code"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    {...register(`party.identifyingCodes.${params.row.id - 1}.partyCode`)}
                    required
                    margin="dense"
                    id="partyCode"
                    label="Party Code"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    {...register(`party.identifyingCodes.${params.row.id - 1}.codeListName`)}
                    margin="dense"
                    id="codeListName"
                    label="Codelist Name"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCodeClose}>Cancel</Button>
                  <Button type="submit">Save Changes</Button>
                </DialogActions>
              </form>
            </Dialog>
          </>
        );
      },
    },
  ];

  const handleReturnClick = () => {
    navigate(-1);
  };

  const handleCodeDelete = (index: number) => {
    if (documentParty.party && documentParty.party.identifyingCodes) {
      const newCodes: PartyDataIdentifyingCodesInner[] = [];
      documentParty.party.identifyingCodes.forEach((val, i) => {
        if (i != index) {
          newCodes.push(val);
        }
      });
      setValue('party.identifyingCodes', newCodes);
      const newDocumentParty: DocumentPartyRequest = {
        party: getValues('party'),
        partyFunction: getValues('partyFunction'),
        displayedAddress: getValues('displayedAddress'),
        isToBeNotified: getValues('isToBeNotified'),
        bookingId: getValues('bookingId'),
      };

      editDocumentParty.mutate(newDocumentParty);
    }
  };

  const handleContactDelete = (id: number) => {
    if (documentParty.party && documentParty.party.partyContactDetails) {
      const newContacts: PartyDataPartyContactDetailsInner[] = [];
      documentParty.party.partyContactDetails.forEach((val, i) => {
        if (i != id - 1) {
          newContacts.push(val);
        }
      });
      setValue('party.partyContactDetails', newContacts);
      const newDocumentParty: DocumentPartyRequest = {
        party: getValues('party'),
        partyFunction: getValues('partyFunction'),
        displayedAddress: getValues('displayedAddress'),
        isToBeNotified: getValues('isToBeNotified'),
        bookingId: getValues('bookingId'),
      };

      editDocumentParty.mutate(newDocumentParty);
    }
  };

  const onSubmit: SubmitHandler<DocumentPartyRequest> = (newDocumentPartyData) => {
    editDocumentParty.mutate(newDocumentPartyData);
  };

  const onSubmitContact: SubmitHandler<PartyDataPartyContactDetailsInner> = (newContactDetails) => {
    const newDocumentPartyData = getValues();
    newDocumentPartyData.party.partyContactDetails.push(newContactDetails);
    editDocumentParty.mutate(newDocumentPartyData);
  };

  const onSubmitCode: SubmitHandler<PartyDataIdentifyingCodesInner> = (newIdentifyingCodes) => {
    const newDocumentPartyData = getValues();
    if (!newDocumentPartyData.party.identifyingCodes) {
      newDocumentPartyData.party.identifyingCodes = [newIdentifyingCodes];
    } else {
      newDocumentPartyData.party.identifyingCodes?.push(newIdentifyingCodes);
    }
    editDocumentParty.mutate(newDocumentPartyData);
  };

  const handleChange = () => {
    setValue('isToBeNotified', !getValues('isToBeNotified'));
  };

  const contactFooter = (): ReactElement => {
    return (
      <>
        <GridFooterContainer>
          <Button sx={{ margin: 1 }} variant="contained" onClick={handleContactClick}>
            Add new contact details
          </Button>
          <Dialog open={contactDialogOpen} onClose={handleContactClick}>
            <form onSubmit={handleSubmitContact(onSubmitContact)}>
              <DialogTitle>Add Contact Details</DialogTitle>
              <DialogContent>
                <TextField
                  {...registerContact(`name`, { required: true })}
                  margin="dense"
                  id="name"
                  label="Name"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  {...registerContact(`phone`)}
                  margin="dense"
                  id="phone"
                  label="Phone"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  {...registerContact(`email`)}
                  margin="dense"
                  id="email"
                  label="Email"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  {...registerContact(`url`)}
                  margin="dense"
                  id="url"
                  label="URL"
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleContactClick}>Cancel</Button>
                <Button type="submit">Save Changes contact</Button>
              </DialogActions>
            </form>
          </Dialog>

          <GridFooter
            sx={{
              border: 'none',
            }}
          />
        </GridFooterContainer>
      </>
    );
  };

  const codeFooter = (): ReactElement => {
    return (
      <>
        <GridFooterContainer>
          <Button sx={{ margin: 1 }} variant="contained" onClick={handleCodeClick}>
            Add new identifying codes
          </Button>
          <Dialog open={codeDialogOpen} onClose={handleCodeClick}>
            <form onSubmit={handleSubmitCode(onSubmitCode)}>
              <DialogTitle>Add Identifying Codes</DialogTitle>
              <DialogContent>
                <TextField
                  {...registerCode(`dCSAResponsibleAgencyCode`)}
                  required
                  margin="dense"
                  id="dcsaResponsibleAgencyCode"
                  label="DCSA Responsible Agency Code"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  {...registerCode(`partyCode`)}
                  required
                  margin="dense"
                  id="partyCode"
                  label="Party Code"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  {...registerCode(`codeListName`)}
                  margin="dense"
                  id="codeListName"
                  label="Codelist Name"
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCodeClick}>Cancel</Button>
                <Button type="submit">Save Changes</Button>
              </DialogActions>
            </form>
          </Dialog>

          <GridFooter
            sx={{
              border: 'none',
            }}
          />
        </GridFooterContainer>
      </>
    );
  };

  const editForm = (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography>Party Details:</Typography>
          </Grid>
          <Grid item>
            <TextField
              {...register('party.partyName', { required: true })}
              margin="dense"
              id="partyName"
              label="Party Name"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('party.taxReference1')}
              margin="dense"
              id="taxReference1"
              label="Tax Reference 1"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('party.taxReference2')}
              margin="dense"
              id="taxReference2"
              label="Tax Reference 2"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('party.publicKey')}
              margin="dense"
              id="publicKey"
              label="Public Key"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('partyFunction', { required: true })}
              margin="dense"
              id="partyFunction"
              label="Party Function"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  {...register('isToBeNotified', { required: true })}
                  id="isToBeNotified"
                  color="primary"
                  defaultChecked={documentParty.isToBeNotified as boolean}
                  onChange={handleChange}
                />
              }
              label="Party is to be notified?"
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <Button variant="contained" onClick={handleReturnClick}>
                  Return
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" type="submit">
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography>Address Details:</Typography>
          </Grid>
          <Grid item>
            <TextField
              {...register('party.address.addressName')}
              margin="dense"
              id="addressName"
              label="Address Name"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('party.address.streetName')}
              margin="dense"
              id="streetName"
              label="Street Name"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('party.address.streetNumber')}
              margin="dense"
              id="streetNumber"
              label="Street Number"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('party.address.floor')}
              margin="dense"
              id="floor"
              label="Floor"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('party.address.postCode')}
              margin="dense"
              id="postCode"
              label="Post Code"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('party.address.cityName')}
              margin="dense"
              id="cityName"
              label="City"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('party.address.stateRegion')}
              margin="dense"
              id="stateRegion"
              label="State/Region"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register('party.address.country')}
              margin="dense"
              id="country"
              label="Country"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              {...register(`displayedAddress.${0}`)}
              margin="dense"
              id="displayedAddress"
              label="Single line address"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        {documentParty.party && documentParty.party.partyContactDetails && (
          <Box sx={{ height: 500 }}>
            <DataGrid
              rows={documentParty.party.partyContactDetails}
              columns={contactDetails}
              pageSize={5}
              rowsPerPageOptions={[5]}
              components={{ Footer: contactFooter }}
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={4}>
        {documentParty.party && documentParty.party.identifyingCodes && (
          <Box sx={{ height: 500 }}>
            <DataGrid
              rows={documentParty.party.identifyingCodes}
              columns={identifyingCodes}
              pageSize={5}
              rowsPerPageOptions={[5]}
              components={{ Footer: codeFooter }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );

  return (
    <>
      {!dialogOpen ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>{editForm}</form>
        </>
      ) : (
        <>{editForm}</>
      )}
    </>
  );
};

async function fetchDocumentParty(id: string): Promise<DocumentPartyResponse> {
  const authHeader = await getAuthHeader();
  return documentPartiesApi.documentPartiesIdGet({ id }, { headers: authHeader });
}
