import { Alert, List, ListItem } from '@mui/material';
import { BookingResponse } from '../../generated';

export function BookingRequestChecklist({ booking }: { booking?: BookingResponse }) {
  const hasCommodity = (booking?.commodities?.length as number) > 0;
  const hasPOL = booking?.shipmentLocations?.some((loc) => loc.shipmentLocationTypeCode === 'POL');
  const hasPOD = booking?.shipmentLocations?.some((loc) => loc.shipmentLocationTypeCode === 'POD');
  const hasShipper = booking?.documentParties?.some((party) => party.partyFunction === 'OS');
  const hasConsignee = booking?.documentParties?.some((party) => party.partyFunction === 'CN');
  const hasNotifyParty = booking?.documentParties?.some((party) => party.partyFunction === 'N1');

  if (hasCommodity && hasPOL && hasPOD && hasShipper && hasConsignee && hasNotifyParty) {
    return null;
  }

  return (
    <Alert severity="info">
      Before sending the booking request to the carrier, please provide the following information:
      {booking && (
        <List
          sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          {!hasCommodity && <ListItem>At least one Commodity</ListItem>}
          {!hasPOL && <ListItem>A shipment location with Port of Loading</ListItem>}
          {!hasPOD && <ListItem>A shipment location with Port of Discharge</ListItem>}
          {!hasShipper && <ListItem>Select a Shipper through manage document parties</ListItem>}
          {!hasConsignee && <ListItem>Select a Consignee through manage document parties</ListItem>}
          {!hasNotifyParty && (
            <ListItem>Select a Notify Party through manage document parties</ListItem>
          )}
        </List>
      )}
    </Alert>
  );
}
