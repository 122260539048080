/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Indicates the type of service offered at Origin. Options are defined in the Receipt/Delivery entity.
 * - CY (Container yard (incl. rail ramp))
 * - SD (Store Door)
 * - CFS (Container Freight Station)
 * @export
 */
export const ReceiptTypeAtOrigin = {
    Cy: 'CY',
    Sd: 'SD',
    Cfs: 'CFS'
} as const;
export type ReceiptTypeAtOrigin = typeof ReceiptTypeAtOrigin[keyof typeof ReceiptTypeAtOrigin];


export function ReceiptTypeAtOriginFromJSON(json: any): ReceiptTypeAtOrigin {
    return ReceiptTypeAtOriginFromJSONTyped(json, false);
}

export function ReceiptTypeAtOriginFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReceiptTypeAtOrigin {
    return json as ReceiptTypeAtOrigin;
}

export function ReceiptTypeAtOriginToJSON(value?: ReceiptTypeAtOrigin | null): any {
    return value as any;
}

