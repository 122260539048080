import { ethers } from 'ethers';

export function getWalletSigner(): ethers.Signer {
  const { ethereum } = window;
  const provider = new ethers.providers.Web3Provider(ethereum);
  if (provider.getSigner() === null) {
    console.error('No wallet connected');
  }
  return provider.getSigner();
}

// TODO this is a hardcoded address representing the issuer.
// Currently, only this identity can issue documents on our deployed title escrow registry.
export const defaultIssuingAddress = '0x5fF452c0B2e6d40551D335C601C28Ec09b0fd99B';
