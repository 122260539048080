import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TitleEscrow } from '@govtechsg/token-registry/dist/contracts';
import { toast } from 'react-toastify';
import { QueryClient } from '@tanstack/react-query';
import { Item, titleEscrowTransfer } from './TitleTransfer';
import { useConnectWallet } from '@web3-onboard/react';
import { Logger } from 'ethers/lib/utils';
import { showShortWalletAddr } from '../../utils/shortWalletAddress';

interface Props {
  connectedEscrow: TitleEscrow;
  queryClient: QueryClient;
  refreshTitleEscrowData: () => void;
}

// Small component to view and update the current nominee to be the new beneficiary
export function ManageSurrenderDocument(props: Props): ReactElement {
  const { handleSubmit, reset } = useForm<titleEscrowTransfer>();
  const [{ wallet }] = useConnectWallet();
  const [beneficiary, setBeneficiary] = useState<string>();
  const [holder, setHolder] = useState<string>();

  const walletAddr = wallet?.accounts?.[0].address;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const b = await props.connectedEscrow.beneficiary();
        const h = await props.connectedEscrow.holder();
        setBeneficiary(b);
        setHolder(h);
      } catch (error) {
        toast.error('Failed to fetch owner and holder information');
      }
    };

    fetchData();
  }, [props.connectedEscrow]);

  // If the current user is the beneficiary of the token,
  // perform the nominate change of owner here
  const performTransaction: SubmitHandler<titleEscrowTransfer> = async () => {
    try {
      const surrenderResult = await props.connectedEscrow.surrender();
      await surrenderResult.wait(1);
      toast.success('Surrender document succeeded');
      props.refreshTitleEscrowData();
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.code === Logger.errors.UNPREDICTABLE_GAS_LIMIT) {
        toast.info(
          `Please switch the active account to ${showShortWalletAddr(walletAddr)} in MetaMask`,
        );
      } else {
        toast.error(
          'Could not perform surrender document, please check gas amount and permissions',
        );
      }
    }
    reset();
  };

  return (
    <div>
      {holder?.toLowerCase() === walletAddr && beneficiary?.toLowerCase() === walletAddr && (
        <Item>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Surrender document
          </Typography>
          <Typography variant="body2">Surrender the eBL document back to the issuer</Typography>
          <form onSubmit={handleSubmit(performTransaction)}>
            <Button type="submit" variant="contained" sx={{ my: 1 }}>
              Surrender document
            </Button>
          </form>
        </Item>
      )}
    </div>
  );
}
