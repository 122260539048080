import { BookingRequest, ResponseError, ShipmentResponse } from '../../generated';

import Button from '@mui/material/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bookingsApi } from '../../api/bookingsApi';
import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { getAuthHeader } from '../../utils/getAuthHeader';

export default function AddBimcoForm(props: {
  shipment: ShipmentResponse;
  isDialogOpen: boolean;
  handleCloseDialog: (value: React.SetStateAction<boolean>) => void;
}) {
  const shipment = props.shipment;
  const openForm = props.isDialogOpen;
  const handleCloseDialog = props.handleCloseDialog;
  const queryClient = useQueryClient();
  const { register: bookingRegister, handleSubmit: bookingSubmit } = useForm<BookingRequest>();
  const [charterPartyDateValue, setCharterPartyDateValue] = useState<string | null>(null);

  const onSubmitBimcoForm: SubmitHandler<BookingRequest> = (newShipmentData) => {
    newShipmentData.shipmentId = shipment.id;
    createBooking.mutate(newShipmentData);
  };

  const createBooking = useMutation(
    async (booking: BookingRequest) => {
      const authHeader = await getAuthHeader();

      booking.eblType = 'BIMCO';
      booking.charterPartyDate = charterPartyDateValue as string;
      return bookingsApi.bookingRequestsPost({ bookingRequest: booking }, { headers: authHeader });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipment']);
        toast.success('Created draft booking request');
        handleClose();
      },
      onError: (error: ResponseError) => {
        queryClient.invalidateQueries(['bookings']);
        toast.error(`Error creating draft booking request: ${error.response.statusText}`);
      },
    },
  );

  const handleClose = () => {
    handleCloseDialog(false);
  };

  return (
    <>
      <Dialog open={openForm} onClose={handleClose}>
        <form onSubmit={bookingSubmit(onSubmitBimcoForm)}>
          <DialogTitle>Add BIMCO booking request</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill in the required BIMCO booking request fields
            </DialogContentText>
            <Grid container direction={'column'} spacing={3}>
              <Grid item>
                <TextField
                  {...bookingRegister('vesselName')}
                  required
                  margin="dense"
                  id="vesselName"
                  label="Vessel name"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Charter party date"
                    value={charterPartyDateValue}
                    onChange={(newValue) => {
                      const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
                      setCharterPartyDateValue(formattedDate);
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
