/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocumentPartyRequest,
  DocumentPartyResponse,
} from '../models';
import {
    DocumentPartyRequestFromJSON,
    DocumentPartyRequestToJSON,
    DocumentPartyResponseFromJSON,
    DocumentPartyResponseToJSON,
} from '../models';

export interface DocumentPartiesBookingRequestIdGetRequest {
    id: string;
}

export interface DocumentPartiesIdDeleteRequest {
    id: string;
}

export interface DocumentPartiesIdGetRequest {
    id: string;
}

export interface DocumentPartiesIdPutRequest {
    id: string;
    documentPartyRequest?: DocumentPartyRequest;
}

export interface DocumentPartiesPostRequest {
    documentPartyRequest?: DocumentPartyRequest;
}

/**
 * 
 */
export class DocumentPartyApi extends runtime.BaseAPI {

    /**
     * Get Document Parties by Booking id 
     */
    async documentPartiesBookingRequestIdGetRaw(requestParameters: DocumentPartiesBookingRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentPartyResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling documentPartiesBookingRequestIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document-parties/booking-request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentPartyResponseFromJSON));
    }

    /**
     * Get Document Parties by Booking id 
     */
    async documentPartiesBookingRequestIdGet(requestParameters: DocumentPartiesBookingRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentPartyResponse>> {
        const response = await this.documentPartiesBookingRequestIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a Document Party from the database
     */
    async documentPartiesIdDeleteRaw(requestParameters: DocumentPartiesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling documentPartiesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document-parties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Document Party from the database
     */
    async documentPartiesIdDelete(requestParameters: DocumentPartiesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.documentPartiesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get Document Party by id 
     */
    async documentPartiesIdGetRaw(requestParameters: DocumentPartiesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentPartyResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling documentPartiesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document-parties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentPartyResponseFromJSON(jsonValue));
    }

    /**
     * Get Document Party by id 
     */
    async documentPartiesIdGet(requestParameters: DocumentPartiesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentPartyResponse> {
        const response = await this.documentPartiesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specified Document Party in the database
     */
    async documentPartiesIdPutRaw(requestParameters: DocumentPartiesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling documentPartiesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/document-parties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentPartyRequestToJSON(requestParameters.documentPartyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the specified Document Party in the database
     */
    async documentPartiesIdPut(requestParameters: DocumentPartiesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.documentPartiesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new Document Party to the database
     */
    async documentPartiesPostRaw(requestParameters: DocumentPartiesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/document-parties`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentPartyRequestToJSON(requestParameters.documentPartyRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a new Document Party to the database
     */
    async documentPartiesPost(requestParameters: DocumentPartiesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.documentPartiesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
