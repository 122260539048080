/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Transport obligations, costs and risks as agreed between buyer and seller.
 * - FCA (Free Carrier)
 * - FOB (Free on Board)
 * @export
 */
export const IncoTerms = {
    Fca: 'FCA',
    Fob: 'FOB'
} as const;
export type IncoTerms = typeof IncoTerms[keyof typeof IncoTerms];


export function IncoTermsFromJSON(json: any): IncoTerms {
    return IncoTermsFromJSONTyped(json, false);
}

export function IncoTermsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncoTerms {
    return json as IncoTerms;
}

export function IncoTermsToJSON(value?: IncoTerms | null): any {
    return value as any;
}

