// APP TEXT
export const APP_DESCRIPTION =
  'Frontend application to the support the electronic Bill of Lading project';
export const FOOTER_TEXT = `${new Date().getFullYear()} EBL `;
// PAGES TITLE
export const PAGE_TITLE_HOME = 'Home';
export const PAGE_TITLE_SETTINGS = 'Settings';
export const PAGE_TITLE_PREFERENCES = 'Preferences';
// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;
