import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { shipmentsApi } from '../api/shipmentsApi';
import { AddShipmentLocationsForm } from '../components/AddShipmentLocationsForm/AddShipmentLocationsForm';
import AddBimcoForm from '../components/BimcoForms/AddBimcoForm';
import BookingActions from '../components/Booking/BookingActions';
import { BookingRequestChecklist } from '../components/BookingChecklist';
import { CommoditiesManager } from '../components/CommoditiesManager/CommoditiesManager';
import { ShipmentBooking } from '../components/ShipmentBooking';
import { ShipmentDocs } from '../components/ShipmentDocs';
import { ShipmentStatus } from '../components/ShipmentStatus';
import TTSummary from '../components/TTSummary';
import { ShipmentRequest, ShipmentResponse } from '../generated';
import { getProcessStep, isBLIssued } from '../utils/bookingStatus';
import { getAuthHeader } from '../utils/getAuthHeader';
import { NotFound } from './NotFound';

async function fetchShipment(id: string): Promise<ShipmentResponse> {
  const authHeader = await getAuthHeader();

  return shipmentsApi.shipmentsIdGet({ id: id }, { headers: authHeader });
}

export const Shipment = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const [openBimcoForm, setOpenBimcoForm] = useState(false);
  const queryClient = useQueryClient();
  const location = useLocation();
  const id = location.pathname.replaceAll('/shipments/', '').trimStart();
  // const navigate = useNavigate();

  const steps = [
    'Draft Booking Request',
    'Submit Booking Request',
    'Confirm Booking Request',
    'Draft Bill of Lading (B/L)',
    'Confirm B/L',
    'Issued B/L',
  ];

  const { register, handleSubmit } = useForm<ShipmentRequest>();

  const {
    data: shipment,
    isError: shipmentError,
    isLoading: shipmentIsLoading,
  } = useQuery(['shipment', id], () => fetchShipment(id), {
    retry: false,
  });

  const setShipmentDetails = useMutation(
    async (shipmentData: ShipmentRequest) => {
      const authHeader = await getAuthHeader();

      return shipmentsApi.shipmentsIdPut(
        { id: id, shipmentRequest: shipmentData },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipments']);
        handleClose();
      },
    },
  );

  if (shipmentIsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (shipmentError) {
    return <NotFound />;
  }

  const onSubmit: SubmitHandler<ShipmentRequest> = (newShipmentData) => {
    const shipmentData = shipment as ShipmentRequest;
    shipmentData.name = newShipmentData.name;
    setShipmentDetails.mutate(shipmentData);
  };

  const isStepFailed = (index: number) => {
    return index == 100;
  };

  const getActiveStep = (eblStatus: string, bookingStatus: string) => {
    return getProcessStep(eblStatus, bookingStatus);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBimcoClickOpen = () => {
    setOpenBimcoForm(true);
  };

  return (
    <>
      <Breadcrumbs style={{ fontSize: 35 }} aria-label="breadcrumb">
        <Link underline="hover" component={RouterLink} color="inherit" to="/">
          Shipments{' '}
        </Link>
        <Link
          underline="hover"
          component={RouterLink}
          color="text.primary"
          to={`/shipments/${shipment?.id}`}
        >
          {shipment?.name}
          <IconButton onClick={handleClickOpen} color="primary">
            <EditIcon />
          </IconButton>
        </Link>
      </Breadcrumbs>

      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Shipment Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To edit a shipment please enter the shipment&apos;s name and submit the form.
            </DialogContentText>
            <TextField
              {...register('name', { required: true })}
              margin="dense"
              id="name"
              label="Name"
              placeholder={shipment?.name}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Box sx={{ width: '100%', marginBottom: '20px' }}>
        <Stepper
          activeStep={getActiveStep(
            shipment?.eblDocumentStatus || '',
            shipment?.booking?.status || '',
          )}
        >
          {steps.map((label, index) => {
            const labelProps: {
              optional?: React.ReactNode;
              error?: boolean;
            } = {};
            if (isStepFailed(index)) {
              labelProps.optional = (
                <Typography variant="caption" color="error">
                  Booking Request not confirmed
                </Typography>
              );
              labelProps.error = true;
            }

            return (
              <Step key={label}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>

      <Box marginBottom="20px">
        {shipment?.booking !== undefined && shipment?.booking.id !== '' ? (
          <>
            <BookingActions shipment={shipment} booking={shipment.booking} />
          </>
        ) : (
          <>
            <Typography sx={{ mt: 4 }} variant="h5" gutterBottom>
              Attach booking request
            </Typography>
            <Button variant="outlined" onClick={handleBimcoClickOpen} startIcon={<AddIcon />}>
              BIMCO format
            </Button>

            <AddBimcoForm
              shipment={shipment}
              isDialogOpen={openBimcoForm}
              handleCloseDialog={() => setOpenBimcoForm(false)}
            ></AddBimcoForm>
          </>
        )}
      </Box>

      {shipment?.docs && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ShipmentDocs docs={shipment.docs} shipmentId={shipment.id} />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ListItem>
            <ShipmentStatus
              bookingStatus={shipment?.booking?.status}
              eblStatus={shipment?.eblDocumentStatus}
            />
          </ListItem>

          {shipment?.eblDocumentStatus === 'ISSU' && <TTSummary shipment={shipment} />}
        </Grid>

        <Grid item xs={4}>
          {shipment?.booking !== undefined &&
            shipment?.booking.id !== '' &&
            !isBLIssued(shipment) && <BookingRequestChecklist booking={shipment.booking} />}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          {shipment?.booking !== undefined && shipment?.booking?.shipmentId !== '' ? (
            <ShipmentBooking booking={shipment.booking} />
          ) : (
            <div>
              <Typography sx={{ mt: 4 }} variant="h5" gutterBottom>
                Booking details
              </Typography>
              <p>(No booking attached yet)</p>
            </div>
          )}
        </Grid>

        {shipment?.booking !== undefined && shipment?.booking.id !== '' && (
          <CommoditiesManager booking={shipment.booking}></CommoditiesManager>
        )}

        {shipment?.booking !== undefined && shipment?.booking.id !== '' && (
          <AddShipmentLocationsForm booking={shipment.booking}></AddShipmentLocationsForm>
        )}
      </Grid>
    </>
  );
};
