/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Specifying which communication channel is to be used for this booking e.g.
 * - EI (EDI transmission)
 * - EM (Email)
 * - AO (API)
 * @export
 */
export const CommunicationChannelCode = {
    Ei: 'EI',
    Em: 'EM',
    Ao: 'AO'
} as const;
export type CommunicationChannelCode = typeof CommunicationChannelCode[keyof typeof CommunicationChannelCode];


export function CommunicationChannelCodeFromJSON(json: any): CommunicationChannelCode {
    return CommunicationChannelCodeFromJSONTyped(json, false);
}

export function CommunicationChannelCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationChannelCode {
    return json as CommunicationChannelCode;
}

export function CommunicationChannelCodeToJSON(value?: CommunicationChannelCode | null): any {
    return value as any;
}

