import React, { ReactElement } from 'react';
import {
  AccountBox as AccountBoxIcon,
  List as PreferencesIcon,
  Logout as LogoutIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

import { ActionItem } from './ActionItem';

interface ActionProps {
  total?: number;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disableTooltip?: boolean;
}

export const UserAccount = ({ onClick, disableTooltip = false }: ActionProps): ReactElement => (
  <ActionItem
    title="My Account"
    icon={AccountBoxIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
);

export const SignOut = ({ onClick, disableTooltip = false }: ActionProps): ReactElement => (
  <ActionItem
    title="Sign Out"
    icon={LogoutIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
);

export const Settings = ({ onClick, disableTooltip = false }: ActionProps): ReactElement => (
  <ActionItem
    title="Settings"
    icon={SettingsIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
);

export const Preferences = ({ onClick, disableTooltip = false }: ActionProps): ReactElement => (
  <ActionItem
    title="Preferences"
    icon={PreferencesIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
);
