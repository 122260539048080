import { useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { AppContext } from '../../Context';
import { useLocation, useNavigate } from 'react-router';
import { BookingData, BookingRequest, ResponseError, ShipmentResponse } from '../../generated';
import { bookingsApi } from '../../api/bookingsApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FieldValues, useFormContext } from 'react-hook-form';
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import {
  formatCargoMovementTypeAtDestination,
  formatCargoMovementTypeAtOrigin,
  formatCommunicationChannelCode,
  formatDeliveryTypeAtDestination,
  formatIncoTerms,
  formatModeOfTransport,
  formatPaymentTermCode,
  formatReceiptTypeAtOrigin,
} from '../../utils/bookingRequestHelpers';
import { formatBoolean } from '../../utils/formatBoolean';

export default function Confirm(shipment: ShipmentResponse) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { resetActiveStep, handleBack } = useContext(AppContext);
  const data = location.state as ShipmentResponse;
  const { getValues } = useFormContext();
  const formValues = getValues();

  const createBooking = useMutation(
    (booking: BookingRequest) => {
      booking.shipmentId = shipment.id;
      booking.eblType = 'DCSA';
      return bookingsApi.bookingRequestsPost({ bookingRequest: booking });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bookings']);
        toast.success('Created draft booking request');

        //reset step form
        resetActiveStep();

        navigate(`/shipments/${shipment.id}`);
      },
      onError: (error: ResponseError) => {
        queryClient.invalidateQueries(['bookings']);
        toast.error(`Error creating draft booking request: ${error.response.statusText}`);

        //reset step form
        resetActiveStep();

        navigate(`/shipments/${data.id}`);
      },
    },
  );

  const editBooking = useMutation(
    (booking: BookingRequest) => {
      let resetQuery = false;
      if (shipment.booking?.status !== 'DRFT' && shipment.booking?.status !== 'PENU') {
        // Handle this as a separate booking request to the carrier
        resetQuery = true;
      }
      booking.eblType = 'DCSA';
      return bookingsApi.bookingRequestsIdPut({
        id: shipment.booking?.id as string,
        bookingRequest: booking,
        reset: resetQuery, // TODO Implement logic for booking reset
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bookings']);
        resetActiveStep();
        toast.success('Edited booking request');
        navigate(`/shipments/${shipment.id}`);
      },
      onError: (error: ResponseError) => {
        queryClient.invalidateQueries(['bookings']);
        resetActiveStep();
        toast.error(`Error editing booking request: ${error.response.statusText}`);
        navigate(`/shipments/${shipment.id}`);
      },
    },
  );

  const handleSubmit = () => {
    const form: BookingData = getValues() as BookingData;
    form.shipmentId = data.id || (shipment.booking?.shipmentId as string);

    if (shipment.booking?.id) {
      editBooking.mutate(form);
    } else {
      createBooking.mutate(form);
    }
  };

  const renderFormValues = (formValues: FieldValues) => {
    const fields = [
      { label: 'Vessel name', value: formValues.vesselName },
      { label: 'Vessel IMO number', value: formValues.vesselIMONumber },
      { label: 'Incoterms', value: formatIncoTerms(formValues.incoTerms) },
      { label: 'Mode of transport', value: formatModeOfTransport(formValues.modeOfTransport) },
      { label: 'Payment term code', value: formatPaymentTermCode(formValues.modeOfTransport) },
      { label: 'Service contract reference', value: formValues.serviceContractReference },
      { label: 'Export declaration reference', value: formValues.exportDeclarationReference },
      { label: 'Import license reference', value: formValues.importLicenseReference },
      { label: 'Contract quotation reference', value: formValues.contractQuotationReference },
      { label: 'Carrier export voyage number', value: formValues.carrierExportVoyageNumber },
      { label: 'Expected departure date', value: formValues.expectedDepartureDate },
      {
        label: 'Expected arrival start date',
        value: formValues.expectedArrivalAtPlaceOfDeliveryStartDate,
      },
      {
        label: 'Expected arrival end date',
        value: formValues.expectedArrivalAtPlaceOfDeliveryEndDate,
      },
      {
        label: 'Receipt delivery type at origin',
        value: formatReceiptTypeAtOrigin(formValues.receiptTypeAtOrigin),
      },
      {
        label: 'Receipt delivery type at destination',
        value: formatDeliveryTypeAtDestination(formValues.deliveryTypeAtDestination),
      },
      {
        label: 'Cargo movement type at origin',
        value: formatCargoMovementTypeAtOrigin(formValues.cargoMovementTypeAtOrigin),
      },
      {
        label: 'Cargo movement type at destination',
        value: formatCargoMovementTypeAtDestination(formValues.cargoMovementTypeAtDestination),
      },
      {
        label: 'Cargo movement type at destination',
        value: formatCargoMovementTypeAtDestination(formValues.cargoMovementTypeAtDestination),
      },
      {
        label: 'Communication channel code',
        value: formatCommunicationChannelCode(formValues.communicationChannelCode),
      },
      {
        label: 'Is partial load allowed?',
        value: formatBoolean(formValues.isPartialLoadAllowed),
      },
      {
        label: 'Is export declaration required?',
        value: formatBoolean(formValues.isExportDeclarationRequired),
      },
      {
        label: 'Is import license required?',
        value: formatBoolean(formValues.isImportLicenseRequired),
      },
      {
        label: 'Is equipment substitution allowed??',
        value: formatBoolean(formValues.isEquipmentSubstitutionAllowed),
      },
    ];

    return (
      <>
        <List disablePadding>
          {fields.map(
            ({ label, value }, index) =>
              value && (
                <>
                  <ListItem key={label}>
                    <ListItemText primary={label} secondary={value} />
                  </ListItem>

                  {index !== fields.length - 1 && <Divider />}
                </>
              ),
          )}
        </List>
      </>
    );
  };

  return (
    <>
      {renderFormValues(formValues)}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Button sx={{ mr: 1 }} onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" color="success" onClick={handleSubmit}>
          Confirm & Continue
        </Button>
      </Box>
    </>
  );
}
