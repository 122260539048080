import React, { ReactElement, useEffect, useState } from 'react';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { Hamburger } from './Hamburger';
import { UserAccount } from '../Actions';
import { DefaultMenu, MobileMenu } from './Menu';
import { useConnectWallet, useWallets } from '@web3-onboard/react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

interface HeaderProps {
  toggleNavigation: () => void;
}

export const Header = ({ toggleNavigation }: HeaderProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const connectedWallets = useWallets();

  useEffect(() => {
    if (!connectedWallets.length) return;
    const connectedWalletsLabelArray = connectedWallets.map(({ label }) => label);
    window.localStorage.setItem('connectedWallets', JSON.stringify(connectedWalletsLabelArray));
  }, [wallet, connectedWallets]);

  useEffect(() => {
    if (!connect || connectedWallets?.length > 0) return;
    const previousConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets') || '[]',
    ) as string[];

    if (previousConnectedWallets?.length) {
      void (async () => {
        await connect({
          autoSelect: {
            label: previousConnectedWallets[0],
            disableModals: true,
          },
        });
      })();
    }
    // eslint-disable-next-line
  }, [connect]);

  useEffect(() => {
    if (wallet == null) {
      window.localStorage.removeItem('connectedWallets');
    }
  }, [wallet]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setMobileMoreAnchorEl(event.currentTarget);

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleWallet = () => {
    if (wallet) {
      disconnect(wallet);
      window.localStorage.removeItem('connectedWallets');
    } else {
      connect().then((r) => {
        console.log('Connected wallet ' + r[0].accounts[0]);
      });
    }
  };

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar disableGutters variant="dense">
          <Hamburger toggleNavigation={toggleNavigation} />
          <Box
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            Electronic Bill of Lading
          </Box>
          <Box sx={{ flexGrow: 1 }} />

          <Box>
            <Button
              variant="contained"
              startIcon={<AccountBalanceWalletIcon />}
              onClick={handleWallet}
            >
              {connecting ? 'Connecting wallet' : wallet ? 'Disconnect wallet' : 'Connect wallet'}
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
            <UserAccount onClick={handleProfileMenuOpen} />
          </Box>
        </Toolbar>
      </AppBar>
      <MobileMenu
        isMenuOpen={Boolean(mobileMoreAnchorEl)}
        handleMenuOpen={handleMobileMenuOpen}
        handleMenuClose={handleMobileMenuClose}
        anchorEl={mobileMoreAnchorEl}
      />
      <DefaultMenu
        isMenuOpen={Boolean(anchorEl)}
        handleMenuClose={handleMenuClose}
        anchorEl={anchorEl}
      />
    </>
  );
};
