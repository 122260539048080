/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CargoGrossVolumeUnit } from './CargoGrossVolumeUnit';
import {
    CargoGrossVolumeUnitFromJSON,
    CargoGrossVolumeUnitFromJSONTyped,
    CargoGrossVolumeUnitToJSON,
} from './CargoGrossVolumeUnit';
import type { CargoGrossWeightUnit } from './CargoGrossWeightUnit';
import {
    CargoGrossWeightUnitFromJSON,
    CargoGrossWeightUnitFromJSONTyped,
    CargoGrossWeightUnitToJSON,
} from './CargoGrossWeightUnit';

/**
 * 
 * @export
 * @interface CommodityRequest
 */
export interface CommodityRequest {
    /**
     * Short description of goods to be shipped which allow the carrier to confirm acceptance and commercial terms.
     * @type {string}
     * @memberof CommodityRequest
     */
    commodityName: string;
    /**
     * High-level description of goods to be shipped which allow the carrier to confirm acceptance and commercial terms. To be replaced by "description of goods" upon submission of shipping instruction
     * @type {string}
     * @memberof CommodityRequest
     */
    commodityDescription: string;
    /**
     * Used by customs to classify the product being shipped.
     * @type {string}
     * @memberof CommodityRequest
     */
    hSCode?: string;
    /**
     * The grand total weight of the cargo and weight per container(s) including packaging items being carried, which can be expressed in imperial or metric terms, as provided by the shipper. Excludes the tare weight of the container(s).'
     * @type {number}
     * @memberof CommodityRequest
     */
    cargoGrossWeight: number;
    /**
     * 
     * @type {CargoGrossWeightUnit}
     * @memberof CommodityRequest
     */
    cargoGrossWeightUnit: CargoGrossWeightUnit;
    /**
     * The grand total volume of the commodity
     * @type {number}
     * @memberof CommodityRequest
     */
    cargoGrossVolume: number;
    /**
     * 
     * @type {CargoGrossVolumeUnit}
     * @memberof CommodityRequest
     */
    cargoGrossVolumeUnit: CargoGrossVolumeUnit;
    /**
     * Specifies the kind of packages associated with this cargo item
     * @type {string}
     * @memberof CommodityRequest
     */
    kindOfPackages?: string;
    /**
     * Specifies the number of packages associated with this cargo item
     * @type {number}
     * @memberof CommodityRequest
     */
    numberOfPackages?: number;
    /**
     * Issue date of the export license applicable to the booking. Mandatory to provide in booking request for specific commodities
     * @type {string}
     * @memberof CommodityRequest
     */
    exportLicenseIssueDate?: string;
    /**
     * Expiry date of the export license applicable to the booking.
     * 
     * Mandatory to provide in booking request for specific commodities.
     * @type {string}
     * @memberof CommodityRequest
     */
    exportLicenseExpiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CommodityRequest
     */
    bookingId?: string;
}

/**
 * Check if a given object implements the CommodityRequest interface.
 */
export function instanceOfCommodityRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commodityName" in value;
    isInstance = isInstance && "commodityDescription" in value;
    isInstance = isInstance && "cargoGrossWeight" in value;
    isInstance = isInstance && "cargoGrossWeightUnit" in value;
    isInstance = isInstance && "cargoGrossVolume" in value;
    isInstance = isInstance && "cargoGrossVolumeUnit" in value;

    return isInstance;
}

export function CommodityRequestFromJSON(json: any): CommodityRequest {
    return CommodityRequestFromJSONTyped(json, false);
}

export function CommodityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommodityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commodityName': json['commodityName'],
        'commodityDescription': json['commodityDescription'],
        'hSCode': !exists(json, 'HSCode') ? undefined : json['HSCode'],
        'cargoGrossWeight': json['cargoGrossWeight'],
        'cargoGrossWeightUnit': CargoGrossWeightUnitFromJSON(json['cargoGrossWeightUnit']),
        'cargoGrossVolume': json['cargoGrossVolume'],
        'cargoGrossVolumeUnit': CargoGrossVolumeUnitFromJSON(json['cargoGrossVolumeUnit']),
        'kindOfPackages': !exists(json, 'kindOfPackages') ? undefined : json['kindOfPackages'],
        'numberOfPackages': !exists(json, 'numberOfPackages') ? undefined : json['numberOfPackages'],
        'exportLicenseIssueDate': !exists(json, 'exportLicenseIssueDate') ? undefined : json['exportLicenseIssueDate'],
        'exportLicenseExpiryDate': !exists(json, 'exportLicenseExpiryDate') ? undefined : json['exportLicenseExpiryDate'],
        'bookingId': !exists(json, 'bookingId') ? undefined : json['bookingId'],
    };
}

export function CommodityRequestToJSON(value?: CommodityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commodityName': value.commodityName,
        'commodityDescription': value.commodityDescription,
        'HSCode': value.hSCode,
        'cargoGrossWeight': value.cargoGrossWeight,
        'cargoGrossWeightUnit': CargoGrossWeightUnitToJSON(value.cargoGrossWeightUnit),
        'cargoGrossVolume': value.cargoGrossVolume,
        'cargoGrossVolumeUnit': CargoGrossVolumeUnitToJSON(value.cargoGrossVolumeUnit),
        'kindOfPackages': value.kindOfPackages,
        'numberOfPackages': value.numberOfPackages,
        'exportLicenseIssueDate': value.exportLicenseIssueDate,
        'exportLicenseExpiryDate': value.exportLicenseExpiryDate,
        'bookingId': value.bookingId,
    };
}

