import React, { ComponentType, ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  Icon,
  IconButton,
  lighten,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { Route } from '../../../../types';

interface RouteItemProps {
  route: Route;
  nested?: boolean;
  hasChildren?: boolean;
  handleMenuClick?: (route: Route) => void;
}

export const RouteItem = ({
  route,
  nested = false,
  hasChildren = false,
  handleMenuClick = () => {},
}: RouteItemProps): ReactElement => {
  const location = useLocation();

  const handleNavigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!route.enabled || hasChildren) e.preventDefault();
  };

  const selected =
    location.pathname === route.path ||
    (hasChildren && route.subRoutes?.some((e) => location.pathname === e.path));

  const item = (
    <StyledListItemButton
      enabled={route.enabled.toString()}
      sx={{ pl: nested ? 3 : 1 }}
      onClick={() => handleMenuClick(route)}
    >
      <ListItemIcon>
        <StyledIconButton size="small" selected={location.pathname === route.path}>
          {route.icon && <StyledIcon component={route.icon} selected={selected || false} />}
        </StyledIconButton>
      </ListItemIcon>
      <ListItemText primary={route.title} />
      {hasChildren && (route.expanded ? <ExpandLess /> : <ExpandMore />)}
    </StyledListItemButton>
  );

  if (route.isHidden) {
    return <></>;
  }

  return (
    <StyledNavLink to={`${route.path}`} key={route.key} onClick={handleNavigate}>
      {route.description ? (
        <Tooltip
          title={`${route.description}${!route.enabled ? ' (Not Allowed)' : ''}`}
          placement="right"
        >
          {item}
        </Tooltip>
      ) : (
        item
      )}
    </StyledNavLink>
  );
};

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

const StyledListItemButton = styled(ListItemButton)<{ enabled: string }>(({ theme, enabled }) =>
  !enabled
    ? {
        '*': { cursor: 'not-allowed', color: theme.palette.text.secondary },
      }
    : {},
);

const StyledIconButton = styled(IconButton)<{ selected: boolean }>(({ selected, theme }) => ({
  boxShadow: selected ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

const StyledIcon = styled(Icon)<{
  selected: boolean;
  component: ComponentType;
}>`
  ${({ selected, theme }) => selected && `color: ${theme.palette.primary.main};`}
`;
