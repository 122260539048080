import * as React from 'react';
import { ReactElement } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { PageDefault } from './components/PageDefault';
import { routes } from './config';
import { Route as AppRoute } from './types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#223046',
    },
    info: {
      main: '#223046',
    },
  },
});

function App(): ReactElement {
  const addRoute = (route: AppRoute) => (
    <Route key={route.key} path={route.path} element={route.component || PageDefault} />
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Router>
          <Layout>
            <Routes>
              {routes.map((route: AppRoute) =>
                route.subRoutes
                  ? route.subRoutes.map((item: AppRoute) => addRoute(item))
                  : addRoute(route),
              )}
            </Routes>
            <div className="no-print">
              <ReactQueryDevtools initialIsOpen={false} />
            </div>
          </Layout>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
