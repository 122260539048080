import { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

export const NotFound = (): ReactElement => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h1">404</Typography>
        <Typography variant="h6">The page you are looking for does not exist</Typography>
      </Box>
    </>
  );
};
