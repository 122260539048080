/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShipmentLocationTypeCode } from './ShipmentLocationTypeCode';
import {
    ShipmentLocationTypeCodeFromJSON,
    ShipmentLocationTypeCodeFromJSONTyped,
    ShipmentLocationTypeCodeToJSON,
} from './ShipmentLocationTypeCode';
import type { UnLocationLocation } from './UnLocationLocation';
import {
    UnLocationLocationFromJSON,
    UnLocationLocationFromJSONTyped,
    UnLocationLocationToJSON,
} from './UnLocationLocation';

/**
 * 
 * @export
 * @interface ShipmentLocationResponse
 */
export interface ShipmentLocationResponse {
    /**
     * 
     * @type {UnLocationLocation}
     * @memberof ShipmentLocationResponse
     */
    location: UnLocationLocation;
    /**
     * 
     * @type {ShipmentLocationTypeCode}
     * @memberof ShipmentLocationResponse
     */
    shipmentLocationTypeCode: ShipmentLocationTypeCode;
    /**
     * A date when the event is taking place at the location
     * @type {Date}
     * @memberof ShipmentLocationResponse
     */
    eventDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ShipmentLocationResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentLocationResponse
     */
    creatorId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentLocationResponse
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentLocationResponse
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentLocationResponse
     */
    deletedAt?: Date;
}

/**
 * Check if a given object implements the ShipmentLocationResponse interface.
 */
export function instanceOfShipmentLocationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "shipmentLocationTypeCode" in value;

    return isInstance;
}

export function ShipmentLocationResponseFromJSON(json: any): ShipmentLocationResponse {
    return ShipmentLocationResponseFromJSONTyped(json, false);
}

export function ShipmentLocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentLocationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': UnLocationLocationFromJSON(json['location']),
        'shipmentLocationTypeCode': ShipmentLocationTypeCodeFromJSON(json['shipmentLocationTypeCode']),
        'eventDateTime': !exists(json, 'eventDateTime') ? undefined : (new Date(json['eventDateTime'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}

export function ShipmentLocationResponseToJSON(value?: ShipmentLocationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': UnLocationLocationToJSON(value.location),
        'shipmentLocationTypeCode': ShipmentLocationTypeCodeToJSON(value.shipmentLocationTypeCode),
        'eventDateTime': value.eventDateTime === undefined ? undefined : (value.eventDateTime.toISOString()),
        'id': value.id,
        'creatorId': value.creatorId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}

