/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CargoGrossVolumeUnit } from './CargoGrossVolumeUnit';
import {
    CargoGrossVolumeUnitFromJSON,
    CargoGrossVolumeUnitFromJSONTyped,
    CargoGrossVolumeUnitToJSON,
} from './CargoGrossVolumeUnit';
import type { CargoGrossWeightUnit } from './CargoGrossWeightUnit';
import {
    CargoGrossWeightUnitFromJSON,
    CargoGrossWeightUnitFromJSONTyped,
    CargoGrossWeightUnitToJSON,
} from './CargoGrossWeightUnit';

/**
 * 
 * @export
 * @interface CommodityResponse
 */
export interface CommodityResponse {
    /**
     * Short description of goods to be shipped which allow the carrier to confirm acceptance and commercial terms.
     * @type {string}
     * @memberof CommodityResponse
     */
    commodityName: string;
    /**
     * High-level description of goods to be shipped which allow the carrier to confirm acceptance and commercial terms. To be replaced by "description of goods" upon submission of shipping instruction
     * @type {string}
     * @memberof CommodityResponse
     */
    commodityDescription: string;
    /**
     * Used by customs to classify the product being shipped.
     * @type {string}
     * @memberof CommodityResponse
     */
    hSCode?: string;
    /**
     * The grand total weight of the cargo and weight per container(s) including packaging items being carried, which can be expressed in imperial or metric terms, as provided by the shipper. Excludes the tare weight of the container(s).'
     * @type {number}
     * @memberof CommodityResponse
     */
    cargoGrossWeight: number;
    /**
     * 
     * @type {CargoGrossWeightUnit}
     * @memberof CommodityResponse
     */
    cargoGrossWeightUnit: CargoGrossWeightUnit;
    /**
     * The grand total volume of the commodity
     * @type {number}
     * @memberof CommodityResponse
     */
    cargoGrossVolume: number;
    /**
     * 
     * @type {CargoGrossVolumeUnit}
     * @memberof CommodityResponse
     */
    cargoGrossVolumeUnit: CargoGrossVolumeUnit;
    /**
     * Specifies the kind of packages associated with this cargo item
     * @type {string}
     * @memberof CommodityResponse
     */
    kindOfPackages?: string;
    /**
     * Specifies the number of packages associated with this cargo item
     * @type {number}
     * @memberof CommodityResponse
     */
    numberOfPackages?: number;
    /**
     * Issue date of the export license applicable to the booking. Mandatory to provide in booking request for specific commodities
     * @type {string}
     * @memberof CommodityResponse
     */
    exportLicenseIssueDate?: string;
    /**
     * Expiry date of the export license applicable to the booking.
     * 
     * Mandatory to provide in booking request for specific commodities.
     * @type {string}
     * @memberof CommodityResponse
     */
    exportLicenseExpiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CommodityResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CommodityResponse
     */
    creatorId?: number;
    /**
     * 
     * @type {Date}
     * @memberof CommodityResponse
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CommodityResponse
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CommodityResponse
     */
    deletedAt?: Date;
}

/**
 * Check if a given object implements the CommodityResponse interface.
 */
export function instanceOfCommodityResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commodityName" in value;
    isInstance = isInstance && "commodityDescription" in value;
    isInstance = isInstance && "cargoGrossWeight" in value;
    isInstance = isInstance && "cargoGrossWeightUnit" in value;
    isInstance = isInstance && "cargoGrossVolume" in value;
    isInstance = isInstance && "cargoGrossVolumeUnit" in value;

    return isInstance;
}

export function CommodityResponseFromJSON(json: any): CommodityResponse {
    return CommodityResponseFromJSONTyped(json, false);
}

export function CommodityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommodityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commodityName': json['commodityName'],
        'commodityDescription': json['commodityDescription'],
        'hSCode': !exists(json, 'HSCode') ? undefined : json['HSCode'],
        'cargoGrossWeight': json['cargoGrossWeight'],
        'cargoGrossWeightUnit': CargoGrossWeightUnitFromJSON(json['cargoGrossWeightUnit']),
        'cargoGrossVolume': json['cargoGrossVolume'],
        'cargoGrossVolumeUnit': CargoGrossVolumeUnitFromJSON(json['cargoGrossVolumeUnit']),
        'kindOfPackages': !exists(json, 'kindOfPackages') ? undefined : json['kindOfPackages'],
        'numberOfPackages': !exists(json, 'numberOfPackages') ? undefined : json['numberOfPackages'],
        'exportLicenseIssueDate': !exists(json, 'exportLicenseIssueDate') ? undefined : json['exportLicenseIssueDate'],
        'exportLicenseExpiryDate': !exists(json, 'exportLicenseExpiryDate') ? undefined : json['exportLicenseExpiryDate'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}

export function CommodityResponseToJSON(value?: CommodityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commodityName': value.commodityName,
        'commodityDescription': value.commodityDescription,
        'HSCode': value.hSCode,
        'cargoGrossWeight': value.cargoGrossWeight,
        'cargoGrossWeightUnit': CargoGrossWeightUnitToJSON(value.cargoGrossWeightUnit),
        'cargoGrossVolume': value.cargoGrossVolume,
        'cargoGrossVolumeUnit': CargoGrossVolumeUnitToJSON(value.cargoGrossVolumeUnit),
        'kindOfPackages': value.kindOfPackages,
        'numberOfPackages': value.numberOfPackages,
        'exportLicenseIssueDate': value.exportLicenseIssueDate,
        'exportLicenseExpiryDate': value.exportLicenseExpiryDate,
        'id': value.id,
        'creatorId': value.creatorId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}

