/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ShipmentLocationRequest,
  ShipmentLocationResponse,
} from '../models';
import {
    ShipmentLocationRequestFromJSON,
    ShipmentLocationRequestToJSON,
    ShipmentLocationResponseFromJSON,
    ShipmentLocationResponseToJSON,
} from '../models';

export interface ShipmentLocationsIdDeleteRequest {
    id: string;
}

export interface ShipmentLocationsIdGetRequest {
    id: string;
}

export interface ShipmentLocationsIdPutRequest {
    id: string;
    shipmentLocationRequest?: ShipmentLocationRequest;
}

export interface ShipmentLocationsPostRequest {
    shipmentLocationRequest?: ShipmentLocationRequest;
}

/**
 * 
 */
export class ShipmentLocationApi extends runtime.BaseAPI {

    /**
     * Delete a ShipmentLocation from the database
     */
    async shipmentLocationsIdDeleteRaw(requestParameters: ShipmentLocationsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling shipmentLocationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shipment-locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a ShipmentLocation from the database
     */
    async shipmentLocationsIdDelete(requestParameters: ShipmentLocationsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shipmentLocationsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get ShipmentLocation by id 
     */
    async shipmentLocationsIdGetRaw(requestParameters: ShipmentLocationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShipmentLocationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling shipmentLocationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shipment-locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentLocationResponseFromJSON(jsonValue));
    }

    /**
     * Get ShipmentLocation by id 
     */
    async shipmentLocationsIdGet(requestParameters: ShipmentLocationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShipmentLocationResponse> {
        const response = await this.shipmentLocationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specified ShipmentLocation in the database
     */
    async shipmentLocationsIdPutRaw(requestParameters: ShipmentLocationsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling shipmentLocationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/shipment-locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ShipmentLocationRequestToJSON(requestParameters.shipmentLocationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the specified ShipmentLocation in the database
     */
    async shipmentLocationsIdPut(requestParameters: ShipmentLocationsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shipmentLocationsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new shipment location to the database
     */
    async shipmentLocationsPostRaw(requestParameters: ShipmentLocationsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/shipment-locations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShipmentLocationRequestToJSON(requestParameters.shipmentLocationRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a new shipment location to the database
     */
    async shipmentLocationsPost(requestParameters: ShipmentLocationsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.shipmentLocationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
