import { useQuery } from '@tanstack/react-query';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { vaultApi } from '../../api/vaultApi';

import {
  OpenAttestationDocument as OpenAttestationDocumentV2,
  TemplateType,
} from '@govtechsg/open-attestation/dist/types/__generated__/schema.2.0';
import {
  FrameActions,
  FrameConnector,
  HostActions,
  renderDocument,
} from '@govtechsg/decentralized-renderer-react-components';
import { getTemplateUrl, WrappedOrSignedOpenAttestationDocument } from '../../utils/shared';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import {
  getData,
  OpenAttestationDocument,
  wrapDocument,
  WrappedDocument,
} from '@govtechsg/open-attestation';
import { getTokenRegistryAddress } from '../../tradeTrust/tokenRegistry';
import { getAuthHeader } from '../../utils/getAuthHeader';

const DEFAULT_RENDERER_URL = `https://generic-templates.tradetrust.io`;

export interface TemplateProps {
  id: string;
  label: string;
  type: string;
}

export type Dispatch = (action: HostActions) => void;

const SCROLLBAR_WIDTH = 100; // giving scrollbar a default width as there are no perfect ways to get it

export const DecentralisedRenderer = ({
  vaultId,
  isWrapped,
  templateType,
}: {
  vaultId: string;
  isWrapped: boolean;
  templateType: string;
}): ReactElement => {
  const [wrappedDocument, setWrappedDocument] = useState<WrappedOrSignedOpenAttestationDocument>();
  const [document, setDocument] = useState<OpenAttestationDocument>();

  const {
    isLoading,
    error,
    data: doc,
  } = useQuery(
    ['vaultdocument', vaultId],
    async () => {
      const authHeader = await getAuthHeader();
      const responseData = await vaultApi.vaultGet({ id: vaultId }, { headers: authHeader });
      return responseData;
    },
    {
      enabled: vaultId !== '',
      retry: false,
    },
  );

  const toFrame = useRef<Dispatch>();
  const [height, setHeight] = useState(250);
  const [isTimeout, setIsTimeout] = useState(false);
  const [source, setSource] = useState<string>();

  const onConnected = useCallback(
    (frame) => {
      toFrame.current = frame;
      if (toFrame.current && document) {
        toFrame.current(renderDocument({ document, rawDocument: wrappedDocument }));
      }
    },
    [document, wrappedDocument],
  );

  const dispatch = (action: FrameActions): void => {
    if (action.type === 'UPDATE_HEIGHT') {
      // adding SCROLLBAR_WIDTH in case the frame content overflow horizontally, which will cause scrollbars to appear
      setHeight(action.payload + SCROLLBAR_WIDTH);
    }
    if (action.type === 'TIMEOUT') {
      setIsTimeout(true);
    }
  };

  // on document loaded from vault
  useEffect(() => {
    if (!isLoading && doc != undefined) {
      if (doc.contentType !== 'application/json') {
        console.error('Document is not in JSON format');
      } else {
        try {
          const decodedString = window.atob(doc.file);
          const byteArray = new Uint8Array(decodedString.length);

          for (let i = 0; i < decodedString.length; ++i) {
            byteArray[i] = decodedString.charCodeAt(i);
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let w: WrappedDocument<any>;

          if (!isWrapped) {
            const oaDocument = JSON.parse(decodedString);
            // setting document data to wrap
            const newDocumentData = {
              $template: {
                type: 'EMBEDDED_RENDERER' as TemplateType,
                name: templateType,
                url: 'https://decentralized-renderer.ebl.dev/',
              },
              issuers: [
                {
                  name: 'eBL test',
                  // Token registry deployed on Sepolia test network
                  tokenRegistry: getTokenRegistryAddress(parseInt(window.ethereum.networkVersion)),
                  identityProof: {
                    type: 'DNS-TXT',
                    location: 'ebl.dev',
                  },
                },
              ],
              documentUrl: 'localhost:3001/api/v2/transport-documents/',
              id: '',
              oaDocument: oaDocument,
            } as OpenAttestationDocumentV2;
            w = wrapDocument(newDocumentData);
          } else {
            // eslint-disable-next-line
            w = JSON.parse(new TextDecoder('utf-8').decode(byteArray));
          }

          setWrappedDocument(w);
          // Set the template source url to select the correct renderer
          const newSource = getTemplateUrl(w) ?? DEFAULT_RENDERER_URL;
          setSource(newSource);
        } catch (e) {
          console.error('Document is not in JSON format');
        }
      }
    }
  }, [doc, isLoading, isWrapped, templateType]);

  // on wrapped document parsed
  useEffect(() => {
    if (wrappedDocument != undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setDocument(getData(wrappedDocument as WrappedDocument<any>));
    }
  }, [wrappedDocument]);

  // render document onload
  useEffect(() => {
    if (toFrame.current && document) {
      toFrame.current(renderDocument({ document, rawDocument: wrappedDocument }));
    }
  }, [document, toFrame, wrappedDocument]);

  return (
    <>
      <div className={`${isTimeout ? 'container' : ''}`}>
        {(error == undefined && source && (
          <FrameConnector
            style={{ height: `${height}px`, width: '100%', border: '0px', padding: '20px' }}
            source={source}
            dispatch={dispatch}
            onConnected={onConnected}
          />
        )) ||
          (error == undefined && (isLoading || source == undefined) && (
            <div className="text-center">
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            </div>
          )) || (
            <div className="text-center">
              <h3>Document is not available</h3>
            </div>
          )}
      </div>
    </>
  );
};
