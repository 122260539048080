/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PartyDataPartyContactDetailsInner
 */
export interface PartyDataPartyContactDetailsInner {
    /**
     * 
     * @type {string}
     * @memberof PartyDataPartyContactDetailsInner
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyDataPartyContactDetailsInner
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyDataPartyContactDetailsInner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyDataPartyContactDetailsInner
     */
    url?: string;
    /**
     * 
     * @type {number}
     * @memberof PartyDataPartyContactDetailsInner
     */
    id?: number;
}

/**
 * Check if a given object implements the PartyDataPartyContactDetailsInner interface.
 */
export function instanceOfPartyDataPartyContactDetailsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartyDataPartyContactDetailsInnerFromJSON(json: any): PartyDataPartyContactDetailsInner {
    return PartyDataPartyContactDetailsInnerFromJSONTyped(json, false);
}

export function PartyDataPartyContactDetailsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartyDataPartyContactDetailsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function PartyDataPartyContactDetailsInnerToJSON(value?: PartyDataPartyContactDetailsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'phone': value.phone,
        'email': value.email,
        'url': value.url,
        'id': value.id,
    };
}

