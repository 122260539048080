import { ReactElement, useEffect } from 'react';
import { partiesApi } from '../../api/partiesApi';
import { PartyResponse } from '../../generated';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const AddressBookSelect = ({ index }: { index: number }): ReactElement => {
  const { data, isLoading, isError } = useQuery(['parties'], fetchMyParties);
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue('id', '');
  });

  if (isLoading) {
    return <>Loading Addresses..</>;
  } else if (isError) {
    return (
      <>
        <b>Error Loading Addresses</b>
      </>
    );
  } else {
    const handleChange = (event: SelectChangeEvent) => {
      const party = data.find((party) => {
        return event.target.value == party.id;
      });
      if (index == -1) {
        setValue('.party', party);
      } else {
        setValue(`${index}.party`, party);
      }
    };

    const MakeItem = function (X: PartyResponse) {
      return (
        <MenuItem key={X.id} value={X.id}>
          {X.partyName}
        </MenuItem>
      );
    };

    return (
      <>
        <Select {...register('id')} fullWidth variant="standard" onChange={handleChange}>
          {data.map(MakeItem)}
        </Select>
      </>
    );
  }
};
async function fetchMyParties(): Promise<Array<PartyResponse>> {
  const authHeader = await getAuthHeader();
  return partiesApi.partiesGet({ headers: authHeader });
}
