import { ApolloClient, InMemoryCache } from '@apollo/client';
import * as _ from 'lodash';

const subgraphMumbai = process.env.REACT_APP_SUBGRAPH_URL_MUMBAI;
const subgraphSepolia = process.env.REACT_APP_SUBGRAPH_URL_SEPOLIA;

// See https://github.com/Open-Attestation/token-registry-subgraph/ for explanation
export function getTokenHistoryQuery(documentId: string): string {
  return `
    {
      tokens(
        where: { documentId: "${documentId}" }
      ) {
        issuances(first: 1) {
            initiator {
                id
            }
        }
        id
        tokenSnapshots (orderBy: timestamp) {
          id
          timestamp
          action
          beneficiary {
            id
          }
          holder {
            id
          }
          nominee {
            id
          }
          surrendered
          accepted 
        }
        titleEscrow {
          id
        }
      }
    }
  `;
}

export function getGraphClient(networkId: number) {
  // Polygon mumbai network set as default
  let subgraphUrl = subgraphMumbai;
  if (networkId === 11155111) {
    subgraphUrl = subgraphSepolia;
  }
  return new ApolloClient({
    uri: subgraphUrl,
    cache: new InMemoryCache(),
  });
}

// Obtain token history data by extracting snapshots
// eslint-disable-next-line
export function parseTokenHistoryData(data: any): Array<Snapshot> {
  const tokenSnapshots = _.get(data, 'data.tokens.0.tokenSnapshots');
  const snapshots: Array<Snapshot> = [];
  _.forEach(tokenSnapshots, (s) => {
    const id = s.id;
    const idParts = id.split("-");
    // s.txHash = idParts[0];
    snapshots.push({...s, txHash: idParts[0]});
  });

  return snapshots;
}

// eslint-disable-next-line
export function parseInitiator(data: any): string {
  return _.get(data, 'data.tokens.0.issuances.0.initiator.id');
}

// Title escrow id
// eslint-disable-next-line
export function parseTitleEscrowAddress(data: any): string {
  return _.get(data, 'data.tokens.0.titleEscrow.id');
}

// All the action types that can happen according to the token-registry subgraph
// (see https://github.com/Open-Attestation/token-registry-subgraph)
// enum TokenActionType {
//   Unknown
//   Issuance
//   Restoration
//   Acceptance
//   Surrender
//   Nomination
//   NominationRevocation
//   BeneficiaryTransfer
//   HolderTransfer
// }

export type Snapshot = {
  txHash: string;
  timestamp: string;
  action: string;
  surrendered: boolean;
  accepted: boolean;
  beneficiary: {
    id: string;
  };
  holder: {
    id: string;
  };
  nominee?: {
    id: string;
  };
};
