import React, { createContext, useReducer } from 'react';

type ContextProps = {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  resetActiveStep: () => void;
};

export const AppContext = createContext<ContextProps>({
  activeStep: 0,
  handleNext() {},
  handleBack() {},
  resetActiveStep() {},
});

interface ProviderProps {
  children: React.ReactNode;
}

type State = {
  activeStep: number;
};

type Action = { type: 'increase' } | { type: 'decrease' } | { type: 'resetActiveStep' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'increase':
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    case 'decrease':
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    case 'resetActiveStep':
      return {
        ...state,
        activeStep: 0,
      };

    default:
      return state;
  }
}

export function StepsProvider({ children }: ProviderProps) {
  const [{ activeStep }, dispatch] = useReducer(reducer, {
    activeStep: 0,
  });

  // Proceed to next step
  const handleNext = () => dispatch({ type: 'increase' });
  // Go back to prev step
  const handleBack = () => dispatch({ type: 'decrease' });
  // Reset activeStep to 0
  const resetActiveStep = () => dispatch({ type: 'resetActiveStep' });

  return (
    <AppContext.Provider
      value={{
        activeStep,
        handleNext,
        resetActiveStep,
        handleBack,
      }}
    >
      <div className="mui-step-form">{children}</div>
    </AppContext.Provider>
  );
}
