/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartyData1 } from './PartyData1';
import {
    PartyData1FromJSON,
    PartyData1FromJSONTyped,
    PartyData1ToJSON,
} from './PartyData1';
import type { PartyFunction } from './PartyFunction';
import {
    PartyFunctionFromJSON,
    PartyFunctionFromJSONTyped,
    PartyFunctionToJSON,
} from './PartyFunction';

/**
 * stores a party involved in the transport document.
 * @export
 * @interface DocumentParty1
 */
export interface DocumentParty1 {
    /**
     * 
     * @type {PartyData1}
     * @memberof DocumentParty1
     */
    party: PartyData1;
    /**
     * 
     * @type {PartyFunction}
     * @memberof DocumentParty1
     */
    partyFunction: PartyFunction;
    /**
     * If switch to paper is needed then the `displayedAddress` object MUST be provided. 
     * In case it is missing it is not possible to switch the B/L to paper later in the process.
     * @type {Array<string>}
     * @memberof DocumentParty1
     */
    displayedAddress?: Array<string>;
    /**
     * Used to decide whether the party will be notified of the arrival of the cargo.
     * @type {boolean}
     * @memberof DocumentParty1
     */
    isToBeNotified?: boolean;
}

/**
 * Check if a given object implements the DocumentParty1 interface.
 */
export function instanceOfDocumentParty1(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "party" in value;
    isInstance = isInstance && "partyFunction" in value;

    return isInstance;
}

export function DocumentParty1FromJSON(json: any): DocumentParty1 {
    return DocumentParty1FromJSONTyped(json, false);
}

export function DocumentParty1FromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentParty1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'party': PartyData1FromJSON(json['party']),
        'partyFunction': PartyFunctionFromJSON(json['partyFunction']),
        'displayedAddress': !exists(json, 'displayedAddress') ? undefined : json['displayedAddress'],
        'isToBeNotified': !exists(json, 'isToBeNotified') ? undefined : json['isToBeNotified'],
    };
}

export function DocumentParty1ToJSON(value?: DocumentParty1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'party': PartyData1ToJSON(value.party),
        'partyFunction': PartyFunctionToJSON(value.partyFunction),
        'displayedAddress': value.displayedAddress,
        'isToBeNotified': value.isToBeNotified,
    };
}

