/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DCSAResponsibleAgencyCode } from './DCSAResponsibleAgencyCode';
import {
    DCSAResponsibleAgencyCodeFromJSON,
    DCSAResponsibleAgencyCodeFromJSONTyped,
    DCSAResponsibleAgencyCodeToJSON,
} from './DCSAResponsibleAgencyCode';

/**
 * 
 * @export
 * @interface IdentifyingCode
 */
export interface IdentifyingCode {
    /**
     * 
     * @type {DCSAResponsibleAgencyCode}
     * @memberof IdentifyingCode
     */
    dCSAResponsibleAgencyCode?: DCSAResponsibleAgencyCode;
    /**
     * Code to identify the party as provided by the agency
     * @type {string}
     * @memberof IdentifyingCode
     */
    partyCode?: string;
    /**
     * The name of the list, provided by the responsible agency
     * @type {string}
     * @memberof IdentifyingCode
     */
    codeListName?: string;
    /**
     * 
     * @type {number}
     * @memberof IdentifyingCode
     */
    id?: number;
}

/**
 * Check if a given object implements the IdentifyingCode interface.
 */
export function instanceOfIdentifyingCode(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IdentifyingCodeFromJSON(json: any): IdentifyingCode {
    return IdentifyingCodeFromJSONTyped(json, false);
}

export function IdentifyingCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentifyingCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dCSAResponsibleAgencyCode': !exists(json, 'DCSAResponsibleAgencyCode') ? undefined : DCSAResponsibleAgencyCodeFromJSON(json['DCSAResponsibleAgencyCode']),
        'partyCode': !exists(json, 'partyCode') ? undefined : json['partyCode'],
        'codeListName': !exists(json, 'CodeListName') ? undefined : json['CodeListName'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function IdentifyingCodeToJSON(value?: IdentifyingCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DCSAResponsibleAgencyCode': DCSAResponsibleAgencyCodeToJSON(value.dCSAResponsibleAgencyCode),
        'partyCode': value.partyCode,
        'CodeListName': value.codeListName,
        'id': value.id,
    };
}

