/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserSummary } from './UserSummary';
import {
    UserSummaryFromJSON,
    UserSummaryFromJSONTyped,
    UserSummaryToJSON,
} from './UserSummary';

/**
 * 
 * @export
 * @interface DocMetadata
 */
export interface DocMetadata {
    /**
     * 
     * @type {number}
     * @memberof DocMetadata
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DocMetadata
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof DocMetadata
     */
    docType: string;
    /**
     * 
     * @type {string}
     * @memberof DocMetadata
     */
    vaultId: string;
    /**
     * 
     * @type {string}
     * @memberof DocMetadata
     */
    status: string;
    /**
     * 
     * @type {UserSummary}
     * @memberof DocMetadata
     */
    creator: UserSummary;
    /**
     * 
     * @type {Date}
     * @memberof DocMetadata
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DocMetadata
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the DocMetadata interface.
 */
export function instanceOfDocMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "fileName" in value;
    isInstance = isInstance && "docType" in value;
    isInstance = isInstance && "vaultId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function DocMetadataFromJSON(json: any): DocMetadata {
    return DocMetadataFromJSONTyped(json, false);
}

export function DocMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fileName': json['fileName'],
        'docType': json['docType'],
        'vaultId': json['vaultId'],
        'status': json['status'],
        'creator': UserSummaryFromJSON(json['creator']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function DocMetadataToJSON(value?: DocMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileName': value.fileName,
        'docType': value.docType,
        'vaultId': value.vaultId,
        'status': value.status,
        'creator': UserSummaryToJSON(value.creator),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

