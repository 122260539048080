/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocMetadata,
} from '../models';
import {
    DocMetadataFromJSON,
    DocMetadataToJSON,
} from '../models';

export interface DocsIdDeleteRequest {
    id: string;
}

/**
 * 
 */
export class DocsApi extends runtime.BaseAPI {

    /**
     * Get all documents that the user has view access for. This only returns metadata for documents that are stored in vault and database.  If you want to obtain the actual body of the documents, you will need to do an extra call to  GET /api/vault/{id}, with the vaultId as input for this document. 
     */
    async docsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocMetadata>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/docs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocMetadataFromJSON));
    }

    /**
     * Get all documents that the user has view access for. This only returns metadata for documents that are stored in vault and database.  If you want to obtain the actual body of the documents, you will need to do an extra call to  GET /api/vault/{id}, with the vaultId as input for this document. 
     */
    async docsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocMetadata>> {
        const response = await this.docsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete a single document from the vault and its corresponding metadata
     */
    async docsIdDeleteRaw(requestParameters: DocsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling docsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/docs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single document from the vault and its corresponding metadata
     */
    async docsIdDelete(requestParameters: DocsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.docsIdDeleteRaw(requestParameters, initOverrides);
    }

}
