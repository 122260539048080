import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FirstStep from './FirstStep';
import Confirm from './Confirm';
import { AppContext } from '../../Context';
import SecondStep from './SecondStep';
import { ShipmentResponse } from '../../generated';

// Step titles
const labels = ['Required booking details', 'Optional booking details', 'Confirmation'];
const handleSteps = (step: number, shipment: ShipmentResponse) => {
  switch (step) {
    case 0:
      return <FirstStep {...shipment} />;
    case 1:
      return <SecondStep {...shipment} />;
    case 2:
      return <Confirm {...shipment} />;
    default:
      throw new Error('Unknown step');
  }
};

const StepForm = (shipment: ShipmentResponse) => {
  const { activeStep } = useContext(AppContext);

  let action;
  if (window.location.href.indexOf('attach-booking-request') > -1) {
    action = 'Attach';
  }
  if (window.location.href.indexOf('edit-booking-request') > -1) {
    action = 'Edit';
  }

  return (
    <>
      <>
        <Box sx={{ my: 5 }}>
          <Typography variant="h4" align="center">
            {action} booking request
          </Typography>
        </Box>
        <Stepper activeStep={activeStep} sx={{ py: 3 }} alternativeLabel>
          {labels.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {handleSteps(activeStep, shipment)}
      </>
    </>
  );
};

export default StepForm;
