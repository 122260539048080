import { ReactElement } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { retrieveFile } from '../utils/files';
import { DocMetadata } from '../generated';
import { docsApi } from '../api/docsApi';
import { getAuthHeader } from '../utils/getAuthHeader';

export const MyDocs = (): ReactElement => {
  const queryClient = useQueryClient();
  const docsQuery = useQuery(['docs'], fetchMyDocs);

  const deleteDoc = useMutation(
    async (vaultId: string) => {
      const authHeader = await getAuthHeader();
      return docsApi.docsIdDelete({ id: vaultId }, { headers: authHeader });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['docs']);
      },
    },
  );

  return (
    <TableContainer>
      <Table sx={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>File name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Download</TableCell>
            <TableCell align="right">Delete</TableCell>
          </TableRow>
        </TableHead>
        {docsQuery.data && (
          <TableBody>
            {docsQuery.data.map((doc: DocMetadata) => (
              <TableRow key={doc.vaultId}>
                <TableCell>{doc.fileName}</TableCell>
                <TableCell>{doc.docType}</TableCell>
                <TableCell>{doc.status}</TableCell>
                <TableCell align="right">
                  <Button variant="contained" onClick={() => retrieveFile(doc.vaultId.toString())}>
                    Download
                  </Button>
                </TableCell>
                <TableCell align="right" onClick={() => deleteDoc.mutate(doc.id.toString())}>
                  <Button variant="contained" color="error">
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

async function fetchMyDocs(): Promise<Array<DocMetadata>> {
  const authHeader = await getAuthHeader();
  return docsApi.docsGet({ headers: authHeader });
}
