import React, { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { QueryClient } from '@tanstack/react-query';
import { Item, titleEscrowTransfer } from './TitleTransfer';
import { useConnectWallet } from '@web3-onboard/react';
import { PartyResponse } from '../../generated';
import { Logger } from 'ethers/lib/utils';
import { TitleEscrow } from '@govtechsg/token-registry/dist/contracts';
import { showShortWalletAddr } from '../../utils/shortWalletAddress';
import { ethers } from 'ethers';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
  connectedEscrow: TitleEscrow;
  queryClient: QueryClient;
  refreshTitleEscrowData: () => void;
  parties: Array<PartyResponse>;
}

export function ManageNominee(props: Props): ReactElement {
  const [nominee, setNominee] = useState<string>();
  const [partyName, setPartyName] = useState<string>();
  const { register, handleSubmit, reset } = useForm<titleEscrowTransfer>();
  const [{ wallet }] = useConnectWallet();
  const [beneficiary, setBeneficiary] = useState<string>();
  const walletAddr = wallet?.accounts?.[0].address;
  const [useCustomAddress, setUseCustomAddress] = useState(false); // Track whether to use custom address
  const [selectedAddress, setSelectedAddress] = useState<string>(''); // Track the selected address
  const theme = useTheme();

  useEffect(() => {
    const fetchBeneficiaryAndNominee = async () => {
      try {
        const b = await props.connectedEscrow.beneficiary();
        setBeneficiary(b);

        if (nominee === undefined) {
          const nominee = await props.connectedEscrow.nominee();
          setNominee(nominee);

          if (nominee === ethers.constants.AddressZero) {
            setNominee('None');
          } else {
            const party = props.parties.find(
              (p) => p.publicKey?.toLowerCase() === nominee.toLowerCase(),
            );
            if (party !== undefined) {
              setPartyName(party.partyName);
            }
          }
        }
      } catch (error) {
        setNominee('(None)');
      }
    };

    fetchBeneficiaryAndNominee();
  }, [nominee, props.connectedEscrow, props.parties]);

  const performTransaction: SubmitHandler<titleEscrowTransfer> = async (data) => {
    try {
      const nominatedAddress = data.address;

      const r = await props.connectedEscrow.nominate(nominatedAddress);
      setNominee('Waiting for confirmation...');
      await r.wait(1);
      toast.success(`Nominated owner ${showShortWalletAddr(nominatedAddress)} succeeded`);
      setNominee(nominatedAddress);
      props.refreshTitleEscrowData();
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.code === Logger.errors.UNPREDICTABLE_GAS_LIMIT) {
        toast.info(
          `Please switch the active account to ${showShortWalletAddr(walletAddr)} in MetaMask`,
        );
      } else {
        toast.error('Could not transfer holder, please check gas amount and permissions');
      }
    }
    reset();
  };

  const handleSwitchChange = () => {
    // Toggle the switch state and reset the selected address
    setUseCustomAddress(!useCustomAddress);
    setSelectedAddress(''); // Reset the selected address
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAddress(event.target.value); // Update the selected address
  };

  const isCurrentBeneficiary = walletAddr === beneficiary?.toLowerCase();

  const tooltipStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  };

  const iconButtonStyle = {
    color: theme.palette.primary.main,
  };

  return (
    <div>
      <Item>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Current nominee
        </Typography>
        <Typography variant="body1">{partyName}</Typography>
        <Typography variant="body2">{nominee}</Typography>
      </Item>

      {isCurrentBeneficiary && (
        <Item>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Nominate owner
          </Typography>
          <Typography variant="body2">
            Nominate your owner rights to another Ethereum address
          </Typography>
          <form onSubmit={handleSubmit(performTransaction)}>
            <div>
              <div
                style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}
              >
                <Tooltip
                  title="In the address book, enter a public key to display it in this dropdown."
                  arrow
                  style={tooltipStyle}
                >
                  <IconButton aria-label="info" style={iconButtonStyle}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <Typography>Select from address book</Typography>
                <Switch checked={useCustomAddress} onChange={handleSwitchChange} />
                <Typography>Use custom address</Typography>
              </div>
            </div>
            {useCustomAddress ? (
              <TextField
                {...register('address')}
                margin="dense"
                id="customAddress"
                label="Custom Ethereum address"
                placeholder="Custom Address (e.g. 0x4A...)"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={selectedAddress}
                onChange={handleAddressChange}
              />
            ) : (
              <TextField
                {...register('address')}
                margin="dense"
                select
                defaultValue=""
                id="nominee"
                label="Select Ethereum address"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={selectedAddress}
                onChange={handleAddressChange}
              >
                {props.parties
                  .filter((p) => p.publicKey?.toLowerCase() !== nominee?.toLowerCase())
                  .map((p) => (
                    <MenuItem key={p.publicKey} value={p.publicKey}>
                      {p.partyName}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            <Button type="submit" variant="contained" sx={{ my: 1 }}>
              Nominate
            </Button>
          </form>
        </Item>
      )}
    </div>
  );
}
