import React from "react";

export const ConditionsOfCarriage = () => {
  return (
    <div className="conditionsOfCarriage">
      <h4 style={{textAlign: "center"}}>Conditions of Carriage</h4>
      <ol>
        <li>
          <strong>Period of Responsibility Clause</strong>
          <p>
            The Carrier shall in no case be responsible for loss of damage to cargo arisen
            prior to
            loading and after discharging.
          </p>
        </li>
        <li>
          <strong>General Paramount Clause</strong>
          <p>
            The Hague Rules contained in the International Convention for the Unification of certain rules
            relating to
            Bills of Lading, dated Brussels the 25th August 1924 as enacted in the country of shipment shall
            apply to
            this contract. When no such enactment is in force in the country of shipment, the corresponding
            legislation
            of the country of destination shall apply, but in respect of shipments to which no such
            enactments are
            compulsorily applicable, the terms of the said Convention shall apply.
          </p>
          <p>
            <i>Trades where Hague-Visby Rules apply.</i> In trades where the International Brussels Convention 1924 as
            amended by the Protocol signed at
            Brussels on
            February 23rd 1968 - The Hague-Visby Rules - apply compulsory the provisions of the respective
            legislation
            shall be considered incorporated in this Bill of Lading. The Carrier takes all reservations
            possible under
            such applicable legislation, relating to the period before loading and after discharging and
            while the goods
            are in the charge of another Carrier, and to deck cargo and live animals.
          </p>
        </li>
      </ol>
    </div>
  )
}