import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BookingResponseAllOfCarrierMessages } from '../../generated';
import { formatBookingStatus } from '../../utils/bookingStatus';

export default function BookingCarrierUpdatesTable(props: props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>New booking status</TableCell>
            <TableCell>Optional message</TableCell>
            <TableCell>Attachment</TableCell>
            <TableCell>Carrier</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.sort(sortByDate).map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.createdAt?.toUTCString()}</TableCell>
              <TableCell>{formatBookingStatus(row.newStatus || '')}</TableCell>
              <TableCell>{row.reason}</TableCell>
              <TableCell>
                {row.transportDocumentReference && (
                  <a href={`/api/transport-documents/${row.transportDocumentReference}`}>
                    {row.transportDocumentReference}
                  </a>
                )}
              </TableCell>
              <TableCell>{row.carrierId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface props {
  rows: Array<BookingResponseAllOfCarrierMessages>;
}

function sortByDate(
  a: BookingResponseAllOfCarrierMessages,
  b: BookingResponseAllOfCarrierMessages,
) {
  if (!a.createdAt || !b.createdAt) {
    return 0;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return -1;
}
