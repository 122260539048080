import { IconButton, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { ReactElement } from 'react';

async function signOut() {
  const response = await fetch(`/api/auth/logout`);
  if (response.ok) {
    document.cookie = '__session=;path=/';
    document.location.href = '/';
  }
}

export const SignOutRoute = (): ReactElement => {
  return (
    <StyledListItemButton onClick={signOut}>
      <ListItemIcon>
        <IconButton size="small">
          <ExitToApp />
        </IconButton>
      </ListItemIcon>
      <ListItemText primary="Sign Out" />
    </StyledListItemButton>
  );
};

const StyledListItemButton = styled(ListItemButton)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
