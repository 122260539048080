import { ReactElement } from 'react';
import { Chip, Grid, Stack } from '@mui/material';
import { BookingResponse } from '../../generated';
import {
  formatCargoMovementTypeAtDestination,
  formatCargoMovementTypeAtOrigin,
  formatCommunicationChannelCode,
  formatDeliveryTypeAtDestination,
  formatIncoTerms,
  formatModeOfTransport,
  formatPaymentTermCode,
  formatReceiptTypeAtOrigin,
} from '../../utils/bookingRequestHelpers';
import { formatBoolean } from '../../utils/formatBoolean';
import { bookingStatusToColour, formatBookingStatus } from '../../utils/bookingStatus';
import BookingCarrierUpdatesTable from '../BookingCarrierUpdates';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const ShipmentBooking = ({ booking }: { booking: BookingResponse }): ReactElement => {
  return (
    <>
      <Typography sx={{ mt: 4 }} variant="h5" gutterBottom>
        Booking details
      </Typography>

      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <VisibilityIcon />
              <Typography>View booking details</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {booking?.carrierMessages && (
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }} variant="body1">
                    Updates from carrier
                  </Typography>
                  <BookingCarrierUpdatesTable rows={booking.carrierMessages} />
                </Grid>
              )}
              <Grid item xs={8}>
                <Typography style={{ fontWeight: 600 }} variant="body1">
                  Current status
                </Typography>
                <Chip
                  color={bookingStatusToColour(booking.status)}
                  label={formatBookingStatus(booking.status || '')}
                />
              </Grid>

              <Grid item xs={8}>
                <Typography style={{ fontWeight: 600 }} variant="body1">
                  Carrier booking reference
                </Typography>
                {booking?.carrierRef ? (
                  <Typography variant="body2">{booking?.carrierRef}</Typography>
                ) : (
                  <Typography variant="body2">N.A. (Not yet received from carrier)</Typography>
                )}
              </Grid>

              {booking.eblType == 'BIMCO' && (
                <>
                  <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Vessel name
                    </Typography>
                    <Typography variant="body2">{booking?.vesselName}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Charter party date
                    </Typography>
                    <Typography variant="body2">{booking?.charterPartyDate}</Typography>
                  </Grid>
                </>
              )}

              {booking.eblType == 'DCSA' && (
                <>
                  {booking.serviceContractReference !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Service contract reference
                      </Typography>
                      <Typography variant="body2">{booking?.serviceContractReference}</Typography>
                    </Grid>
                  )}
                  {booking.vesselName !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Vessel name
                      </Typography>
                      <Typography variant="body2">{booking?.vesselName}</Typography>
                    </Grid>
                  )}
                  {booking.vesselIMONumber !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Vessel IMO number
                      </Typography>
                      <Typography variant="body2">{booking?.vesselIMONumber}</Typography>
                    </Grid>
                  )}
                  {booking.incoTerms !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Incoterms
                      </Typography>
                      <Typography variant="body2">{formatIncoTerms(booking?.incoTerms)}</Typography>
                    </Grid>
                  )}
                  {booking.modeOfTransport !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Mode of Transport
                      </Typography>
                      <Typography variant="body2">
                        {formatModeOfTransport(booking?.modeOfTransport)}
                      </Typography>
                    </Grid>
                  )}
                  {booking.paymentTermCode !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Payment term code
                      </Typography>
                      <Typography variant="body2">
                        {formatPaymentTermCode(booking?.paymentTermCode)}
                      </Typography>
                    </Grid>
                  )}
                  {booking.contractQuotationReference !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Contract quotation reference
                      </Typography>
                      <Typography variant="body2">{booking?.contractQuotationReference}</Typography>
                    </Grid>
                  )}
                  {booking.carrierExportVoyageNumber !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Carrier export voyage number
                      </Typography>
                      <Typography variant="body2">{booking?.carrierExportVoyageNumber}</Typography>
                    </Grid>
                  )}
                  <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Cargo movement type at destination
                    </Typography>
                    <Typography variant="body2">
                      {formatCargoMovementTypeAtDestination(
                        booking?.cargoMovementTypeAtDestination,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Cargo movement type at origin
                    </Typography>
                    <Typography variant="body2">
                      {formatCargoMovementTypeAtOrigin(booking?.cargoMovementTypeAtOrigin)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Delivery type at destination
                    </Typography>
                    <Typography variant="body2">
                      {formatDeliveryTypeAtDestination(booking?.deliveryTypeAtDestination)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Receipt type at origin
                    </Typography>
                    <Typography variant="body2">
                      {formatReceiptTypeAtOrigin(booking?.receiptTypeAtOrigin)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Communication channel code
                    </Typography>
                    <Typography variant="body2">
                      {formatCommunicationChannelCode(booking?.communicationChannelCode)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Is equipment substitution allowed?
                    </Typography>
                    <Typography variant="body2">
                      {formatBoolean(booking?.isEquipmentSubstitutionAllowed)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Is import license required?
                    </Typography>
                    <Typography variant="body2">
                      {formatBoolean(booking?.isImportLicenseRequired)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Is partial load allowed?
                    </Typography>
                    <Typography variant="body2">
                      {formatBoolean(booking?.isPartialLoadAllowed)}
                    </Typography>
                  </Grid>
                  {booking.isExportDeclarationRequired && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Export declaration reference
                      </Typography>
                      <Typography variant="body2">{booking.exportDeclarationReference}</Typography>
                    </Grid>
                  )}
                  {booking.isImportLicenseRequired && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Import license reference
                      </Typography>
                      <Typography variant="body2">{booking.importLicenseReference}</Typography>
                    </Grid>
                  )}
                  {booking.expectedDepartureDate !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Expected departure date
                      </Typography>
                      <Typography variant="body2">{booking.expectedDepartureDate}</Typography>
                    </Grid>
                  )}
                  {booking.expectedArrivalAtPlaceOfDeliveryStartDate !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Expected arrival start date
                      </Typography>
                      <Typography variant="body2">
                        {booking.expectedArrivalAtPlaceOfDeliveryStartDate}
                      </Typography>
                    </Grid>
                  )}
                  {booking.expectedArrivalAtPlaceOfDeliveryEndDate !== undefined && (
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 600 }} variant="body1">
                        Expected arrival end date
                      </Typography>
                      <Typography variant="body2">
                        {booking.expectedArrivalAtPlaceOfDeliveryEndDate}
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
