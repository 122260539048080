/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddressRequest,
  AddressResponse,
} from '../models';
import {
    AddressRequestFromJSON,
    AddressRequestToJSON,
    AddressResponseFromJSON,
    AddressResponseToJSON,
} from '../models';

export interface AddressesIdDeleteRequest {
    id: string;
}

export interface AddressesIdGetRequest {
    id: string;
}

export interface AddressesIdPutRequest {
    id: string;
    addressRequest?: AddressRequest;
}

export interface AddressesPostRequest {
    addressRequest?: AddressRequest;
}

/**
 * 
 */
export class AddressApi extends runtime.BaseAPI {

    /**
     * Get all addresses that the user has view access for. 
     */
    async addressesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AddressResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressResponseFromJSON));
    }

    /**
     * Get all addresses that the user has view access for. 
     */
    async addressesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AddressResponse>> {
        const response = await this.addressesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete an address from the database
     */
    async addressesIdDeleteRaw(requestParameters: AddressesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addressesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an address from the database
     */
    async addressesIdDelete(requestParameters: AddressesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addressesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get address by id 
     */
    async addressesIdGetRaw(requestParameters: AddressesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddressResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addressesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressResponseFromJSON(jsonValue));
    }

    /**
     * Get address by id 
     */
    async addressesIdGet(requestParameters: AddressesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddressResponse> {
        const response = await this.addressesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specified address in the database
     */
    async addressesIdPutRaw(requestParameters: AddressesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addressesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddressRequestToJSON(requestParameters.addressRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the specified address in the database
     */
    async addressesIdPut(requestParameters: AddressesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addressesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new address to the database
     */
    async addressesPostRaw(requestParameters: AddressesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/addresses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressRequestToJSON(requestParameters.addressRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a new address to the database
     */
    async addressesPost(requestParameters: AddressesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addressesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
