/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressResponse
 */
export interface AddressResponse {
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    addressName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    postCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    stateRegion?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AddressResponse
     */
    creatorId?: number;
    /**
     * 
     * @type {Date}
     * @memberof AddressResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof AddressResponse
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AddressResponse
     */
    deletedAt?: Date;
}

/**
 * Check if a given object implements the AddressResponse interface.
 */
export function instanceOfAddressResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function AddressResponseFromJSON(json: any): AddressResponse {
    return AddressResponseFromJSONTyped(json, false);
}

export function AddressResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressName': !exists(json, 'addressName') ? undefined : json['addressName'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'postCode': !exists(json, 'postCode') ? undefined : json['postCode'],
        'cityName': !exists(json, 'cityName') ? undefined : json['cityName'],
        'stateRegion': !exists(json, 'stateRegion') ? undefined : json['stateRegion'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'id': json['id'],
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}

export function AddressResponseToJSON(value?: AddressResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressName': value.addressName,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
        'floor': value.floor,
        'postCode': value.postCode,
        'cityName': value.cityName,
        'stateRegion': value.stateRegion,
        'country': value.country,
        'id': value.id,
        'creatorId': value.creatorId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}

