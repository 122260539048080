/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The status of the document in the process. Possible values are:
 * - RECE (Received)
 * - DRFT (Drafted)
 * - PENA (Pending Approval)
 * - PENU (Pending Update)
 * - PENC (Pending Confirmation)
 * - CONF (Confirmed)
 * - REJE (Rejected)
 * - APPR (Approved)
 * - ISSU (Issued)
 * - SURR (Surrendered)
 * - SUBM (Submitted)
 * - VOID (Void)
 * - CANC (Cancelled)
 * - CMPL (Completed)
 * @export
 */
export const DocumentStatus = {
    Rece: 'RECE',
    Drft: 'DRFT',
    Pena: 'PENA',
    Penu: 'PENU',
    Penc: 'PENC',
    Conf: 'CONF',
    Reje: 'REJE',
    Appr: 'APPR',
    Issu: 'ISSU',
    Surr: 'SURR',
    Subm: 'SUBM',
    Void: 'VOID',
    Canc: 'CANC',
    Cmpl: 'CMPL'
} as const;
export type DocumentStatus = typeof DocumentStatus[keyof typeof DocumentStatus];


export function DocumentStatusFromJSON(json: any): DocumentStatus {
    return DocumentStatusFromJSONTyped(json, false);
}

export function DocumentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentStatus {
    return json as DocumentStatus;
}

export function DocumentStatusToJSON(value?: DocumentStatus | null): any {
    return value as any;
}

