export const shipmentLocationTypeCodes = [
  {
    value: 'POL',
    label: 'Port of Loading',
  },
  {
    value: 'POD',
    label: 'Port of Discharge',
  },
  {
    value: 'PDE',
    label: 'Place of Delivery',
  },
  {
    value: 'PRE',
    label: 'Place of Receipt',
  },
];

export const formatShipmentLocationType = (value: string) => {
  const findShipmentLocationById = shipmentLocationTypeCodes.find((c) => c.value == value);
  return findShipmentLocationById?.label;
};
