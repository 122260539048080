/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingResponse } from './BookingResponse';
import {
    BookingResponseFromJSON,
    BookingResponseFromJSONTyped,
    BookingResponseToJSON,
} from './BookingResponse';
import type { DocMetadata } from './DocMetadata';
import {
    DocMetadataFromJSON,
    DocMetadataFromJSONTyped,
    DocMetadataToJSON,
} from './DocMetadata';
import type { DocumentStatus } from './DocumentStatus';
import {
    DocumentStatusFromJSON,
    DocumentStatusFromJSONTyped,
    DocumentStatusToJSON,
} from './DocumentStatus';
import type { UserSummary } from './UserSummary';
import {
    UserSummaryFromJSON,
    UserSummaryFromJSONTyped,
    UserSummaryToJSON,
} from './UserSummary';

/**
 * 
 * @export
 * @interface ShipmentResponse
 */
export interface ShipmentResponse {
    /**
     * 
     * @type {string}
     * @memberof ShipmentResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentResponse
     */
    creatorId: number;
    /**
     * 
     * @type {UserSummary}
     * @memberof ShipmentResponse
     */
    creator: UserSummary;
    /**
     * 
     * @type {DocumentStatus}
     * @memberof ShipmentResponse
     */
    eblDocumentStatus?: DocumentStatus;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentResponse
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentResponse
     */
    deletedAt?: Date;
    /**
     * 
     * @type {BookingResponse}
     * @memberof ShipmentResponse
     */
    booking?: BookingResponse;
    /**
     * If the eBL has been issued on the blockchain using a minted token, its tokenid will be here
     * @type {string}
     * @memberof ShipmentResponse
     */
    tokenId?: string;
    /**
     * 
     * @type {Array<DocMetadata>}
     * @memberof ShipmentResponse
     */
    docs?: Array<DocMetadata>;
}

/**
 * Check if a given object implements the ShipmentResponse interface.
 */
export function instanceOfShipmentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "creatorId" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function ShipmentResponseFromJSON(json: any): ShipmentResponse {
    return ShipmentResponseFromJSONTyped(json, false);
}

export function ShipmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'creatorId': json['creatorId'],
        'creator': UserSummaryFromJSON(json['creator']),
        'eblDocumentStatus': !exists(json, 'eblDocumentStatus') ? undefined : DocumentStatusFromJSON(json['eblDocumentStatus']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'booking': !exists(json, 'booking') ? undefined : BookingResponseFromJSON(json['booking']),
        'tokenId': !exists(json, 'tokenId') ? undefined : json['tokenId'],
        'docs': !exists(json, 'docs') ? undefined : ((json['docs'] as Array<any>).map(DocMetadataFromJSON)),
    };
}

export function ShipmentResponseToJSON(value?: ShipmentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'creatorId': value.creatorId,
        'creator': UserSummaryToJSON(value.creator),
        'eblDocumentStatus': DocumentStatusToJSON(value.eblDocumentStatus),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'booking': BookingResponseToJSON(value.booking),
        'tokenId': value.tokenId,
        'docs': value.docs === undefined ? undefined : ((value.docs as Array<any>).map(DocMetadataToJSON)),
    };
}

