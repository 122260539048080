/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommodityRequest,
  CommodityResponse,
} from '../models';
import {
    CommodityRequestFromJSON,
    CommodityRequestToJSON,
    CommodityResponseFromJSON,
    CommodityResponseToJSON,
} from '../models';

export interface CommoditiesIdDeleteRequest {
    id: string;
}

export interface CommoditiesIdGetRequest {
    id: string;
}

export interface CommoditiesIdPutRequest {
    id: string;
    commodityRequest?: CommodityRequest;
}

export interface CommoditiesPostRequest {
    commodityRequest?: CommodityRequest;
}

/**
 * 
 */
export class CommodityApi extends runtime.BaseAPI {

    /**
     * Delete a Commodity from the database
     */
    async commoditiesIdDeleteRaw(requestParameters: CommoditiesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling commoditiesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/commodities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Commodity from the database
     */
    async commoditiesIdDelete(requestParameters: CommoditiesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.commoditiesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get Commodity by id 
     */
    async commoditiesIdGetRaw(requestParameters: CommoditiesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommodityResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling commoditiesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/commodities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommodityResponseFromJSON(jsonValue));
    }

    /**
     * Get Commodity by id 
     */
    async commoditiesIdGet(requestParameters: CommoditiesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommodityResponse> {
        const response = await this.commoditiesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specified Commodity in the database
     */
    async commoditiesIdPutRaw(requestParameters: CommoditiesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling commoditiesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/commodities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CommodityRequestToJSON(requestParameters.commodityRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the specified Commodity in the database
     */
    async commoditiesIdPut(requestParameters: CommoditiesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.commoditiesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new commodity to the database
     */
    async commoditiesPostRaw(requestParameters: CommoditiesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/commodities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommodityRequestToJSON(requestParameters.commodityRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a new commodity to the database
     */
    async commoditiesPost(requestParameters: CommoditiesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.commoditiesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
