import { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AppContext } from '../../Context';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ShipmentResponse } from '../../generated';
import { useFormContext } from 'react-hook-form';

export default function SecondStep(shipment: ShipmentResponse) {
  const { register, getValues, handleSubmit, setValue } = useFormContext();
  const { handleNext, handleBack } = useContext(AppContext);
  const formValues = getValues();

  const [departureDate, setDepartureDate] = useState<string | null>(
    shipment.booking?.expectedDepartureDate || null,
  );
  const [expectedArrivalStartDate, setExpectedArrivalStartDate] = useState<string | null>(
    shipment.booking?.expectedArrivalAtPlaceOfDeliveryStartDate || null,
  );
  const [expectedArrivalEndDate, setExpectedArrivalEndDate] = useState<string | null>(
    shipment.booking?.expectedArrivalAtPlaceOfDeliveryEndDate || null,
  );

  useEffect(() => {
    if (shipment.booking?.expectedDepartureDate) {
      setValue('expectedDepartureDate', shipment.booking.expectedDepartureDate);
    }

    if (shipment.booking?.expectedArrivalAtPlaceOfDeliveryStartDate) {
      setValue(
        'expectedArrivalAtPlaceOfDeliveryStartDate',
        shipment.booking.expectedArrivalAtPlaceOfDeliveryStartDate,
      );
    }

    if (shipment.booking?.expectedArrivalAtPlaceOfDeliveryEndDate) {
      setValue(
        'expectedArrivalAtPlaceOfDeliveryEndDate',
        shipment.booking.expectedArrivalAtPlaceOfDeliveryEndDate,
      );
    }
  }, [
    setValue,
    shipment.booking?.expectedDepartureDate,
    shipment.booking?.expectedArrivalAtPlaceOfDeliveryStartDate,
    shipment.booking?.expectedArrivalAtPlaceOfDeliveryEndDate,
  ]);

  const onSubmit = handleSubmit(() => {
    handleNext();
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register('vesselName')}
            fullWidth
            label="Vessel name"
            name="vesselName"
            placeholder="Enter a vessel name"
            defaultValue={formValues.vesselName || shipment.booking?.vesselName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('vesselIMONumber')}
            fullWidth
            label="Vessel IMO number"
            name="vesselIMONumber"
            placeholder="Enter a vessel IMO number"
            defaultValue={formValues.vesselIMONumber || shipment.booking?.vesselIMONumber}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...register('serviceContractReference')}
            fullWidth
            label="Service contract reference"
            name="serviceContractReference"
            placeholder="Enter a service contract reference"
            defaultValue={
              formValues.serviceContractReference || shipment.booking?.serviceContractReference
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...register('contractQuotationReference')}
            fullWidth
            label="Contract quotation reference"
            name="contractQuotationReference"
            placeholder="Enter a contract quotation reference"
            defaultValue={
              formValues.contractQuotationReference || shipment.booking?.contractQuotationReference
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...register('carrierExportVoyageNumber')}
            fullWidth
            label="Carrier export voyageNumber"
            name="carrierExportVoyageNumber"
            placeholder="Carrier export voyage number"
            defaultValue={
              formValues.carrierExportVoyageNumber || shipment.booking?.carrierExportVoyageNumber
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Incoterms"
            name="incoTerms"
            defaultValue={shipment.booking?.incoTerms ? shipment.booking.incoTerms : ''}
            inputProps={register('incoTerms')}
          >
            <option value=""></option>
            <option value="FCA">Free Carrier</option>
            <option value="FOB">Free on Board</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Mode of transport"
            name="modeOfTransport"
            defaultValue={shipment.booking?.modeOfTransport ? shipment.booking.modeOfTransport : ''}
            inputProps={register('modeOfTransport')}
          >
            <option value=""></option>
            <option value="VESSEL">Vessel</option>
            <option value="RAIL">Rail</option>
            <option value="TRUCK">Truck</option>
            <option value="BARGE">Barge</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            label="Payment term code"
            name="paymentTermCode"
            defaultValue={shipment.booking?.paymentTermCode ? shipment.booking.paymentTermCode : ''}
            inputProps={register('paymentTermCode')}
          >
            <option value=""></option>
            <option value="PRE">Prepaid</option>
            <option value="COL">Rail</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Expected departure date"
              value={departureDate}
              onChange={(newValue) => {
                const formattedDate = dayjs(newValue).format('MM/DD/YYYY');
                setDepartureDate(formattedDate);
                setValue('expectedDepartureDate', formattedDate);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Expected arrival start date"
              value={expectedArrivalStartDate}
              onChange={(newValue) => {
                const formattedDate = dayjs(newValue).format('MM/DD/YYYY');
                setExpectedArrivalStartDate(formattedDate);
                setValue('expectedArrivalAtPlaceOfDeliveryStartDate', formattedDate);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Expected arrival end date"
              value={expectedArrivalEndDate}
              onChange={(newValue) => {
                const formattedDate = dayjs(newValue).format('MM/DD/YYYY');
                setExpectedArrivalEndDate(formattedDate);
                setValue('expectedArrivalAtPlaceOfDeliveryEndDate', formattedDate);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={{ mt: 3, ml: 1 }} onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" sx={{ mt: 3, ml: 1 }} color="primary" onClick={onSubmit}>
          Next
        </Button>
      </Box>
    </>
  );
}
