import { BookingResponse, DocMetadata, ShipmentResponse } from '../generated';
import { OverridableStringUnion } from '@mui/types';
import { ChipPropsColorOverrides } from '@mui/material/Chip/Chip';

export function formatBookingStatus(input: string) {
  // - RECE (Received)
  // - PENU (Pending Update)
  // - PENC (Pending Confirmation)
  // - CONF (Confirmed)
  // - REJE (Rejected)
  // - CANC (Cancelled)
  // - CMPL (Completed)
  if (input === 'DRFT') {
    return 'DRFT (Draft)';
  }
  if (input === 'REQS') {
    return 'REQS (Requested)';
  }
  if (input === 'RECE') {
    return 'RECE (Received)';
  }
  if (input === 'PENU') {
    return 'PENU (Pending Update)';
  }
  if (input === 'PENC') {
    return 'PENC (Pending Confirmation)';
  }
  if (input === 'CONF') {
    return 'CONF (Confirmed)';
  }
  if (input === 'REJE') {
    return 'REJE (Rejected)';
  }
  if (input === 'CANC') {
    return 'CANC (Cancelled)';
  }
  if (input === 'CMPL') {
    return 'CMPL (Completed)';
  }
  if (input === 'ISSU') {
    return 'ISSU (Issued)';
  }
  return input;
}

export function getProcessStep(eblStatus: string, bookingStatus: string) {
  // - RECE (Received)
  // - PENU (Pending Update)
  // - PENC (Pending Confirmation)
  // - CONF (Confirmed)
  // - REJE (Rejected)
  // - CANC (Cancelled)
  // - CMPL (Completed)
  if (bookingStatus === 'CONF') {
    // This means the booking flow is already completed, so we will now look at the eblStatus
    // prop instead
    if (eblStatus === 'DRFT') {
      return 4;
    }
    if (eblStatus === 'CONF') {
      return 5;
    }
    if (eblStatus === 'ISSU') {
      return 6;
    }
    return 3;
  }

  if (bookingStatus === 'DRFT') {
    return 0;
  }
  if (bookingStatus === 'RECE' || bookingStatus === 'PENC' || bookingStatus === 'PENU') {
    return 2;
  }
  if (bookingStatus === 'REJE') {
    return 0;
  }
  if (bookingStatus === 'CANC') {
    return 1;
  }
  return 0;
}

export function bookingStatusToColour(
  input?: string,
): OverridableStringUnion<
  'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
  ChipPropsColorOverrides
> {
  // MUI options:
  //  'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
  if (input === 'RECE' || input === 'DRFT' || input === 'PENC' || input === 'REQS') {
    return 'info';
  }
  if (input === 'PENU') {
    return 'warning';
  }
  if (input === 'CONF') {
    return 'success';
  }
  if (input === 'REJE') {
    return 'error';
  }
  if (input === 'CANC') {
    return 'error';
  }
  if (input === 'CMPL' || input === 'ISSU') {
    return 'success';
  }
  return 'default';
}

export function canBookingBeEdited(booking: BookingResponse): boolean {
  switch (booking.status) {
    case 'PENU':
    case 'DRFT':
      return true;
  }
  return false;
}

// When a booking has been rejected, the user may want to try and submit a new one but with a few different fields
export function canBookingBeDetached(booking: BookingResponse): boolean {
  switch (booking.status) {
    case 'REJE':
    case 'CANC':
    case 'DRFT':
      return true;
  }
  return false;
}

export function canBookingBeCancelled(booking: BookingResponse): boolean {
  switch (booking.status) {
    case 'RECE':
    case 'PENU':
    case 'PENC':
      return true;
  }
  return false;
}

export function canBookingBeSent(booking: BookingResponse): boolean {
  if (booking.documentParties) {
    if (booking.eblType == 'BIMCO' && booking.documentParties.length < 3) {
      return false;
    }

    if (booking.eblType == 'DCSA' && booking.documentParties.length < 1) {
      return false;
    }
  } else {
    return false;
  }

  if (booking.commodities === undefined || booking.commodities.length < 1) {
    return false;
  }

  if (booking.shipmentLocations === undefined || booking.shipmentLocations.length < 2) {
    return false;
  }

  let hasPOL = false;
  let hasPOD = false;

  for (const location of booking.shipmentLocations) {
    if (location.shipmentLocationTypeCode === 'POL') {
      hasPOL = true;
    }
    if (location.shipmentLocationTypeCode === 'POD') {
      hasPOD = true;
    }
  }

  if (!hasPOL || !hasPOD) {
    return false;
  }

  switch (booking.status) {
    case 'PENU':
    case 'DRFT':
      return true;
  }

  return false;
}

export function canBLBeRequested(shipment: ShipmentResponse, booking: BookingResponse): boolean {
  return shipment.eblDocumentStatus === undefined && booking.status === 'CONF';
}

export function canBLBeApproved(shipment: ShipmentResponse) {
  return shipment.eblDocumentStatus === 'DRFT';
}

export function isBLIssued(shipment: ShipmentResponse) {
  return shipment.eblDocumentStatus === 'ISSU';
}

export function hasTTAttached(shipment: ShipmentResponse) {
  const docs = shipment.docs as DocMetadata[];

  for (const doc of docs) {
    if (doc.docType === 'TT') {
      return true;
    }
  }

  return false;
}
