import React, { ReactElement, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';
import { DocMetadata, ShipmentResponse } from '../../generated';
import { shipmentsApi } from '../../api/shipmentsApi';
import { useQuery } from '@tanstack/react-query';
import './ViewBookingRequest.css';

import { Breadcrumbs, Button, Link, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { DecentralisedRenderer } from '../DecentralisedRenderer/DecentralisedRenderer';
import { getAuthHeader } from '../../utils/getAuthHeader';

export const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '2px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: '1px solid #ccc',
}));

// View a rendered version of a booking request json document
export const ViewBookingRequest = (): ReactElement => {
  const [docVaultId, setDocVaultId] = useState<string>('');
  const [shipment, setShipment] = useState<ShipmentResponse>();
  const [bookingDoc, setBookingDoc] = useState<DocMetadata>();

  let shipmentId = '';
  const { id } = useParams();
  if (id != undefined) {
    shipmentId = id;
  }

  useQuery(['shipment', shipmentId], () => fetchAndSetData(shipmentId), {
    retry: false,
  });

  // Fetch shipment and tradetrust file to propagate data into the title escrow page
  async function fetchAndSetData(id: string) {
    const authHeader = await getAuthHeader();

    const shipmentData = await shipmentsApi.shipmentsIdGet({ id: id }, { headers: authHeader });
    setShipment(shipmentData);

    const brDocs = shipmentData.docs?.filter((doc) => doc.fileName.startsWith('booking-request-'));
    if (!brDocs || brDocs.length < 1) {
      console.error('Doc to be rendered is not found');
      return shipmentData;
    }
    // Pick the latest booking request to be rendered
    brDocs.sort((a, b) => (a.updatedAt > b.updatedAt ? 0 : 1));
    const bookingRequestDoc = brDocs[0];
    setBookingDoc(bookingRequestDoc);

    const bookingRequestDocVaultId = bookingRequestDoc.vaultId;
    setDocVaultId(bookingRequestDocVaultId);
    return shipmentData;
  }

  return (
    <div>
      <Breadcrumbs className="no-print" style={{ fontSize: 35 }} aria-label="breadcrumb">
        <Link underline="hover" component={RouterLink} color="inherit" to="/">
          Shipments
        </Link>
        <Link
          underline="hover"
          component={RouterLink}
          color="text.primary"
          to={`/shipments/${shipmentId}`}
        >
          {shipment?.name}
        </Link>
        <Link underline="none" color="text.primary">
          View booking request
        </Link>
      </Breadcrumbs>

      <Box sx={{ flexGrow: 1 }} className="no-print">
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={8} id="doc-render-wrap">
            <Item>
              <DecentralisedRenderer
                vaultId={docVaultId}
                isWrapped={false}
                templateType="BOOKING_REQUEST"
              />
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              {bookingDoc && (
                <Typography variant="h6">
                  Booking registered at: {bookingDoc?.createdAt.toLocaleString('nl')}
                </Typography>
              )}
            </Item>
            <Item>
              <Button style={{ marginLeft: 5 }} onClick={() => print()} variant="contained">
                Download as PDF
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <div className="renderer-print-version">
        <DecentralisedRenderer
          vaultId={docVaultId}
          isWrapped={false}
          templateType="BOOKING_REQUEST"
        />
      </div>
    </div>
  );
};
