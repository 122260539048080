import { ReactElement, useEffect, useState } from 'react';
import { Button, Tooltip, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TitleEscrow, TradeTrustToken__factory } from '@govtechsg/token-registry/dist/contracts';
import { toast } from 'react-toastify';
import { QueryClient } from '@tanstack/react-query';
import { Item, titleEscrowTransfer } from './TitleTransfer';
import { useConnectWallet } from '@web3-onboard/react';
import {
  getTradeTrustTokenRegistry,
  getTokenRegistryAddress,
} from '../../tradeTrust/tokenRegistry';
import { getWalletSigner } from '../../utils/wallet';
import { constants } from '@govtechsg/token-registry';
import { Logger } from 'ethers/lib/utils';
import { showShortWalletAddr } from '../../utils/shortWalletAddress';

interface Props {
  connectedEscrow: TitleEscrow;
  queryClient: QueryClient;
  refreshTitleEscrowData: () => void;
}

const { roleHash } = constants;

// Small component to accept a surrendered document (this will call the "shred" title escrow method)
export function ManageShredDocument(props: Props): ReactElement {
  const { handleSubmit, reset } = useForm<titleEscrowTransfer>();
  const [{ wallet }] = useConnectWallet();
  const [enabled, setEnabled] = useState<boolean>();
  const walletAddr = wallet?.accounts?.[0].address;

  // Check whether the current wallet has sufficient permissions
  useEffect(() => {
    const checkPermissions = async () => {
      const signer = getWalletSigner();
      const tokenRegistry = TradeTrustToken__factory.connect(
        getTokenRegistryAddress(parseInt(window.ethereum?.networkVersion)),
        signer,
      );
      if (walletAddr === undefined) {
        return;
      }

      const hasRole = await tokenRegistry.hasRole(roleHash.AccepterRole, walletAddr);
      setEnabled(hasRole);
    };

    checkPermissions();
  }, [walletAddr]);

  const performTransaction: SubmitHandler<titleEscrowTransfer> = async () => {
    try {
      const tokenRegistry = await getTradeTrustTokenRegistry();
      if (tokenRegistry === undefined) {
        toast.error('Token registry not connected');
        return;
      }

      const tokenId = await props.connectedEscrow.tokenId();
      const burnResult = await tokenRegistry.burn(tokenId);
      await burnResult.wait(1);
      toast.success('Accepted surrender document');
      props.refreshTitleEscrowData();
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.code === Logger.errors.UNPREDICTABLE_GAS_LIMIT) {
        toast.info(
          `Please switch the active account to ${showShortWalletAddr(walletAddr)} in MetaMask`,
        );
      } else {
        if (error.message.includes('find tokenID')) {
          toast.error('Could not find tokenID');
        } else {
          toast.error(
            'Could not accept surrendered document, please check gas amount and permissions',
          );
        }
      }
    }
    reset();
  };

  return (
    <div>
      <Item>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Accept surrendered document
        </Typography>
        <Typography variant="body2">
          Accept and shred the eBL document (this will transfer the document to an inaccessible
          address).
        </Typography>
        {enabled ? (
          <form onSubmit={handleSubmit(performTransaction)}>
            <Button type="submit" variant="contained" sx={{ my: 1 }}>
              Accept document
            </Button>
          </form>
        ) : (
          <Tooltip title="You do not have the accepter role to perform this action">
            <span>
              <Button type="submit" disabled={true} variant="contained" sx={{ my: 1 }}>
                Accept document
              </Button>
            </span>
          </Tooltip>
        )}
      </Item>
    </div>
  );
}
