import React, { useCallback, useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ShipmentResponse } from '../../generated';
import { FileUploader } from '../FileUploader';
import { vaultApi } from '../../api/vaultApi';
import { verifyDoc } from '../../config/oa-verify';
import { getNetworkName } from '../../tradeTrust/tokenRegistry';
import { getAuthHeader } from '../../utils/getAuthHeader';

interface props {
  shipment: ShipmentResponse;
}

// A small UI component for the overview of the current tradetrust status for a single
// tradetrust-ebl document
// If no TT-document is uploaded yet, allow a small spot to do that here via a dropzone
export default function TTSummary(props: props) {
  const queryClient = useQueryClient();
  const [verified, setVerified] = useState<boolean>(false);
  const [verifying, setVerifying] = useState<boolean>(false);
  const [error, setError] = useState('');
  const ttDoc = props.shipment.docs?.find((doc) => doc.fileName.endsWith('tt'));

  async function submitFile(file: File): Promise<string> {
    const authHeader = await getAuthHeader();
    const str = await vaultApi.vaultPost(
      {
        file: file,
        shipmentId: props.shipment.id,
        docType: 'TT',
      },
      { headers: authHeader },
    );

    await queryClient.invalidateQueries(['shipment', props.shipment.id]);
    return str;
  }

  const fetchData = useCallback(
    async (chainId: string) => {
      if (!ttDoc) return;
      setVerifying(true);
      if (!chainId) {
        console.error('No wallet is connected to a valid ethereum network');
        setVerifying(false);
        setVerified(false);
        return;
      }
      try {
        const isVerified = await verifyDoc(ttDoc.vaultId, parseInt(chainId));
        setVerified(isVerified);
        setError('');
      } catch (err) {
        setError(
          err instanceof Error ? err.message : 'An error occurred while verifying the document',
        );
        setVerified(false);
      }
      setVerifying(false);
    },
    [ttDoc],
  );

  useEffect(() => {
    const chainId = window.ethereum?.networkVersion;
    fetchData(chainId).catch(console.error);
  }, [props.shipment?.docs, ttDoc, fetchData]);

  return (
    <div>
      {ttDoc ? (
        verifying ? (
          <Alert severity="info">Verifying .tt file...</Alert>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          verified && (
            <Alert severity="success">
              TradeTrust document verified on{' '}
              {getNetworkName(parseInt(window.ethereum?.networkVersion))}
            </Alert>
          )
        )
      ) : (
        <div>
          <p></p>
          <Alert severity="info">
            Please attach the TradeTrust (.tt) file, to start managing the eBL token
          </Alert>
          <FileUploader onFileSubmit={submitFile} />
        </div>
      )}
    </div>
  );
}
