/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The unit of measure which can be expressed in either imperial or metric terms
 * - FTQ (Cubic foot)
 * - MTQ (Cubic meter)
 * @export
 */
export const CargoGrossVolumeUnit = {
    Mtq: 'MTQ',
    Ftq: 'FTQ'
} as const;
export type CargoGrossVolumeUnit = typeof CargoGrossVolumeUnit[keyof typeof CargoGrossVolumeUnit];


export function CargoGrossVolumeUnitFromJSON(json: any): CargoGrossVolumeUnit {
    return CargoGrossVolumeUnitFromJSONTyped(json, false);
}

export function CargoGrossVolumeUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): CargoGrossVolumeUnit {
    return json as CargoGrossVolumeUnit;
}

export function CargoGrossVolumeUnitToJSON(value?: CargoGrossVolumeUnit | null): any {
    return value as any;
}

