import { vaultApi } from '../api/vaultApi';
import { getAuthHeader } from './getAuthHeader';

export async function retrieveFile(vaultId: string) {
  const authHeader = await getAuthHeader();

  const data = await vaultApi.vaultGet(
    {
      id: vaultId,
    },
    { headers: authHeader },
  );

  const re = /(?:\.([^.]+))?$/;
  const extension = (re.exec(data.filename) || [])[1]?.toLowerCase();

  // TODO this on-demand link building and "virtual click" is a bit of a hack to obtain the file
  const link = document.createElement('a');
  link.href = `data:${`application/${extension || 'pdf'}`};base64,${data.file}`;
  link.setAttribute('download', `${data.filename}${extension ? '' : '.pdf'}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}
