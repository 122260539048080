/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DCSAResponsibleAgencyCode = {
    Iso: 'ISO',
    Unece: 'UNECE',
    Lloyd: 'LLOYD',
    Bic: 'BIC',
    Imo: 'IMO',
    Scac: 'SCAC',
    Itigg: 'ITIGG',
    Itu: 'ITU',
    Smdg: 'SMDG',
    Exis: 'EXIS',
    Fmc: 'FMC',
    Cbsa: 'CBSA',
    Dcsa: 'DCSA',
    Did: 'DID',
    Lei: 'LEI',
    Zzz: 'ZZZ'
} as const;
export type DCSAResponsibleAgencyCode = typeof DCSAResponsibleAgencyCode[keyof typeof DCSAResponsibleAgencyCode];


export function DCSAResponsibleAgencyCodeFromJSON(json: any): DCSAResponsibleAgencyCode {
    return DCSAResponsibleAgencyCodeFromJSONTyped(json, false);
}

export function DCSAResponsibleAgencyCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DCSAResponsibleAgencyCode {
    return json as DCSAResponsibleAgencyCode;
}

export function DCSAResponsibleAgencyCodeToJSON(value?: DCSAResponsibleAgencyCode | null): any {
    return value as any;
}

