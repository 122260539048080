import { Alert, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import './TokenHistoryInner.css';
import React, { ReactElement, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { gql } from '@apollo/client';
import {
  getGraphClient,
  getTokenHistoryQuery, parseInitiator,
  parseTitleEscrowAddress,
  parseTokenHistoryData,
  Snapshot,
} from './queryDocuments';
import { useQuery } from '@tanstack/react-query';
import { PartyResponse, ShipmentResponse } from '../../generated';
import { partiesApi } from '../../api/partiesApi';
import { shipmentsApi } from '../../api/shipmentsApi';
import { getAuthHeader } from '../../utils/getAuthHeader';
import QRCode from 'react-qr-code';
import { CheckCircle } from '@mui/icons-material';

export const TokenHistoryInner = (): ReactElement => {
  const { id } = useParams();
  let shipmentId = '';
  if (id !== undefined) {
    shipmentId = id;
  }

  const [snapshots, setSnapshots] = useState<Array<Snapshot>>([]);
  const [parties, setParties] = useState<Array<PartyResponse>>([]);
  const { isError } = useQuery(['shipment', shipmentId], () => fetchAndSetData(shipmentId), {
    retry: false,
  });
  const [noData, setNoData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>();
  const [titleEscrowAddress, setTitleEscrowAddress] = useState<string>();
  const [initiator, setInitiator] = useState<string>();

  // Obtain the url of the block explorer where all transactions that happened
  // for a single title escrow can be inspected
  function getEventsExplorerUrl(): string {
    const chainId = parseInt(window.ethereum?.networkVersion);

    // Polygon network by default
    let url = 'https://oklink.com/amoy/address/' + titleEscrowAddress + '#events';
    if (chainId === 11155111) {
      url = 'https://sepolia.etherscan.io/address/' + titleEscrowAddress + '#events';
    }
    if (chainId === 51) {
      url = 'https://apothem.xdcscan.io/tx/' + titleEscrowAddress + '#events';
    }
    return url;
  }

  function getTransactionExplorerUrl(tx: string): string {
    const chainId = parseInt(window.ethereum?.networkVersion);

    // Polygon network by default
    let url = 'https://www.oklink.com/amoy/tx/' + tx;
    if (chainId === 11155111) {
      url = 'https://sepolia.etherscan.io/tx/' + tx;
    }
    if (chainId === 51) {
      url = 'https://apothem.xdcscan.io/tx/' + tx;
    }
    return url;
  }

  function getAddressExplorerUrl(address: string): string {
    const chainId = parseInt(window.ethereum?.networkVersion);

    // Polygon network by default
    let url = 'https://www.oklink.com/amoy/address/' + address;
    if (chainId === 11155111) {
      url = 'https://sepolia.etherscan.io/address/' + address;
    }
    if (chainId === 51) {
      url = 'https://apothem.xdcscan.io/address/' + address;
    }
    return url;
  }

  // A short clickable link to the address page. If the address exists in the user's address book,
  // then the party name should also be shown here.
  function displayParty(address?: string, showFullAddress?: boolean): JSX.Element {
    if (!address) {
      return <></>;
    }
    const party = parties.find((p) => p.publicKey?.toLowerCase() === address.toLowerCase());
    if (party?.partyName) {
      return (
        <Link
          underline="hover"
          component={RouterLink}
          color="text.primary"
          to={`/address-book/${address}/edit`}
        >
          {showFullAddress && (
            <div>
              <Typography variant="h5">{party.partyName}</Typography>
              {/*TODO this is a duplicate field - what do we do with it?*/}
              {/*<Typography variant="subtitle2">{party.address?.addressName}</Typography>*/}
              <Typography variant="subtitle2">
                {party.address?.streetName} {party.address?.streetNumber}
              </Typography>
              <Typography variant="subtitle2">{party.address?.postCode}</Typography>
              <Typography variant="subtitle2">{party.address?.cityName}</Typography>
              <Typography variant="subtitle2">{party.address?.country}</Typography>
              <br />
            </div>
          )}
          <Typography variant="body1">{address}</Typography>
        </Link>
      );
    }
    return (
      <Link
        underline="hover"
        component={RouterLink}
        color="text.primary"
        to={`/address-book/${address}/edit`}
      >
        <Typography variant="body1">{address}</Typography>
      </Link>
    );
  }

  // Fetch shipment and tradetrust file to propagate data into the token history page
  const fetchAndSetData = useCallback(async (id: string): Promise<ShipmentResponse> => {
    setLoading(true);
    const authHeader = await getAuthHeader();
    const partiesData = await partiesApi.partiesGet({ headers: authHeader });
    setParties(partiesData);

    const shipmentData = await shipmentsApi.shipmentsIdGet({ id: id }, { headers: authHeader });
    const tokenId = shipmentData.tokenId;
    if (tokenId === undefined) {
      console.error('Token id is not attached to the shipment object');
      return shipmentData;
    }
    await fetchGraphClientData(tokenId);
    return shipmentData;
  }, []);

  // Fetch and refresh all title escrow data from blockchain state;
  // use the token ID to fetch the history using the graphClient instance
  async function fetchGraphClientData(tokenId: string) {
    try {
      const result = await getGraphClient(parseInt(window.ethereum?.networkVersion)).query({
        query: gql(getTokenHistoryQuery('0x' + tokenId)),
      });
      console.log(result.data);
      if (!result.data || result.data.tokens.length < 1) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setLoading(false);
      setTitleEscrowAddress(parseTitleEscrowAddress(result));
      setSnapshots(parseTokenHistoryData(result));
      setInitiator(parseInitiator(result));
    } catch (err) {
      setLoading(false);
      console.error('Error fetching data: ', err);
    }
  }

  function formatDate(timestamp: string) {
    return new Date(parseInt(timestamp) * 1000).toLocaleString('nl');
  }

  // Translate action types into readable action names
  function formatActionName(actionName: string) {
    if (actionNames.has(actionName)) {
      return actionNames.get(actionName);
    }
    return actionName;
  }

  // We should not be showing the full display address (street, postcode etc.) for intermediate
  // transfers
  function showFullAddress(actionName: string) {
    switch (actionName) {
      case 'HolderTransfer':
      case 'BeneficiaryTransfer':
      case 'NominationRevocation':
      case 'Nomination':
      case 'Surrender':
        return false;
      default:
        return true;
    }
  }

  function getRole(actionName: string): string {
    switch (actionName) {
      case 'Issuance':
        return 'OceanWind Lines as carrier';
      case 'Restoration':
        return 'the owner'
      case 'Acceptance':
        return 'OceanWind Lines as carrier'
      case 'Surrender':
        return 'the holder'
      case 'Nomination':
        return 'the owner'
      case 'NominationRevocation':
        return 'the nominee'
      case 'BeneficiaryTransfer':
        return 'the owner';
      case 'HolderTransfer':
        return 'the owner';
      default:
        return '(unknown)';
    }
  }

  function getPublicKeyForStep(step: Snapshot): string | undefined {
    switch (getRole(step.action)) {
      case 'OceanWind Lines as carrier':
        return initiator;
      case 'the owner':
        return step.beneficiary?.id;
      case 'the nominee':
        return step.nominee?.id;
      case 'the holder':
        return step.holder?.id;
      default:
        return '(unknown)';
    }
  }

  const actionNames = new Map<string, string>([
    ['Issuance', 'Issued by OceanWind Lines as carrier to:'],
    ['HolderTransfer', 'Transfer'],
    ['BeneficiaryTransfer', 'Owner transfer'],
    ['NominationRevocation', 'Nomination revocation'],
    ['Surrender', 'Surrender'],
    ['Acceptance', 'Accepted by OceanWind Lines as carrier'],
  ]);

  if (id === undefined) {
    return <p>Error: shipment ID not found</p>;
  }

  return (
    <Grid container width={1500}>
      <Grid item xs={3.5}>
        <Typography variant="h4">Action</Typography>
      </Grid>

      <Grid item xs={12}>
        <br/>
      </Grid>

      {loading && (
        <Grid container>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}

      {isError && (
        <Grid container>
          <Grid item>
            <Alert severity="warning">Error fetching token history data.</Alert>
          </Grid>
        </Grid>
      )}

      {noData && (
        <Grid container>
          <Grid item>
            <Alert severity="warning">
              No token history data found. The token may be issued over a different blockchain
              network; Try switching the connected network in your wallet.
            </Alert>
          </Grid>
        </Grid>
      )}

      {snapshots.map((step, index) => (step.action !== "BeneficiaryTransfer") && (
        <div key={index}>
          <Grid key={index} minWidth={1500} container>
            <Grid item xs={3.5}>
              <Grid container>
                <Grid item xs={1.3}>
                  <CheckCircle fontSize="large" />
                </Grid>
                <Grid item xs={10.7}>
                  <Typography variant="h6">{formatActionName(step.action)}</Typography>
                  <Typography variant="body2">{formatDate(step.timestamp)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4.25}>
              {(index === 0) && (
                <div>
                  <Grid item xs={4.25}>
                    <Typography variant="h4">Owner</Typography>
                  </Grid>
                </div>
              )}
              {displayParty(step.beneficiary?.id, showFullAddress(step.action))}
            </Grid>
            <Grid item xs={4.25}>
              {(index === 0) && (
                <div>
                  <Grid item xs={4.25}>
                    <Typography variant="h4">Holder</Typography>
                  </Grid>
                </div>
              )}
              {displayParty(step.holder?.id, showFullAddress(step.action))}
            </Grid>
            {/*Removing Nominee column for now*/}
            {/*<Grid item xs={3.5}>*/}
            {/*  {displayParty(step.nominee?.id, showFullAddress(step.action))}*/}
            {/*</Grid>*/}
          </Grid>
          <br/>
          <Grid key={index} minWidth={1500} container>
            <Grid item xs={12}>
              <Typography variant="body2">
                Document has been successfully signed by {getRole(step.action)},
                with the following digital signature: <a href={getTransactionExplorerUrl(step.txHash)}
                                                         target="_blank" rel="noreferrer">
                {step.txHash}
              </a>.
              <br/>
                {/*TODO @TIM these are not the correct public key addresses - this requires a rework*/}
                Using the public key <a href={getAddressExplorerUrl(getPublicKeyForStep(step) || "")}
                                        target="_blank"
                                        rel="noreferrer">{getPublicKeyForStep(step)}</a>.
              </Typography>
            </Grid>
          </Grid>

          <br/>
          <Divider variant={'fullWidth'} orientation={'horizontal'} color="black" />
          <br />
        </div>
      ))}

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">
            View transactions on blockchain:
            <br />
            <a href={getEventsExplorerUrl()} target="_blank" rel="noreferrer">
              {getEventsExplorerUrl()}
            </a>
          </Typography>
        </Grid>
      </Grid>

      <Grid container className="print-only">
        <Grid item xs={12}>
          <br />
          <QRCode value={getEventsExplorerUrl()} />
        </Grid>
      </Grid>
    </Grid>
  );
};
