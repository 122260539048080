import {
  CargoMovementTypeAtDestination,
  CargoMovementTypeAtOrigin,
  CommunicationChannelCode,
  DeliveryTypeAtDestination,
  IncoTerms,
  ModeOfTransport,
  PaymentTermCode,
  ReceiptTypeAtOrigin,
} from '../generated';

export function formatCargoMovementTypeAtOrigin(input: string | undefined) {
  switch (input) {
    case CargoMovementTypeAtOrigin.Fcl: {
      return 'Full Container Load';
    }
    case CargoMovementTypeAtOrigin.Lcl: {
      return 'Less than Container Load';
    }
    case CargoMovementTypeAtOrigin.Bb: {
      return 'Break Bulk';
    }
  }
}

export function formatCargoMovementTypeAtDestination(input: string | undefined) {
  switch (input) {
    case CargoMovementTypeAtDestination.Fcl: {
      return 'Full Container Load';
    }
    case CargoMovementTypeAtDestination.Lcl: {
      return 'Less than Container Load';
    }
    case CargoMovementTypeAtDestination.Bb: {
      return 'Break Bulk';
    }
  }
}

export function formatDeliveryTypeAtDestination(input: string | undefined) {
  switch (input) {
    case DeliveryTypeAtDestination.Cfs: {
      return 'Container Freight Station';
    }
    case DeliveryTypeAtDestination.Cy: {
      return 'Container yard (incl. rail ramp)';
    }
    case DeliveryTypeAtDestination.Sd: {
      return 'Store Door';
    }
  }
}

export function formatReceiptTypeAtOrigin(input: string | undefined) {
  switch (input) {
    case ReceiptTypeAtOrigin.Cfs: {
      return 'Container Freight Station';
    }
    case ReceiptTypeAtOrigin.Cy: {
      return 'Container yard (incl. rail ramp)';
    }
    case ReceiptTypeAtOrigin.Sd: {
      return 'Store Door';
    }
  }
}

export function formatCommunicationChannelCode(input: string | undefined) {
  switch (input) {
    case CommunicationChannelCode.Ao: {
      return 'API';
    }
    case CommunicationChannelCode.Ei: {
      return 'EDI transmission';
    }
    case CommunicationChannelCode.Em: {
      return 'Email';
    }
  }
}

export function formatIncoTerms(input: string | undefined) {
  switch (input) {
    case IncoTerms.Fca: {
      return 'Free Carrier';
    }
    case IncoTerms.Fob: {
      return 'Free on Board';
    }
  }
}

export function formatModeOfTransport(input: string | undefined) {
  switch (input) {
    case ModeOfTransport.Barge: {
      return 'Barge';
    }
    case ModeOfTransport.Rail: {
      return 'Rail';
    }
    case ModeOfTransport.Truck: {
      return 'Truck';
    }
    case ModeOfTransport.Vessel: {
      return 'Vessel';
    }
  }
}

export function formatPaymentTermCode(input: string | undefined) {
  switch (input) {
    case PaymentTermCode.Col: {
      return 'Collect';
    }
    case PaymentTermCode.Pre: {
      return 'Prepaid';
    }
  }
}
