import { ReactElement, useCallback, useMemo, useState } from 'react';
import { Button, Card, CardContent, LinearProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';

export function FileUploader(props: props): ReactElement {
  const [fileInput, setFileInput] = useState<null | File>(null);
  const [fileName, setFileName] = useState<string>();
  const [isUploading, setUploading] = useState<boolean>(false);
  const [resultData, setResultData] = useState<string>('');

  const baseStyle = useMemo(
    () => ({
      flex: 1,
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out',
    }),
    [],
  );

  const focusedStyle = useMemo(
    () => ({
      borderColor: '#2196f3',
    }),
    [],
  );

  const acceptStyle = useMemo(
    () => ({
      borderColor: '#00e676',
    }),
    [],
  );

  const rejectStyle = useMemo(
    () => ({
      borderColor: '#ff1744',
    }),
    [],
  );

  const fileHandler = useCallback((acceptedFiles) => {
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      setFileInput(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: fileHandler,
      accept: {
        'application/pdf': ['.pdf'],
        'application/json': ['.json', '.tt'],
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, acceptStyle, baseStyle, focusedStyle, rejectStyle],
  );

  async function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (fileInput !== null) {
      setFileName(fileInput.name);
      setUploading(true);
      const response = await props.onFileSubmit(fileInput);
      setResultData(response);
      setUploading(false);
    }
  }

  return (
    <div>
      <form onSubmit={(e) => submitForm(e)}>
        <Card
          variant="outlined"
          {...getRootProps({ style, isFocused, isDragAccept, isDragReject })}
        >
          <CardContent>
            <input type="file" {...getInputProps()} />
            {isDragActive ? (
              <p>Drop a pdf file here...</p>
            ) : (
              <p>Drag or drop some files here, or click to select files</p>
            )}
          </CardContent>
        </Card>
        <p>{fileInput && `Selected file: ${fileInput.name}`}</p>
        <Button variant="contained" type="submit">
          Upload file
        </Button>
      </form>
      {isUploading && (
        <div>
          <p>Uploading file: {fileName}</p>
          <LinearProgress />
        </div>
      )}
      {resultData && (
        <div>
          <p>Created file {resultData}</p>
        </div>
      )}
    </div>
  );
}

interface props {
  onFileSubmit: (file: File) => Promise<string>;
}
