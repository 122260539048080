import React, { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { QueryClient } from '@tanstack/react-query';
import { Item, titleEscrowTransfer } from './TitleTransfer';
import { useConnectWallet } from '@web3-onboard/react';
import { PartyResponse } from '../../generated';
import { Logger } from 'ethers/lib/utils';
import { TitleEscrow } from '@govtechsg/token-registry/dist/contracts';
import { showShortWalletAddr } from '../../utils/shortWalletAddress';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
  connectedEscrow: TitleEscrow;
  queryClient: QueryClient;
  refreshTitleEscrowData: () => void;
  parties: Array<PartyResponse>;
}

interface RequestTransfer {
  address: string;
}

export function ManageHolder(props: Props): ReactElement {
  const [holder, setHolder] = useState<string | undefined>();
  const [partyName, setPartyName] = useState<string | undefined>();
  const { register, handleSubmit, reset } = useForm<RequestTransfer>();
  const [{ wallet }] = useConnectWallet();

  const walletAddr = wallet?.accounts?.[0].address;
  const [useCustomAddress, setUseCustomAddress] = useState(false); // Track whether to use custom address
  const [selectedAddress, setSelectedAddress] = useState<string>(''); // Track the selected address

  const theme = useTheme();

  const fetchHolder = async () => {
    try {
      const holder = await props.connectedEscrow.holder();
      const party = props.parties.find((p) => p.publicKey?.toLowerCase() === holder.toLowerCase());
      setPartyName(party?.partyName);
      setHolder(holder);
    } catch (error) {
      setHolder('(None)');
    }
  };

  useEffect(() => {
    if (holder === undefined) {
      fetchHolder();
    }
  });

  const performTransaction: SubmitHandler<titleEscrowTransfer> = async (data) => {
    try {
      const transaction = await props.connectedEscrow.transferHolder(
        useCustomAddress ? selectedAddress : data.address,
      );
      setHolder('Waiting for confirmation...');
      await transaction.wait(1);
      toast.success(
        `Transfer of holder to ${showShortWalletAddr(
          useCustomAddress ? selectedAddress : data.address,
        )} succeeded`,
      );
      setHolder(useCustomAddress ? selectedAddress : data.address);
      props.refreshTitleEscrowData();
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.code === Logger.errors.UNPREDICTABLE_GAS_LIMIT) {
        toast.info(
          `Please switch the active account to ${showShortWalletAddr(walletAddr)} in MetaMask`,
        );
      } else {
        toast.error('Could not transfer holder, please check gas amount and permissions');
      }
    }
    reset();
  };

  const handleSwitchChange = () => {
    // Toggle the switch state and reset the selected address
    setUseCustomAddress(!useCustomAddress);
    setSelectedAddress(''); // Reset the selected address
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAddress(event.target.value); // Update the selected address
  };

  const tooltipStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  };

  const iconButtonStyle = {
    color: theme.palette.primary.main,
  };

  return (
    <div>
      <Item>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Current holder
        </Typography>
        <Typography variant="body1">{partyName}</Typography>
        <Typography variant="body2">{holder}</Typography>
      </Item>

      {walletAddr === holder?.toLowerCase() && (
        <Item>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Change holder
          </Typography>
          <Typography variant="body2">
            Transfer your holder rights to another Ethereum address
          </Typography>
          <form onSubmit={handleSubmit(performTransaction)}>
            <div>
              <div
                style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}
              >
                <Tooltip
                  title="In the address book, enter a public key to display it in this dropdown."
                  arrow
                  style={tooltipStyle}
                >
                  <IconButton aria-label="info" style={iconButtonStyle}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <Typography>Select from address book</Typography>
                <Switch checked={useCustomAddress} onChange={handleSwitchChange} />
                <Typography>Use custom address</Typography>
              </div>
            </div>
            {useCustomAddress ? (
              <TextField
                {...register('address')}
                margin="dense"
                id="customAddress"
                label="Custom Ethereum address"
                placeholder="Custom Address (e.g. 0x4A...)"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={selectedAddress}
                onChange={handleAddressChange}
              />
            ) : (
              <TextField
                {...register('address')}
                margin="dense"
                select
                defaultValue=""
                id="holder"
                label="Select Ethereum address"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={selectedAddress}
                onChange={handleAddressChange}
              >
                {props.parties
                  .filter((p) => p.publicKey?.toLowerCase() !== holder?.toLowerCase())
                  .map((p) => (
                    <MenuItem key={p.publicKey} value={p.publicKey}>
                      {p.partyName}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            {walletAddr === holder?.toLowerCase() && (
              <Button type="submit" variant="contained" sx={{ my: 1 }}>
                Transfer
              </Button>
            )}
          </form>
        </Item>
      )}
    </div>
  );
}
