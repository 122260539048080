import { ReactElement, useEffect, useState } from 'react';
import { Button, Tooltip, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TitleEscrow } from '@govtechsg/token-registry/dist/contracts';
import { toast } from 'react-toastify';
import { QueryClient } from '@tanstack/react-query';
import { Item, titleEscrowTransfer } from './TitleTransfer';
import { useConnectWallet } from '@web3-onboard/react';
import { constants } from '@govtechsg/token-registry';
import { Logger } from 'ethers/lib/utils';
import { showShortWalletAddr } from '../../utils/shortWalletAddress';
import { getTradeTrustTokenRegistry } from '../../tradeTrust/tokenRegistry';

interface Props {
  connectedEscrow: TitleEscrow;
  queryClient: QueryClient;
  refreshTitleEscrowData: () => void;
}

const { roleHash } = constants;

// Small component to reject a surrendered document - this will return the document to the previous owner
export function ManageRejectDocument(props: Props): ReactElement {
  const { handleSubmit, reset } = useForm<titleEscrowTransfer>();
  const [{ wallet }] = useConnectWallet();
  const [enabled, setEnabled] = useState<boolean>();
  const walletAddr = wallet?.accounts?.[0].address;

  // Check whether the current wallet has sufficient permissions
  useEffect(() => {
    getTradeTrustTokenRegistry().then((tokenRegistry) => {
      if (!tokenRegistry || !walletAddr) {
        return;
      }
      tokenRegistry.hasRole(roleHash.RestorerRole, walletAddr).then((r) => {
        setEnabled(r);
      });
    });
  }, [walletAddr]);

  const performTransaction: SubmitHandler<titleEscrowTransfer> = async () => {
    try {
      const tokenRegistry = await getTradeTrustTokenRegistry();
      if (tokenRegistry === undefined) {
        toast.error('Token registry not connected');
        return;
      }

      const tokenId = await props.connectedEscrow.tokenId();
      const restoreResult = await tokenRegistry.restore(tokenId);
      await restoreResult.wait(1);
      toast.success('Rejected surrender document');
      props.refreshTitleEscrowData();
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.code === Logger.errors.UNPREDICTABLE_GAS_LIMIT) {
        toast.info(
          `Please switch the active account to ${showShortWalletAddr(walletAddr)} in MetaMask`,
        );
      } else {
        if (error.message.includes('find tokenID')) {
          toast.error('Could not find tokenID');
        } else {
          toast.error(
            'Could not reject surrendered document, please check gas amount and permissions',
          );
        }
      }
    }

    reset();
  };

  return (
    <div>
      <Item>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Reject surrendered document
        </Typography>
        <Typography variant="body2">
          Reject the surrendered document (this will transfer the document back to the previous
          owner).
        </Typography>
        {enabled ? (
          <form onSubmit={handleSubmit(performTransaction)}>
            <Button type="submit" variant="contained" sx={{ my: 1 }}>
              Reject document
            </Button>
          </form>
        ) : (
          <form onSubmit={handleSubmit(performTransaction)}>
            <Tooltip title="You do not have the restorer role to perform this action">
              <span>
                <Button type="submit" disabled={true} variant="contained" sx={{ my: 1 }}>
                  Reject document
                </Button>
              </span>
            </Tooltip>
          </form>
        )}
      </Item>
    </div>
  );
}
