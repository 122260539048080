import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, CircularProgress } from '@mui/material';
import { DataGrid, GridColumns, GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import { PageTitle } from '../PageTitle';
import { partiesApi } from '../../api/partiesApi';
import { PartyResponse } from '../../generated';
import { NotFound } from '../../pages/NotFound';
import { toast } from 'react-toastify';
import { getAuthHeader } from '../../utils/getAuthHeader';

async function fetchMyParties(): Promise<Array<PartyResponse>> {
  const authHeader = await getAuthHeader();
  return partiesApi.partiesGet({ headers: authHeader });
}

export const PartiesDataTable = (): ReactElement => {
  const { data, isLoading, isError } = useQuery(['parties'], fetchMyParties);

  const queryClient = useQueryClient();
  const deleteParty = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();
      return partiesApi.partiesIdDelete({ id: id }, { headers: authHeader });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['parties']);
      },
    },
  );

  const navigate = useNavigate();
  const navigateToCreate = () => {
    navigate(`/create-party`);
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }
  if (isError) {
    return <NotFound />;
  }

  const columns: GridColumns = [
    {
      field: 'partyName',
      headerName: 'Party Name',
      width: 200,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 350,
      valueFormatter: (params) => {
        return params.value.toUTCString();
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 200,
      renderCell: (params) => (
        <div>
          <Button
            style={{ marginRight: '20px' }}
            variant="contained"
            onClick={() => {
              navigate(`/address-book/${(params.row as PartyResponse).id}/edit`);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              deleteParty.mutate((params.row as PartyResponse).id);
              toast.success('Successfully deleted document party');
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <PageTitle title={'Address Book'} />
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={data}
          columns={columns}
          rowHeight={44}
          headerHeight={56}
          autoPageSize={true}
          disableSelectionOnClick={true}
          components={{
            Footer: () => (
              <GridFooterContainer>
                <Button sx={{ margin: 1 }} variant="contained" onClick={navigateToCreate}>
                  Add new party
                </Button>
                <GridFooter
                  sx={{
                    border: 'none',
                  }}
                />
              </GridFooterContainer>
            ),
          }}
        />
      </div>
    </>
  );
};
