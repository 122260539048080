/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingDocumentStatus } from './BookingDocumentStatus';
import {
    BookingDocumentStatusFromJSON,
    BookingDocumentStatusFromJSONTyped,
    BookingDocumentStatusToJSON,
} from './BookingDocumentStatus';
import type { DocumentStatus } from './DocumentStatus';
import {
    DocumentStatusFromJSON,
    DocumentStatusFromJSONTyped,
    DocumentStatusToJSON,
} from './DocumentStatus';

/**
 * 
 * @export
 * @interface BookingRequestsIdCallbackPutRequest
 */
export interface BookingRequestsIdCallbackPutRequest {
    /**
     * 
     * @type {string}
     * @memberof BookingRequestsIdCallbackPutRequest
     */
    carrierBookingRequestReference: string;
    /**
     * 
     * @type {BookingDocumentStatus}
     * @memberof BookingRequestsIdCallbackPutRequest
     */
    bookingDocumentStatus: BookingDocumentStatus;
    /**
     * 
     * @type {DocumentStatus}
     * @memberof BookingRequestsIdCallbackPutRequest
     */
    eblDocumentStatus?: DocumentStatus;
    /**
     * A free-form text field describing the reason for e.g. accepting or denying a booking. A carrier can 
     * for example write down a summary of amendments that should be made to the booking request.
     * @type {string}
     * @memberof BookingRequestsIdCallbackPutRequest
     */
    reason?: string;
    /**
     * If this callback is regarding a specific transport document, such as the eBL created in an issue 
     * callback message, then this reference should be included. Retrieve the document from the carrier
     * afterwards by calling GET <carrier-api>/v2/transport-documents/{transportDocumentReference}.
     * @type {string}
     * @memberof BookingRequestsIdCallbackPutRequest
     */
    transportDocumentReference?: string;
}

/**
 * Check if a given object implements the BookingRequestsIdCallbackPutRequest interface.
 */
export function instanceOfBookingRequestsIdCallbackPutRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "carrierBookingRequestReference" in value;
    isInstance = isInstance && "bookingDocumentStatus" in value;

    return isInstance;
}

export function BookingRequestsIdCallbackPutRequestFromJSON(json: any): BookingRequestsIdCallbackPutRequest {
    return BookingRequestsIdCallbackPutRequestFromJSONTyped(json, false);
}

export function BookingRequestsIdCallbackPutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingRequestsIdCallbackPutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'carrierBookingRequestReference': json['carrierBookingRequestReference'],
        'bookingDocumentStatus': BookingDocumentStatusFromJSON(json['bookingDocumentStatus']),
        'eblDocumentStatus': !exists(json, 'eblDocumentStatus') ? undefined : DocumentStatusFromJSON(json['eblDocumentStatus']),
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'transportDocumentReference': !exists(json, 'transportDocumentReference') ? undefined : json['transportDocumentReference'],
    };
}

export function BookingRequestsIdCallbackPutRequestToJSON(value?: BookingRequestsIdCallbackPutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'carrierBookingRequestReference': value.carrierBookingRequestReference,
        'bookingDocumentStatus': BookingDocumentStatusToJSON(value.bookingDocumentStatus),
        'eblDocumentStatus': DocumentStatusToJSON(value.eblDocumentStatus),
        'reason': value.reason,
        'transportDocumentReference': value.transportDocumentReference,
    };
}

