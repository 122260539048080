import {
  TradeTrustToken,
  TradeTrustToken__factory,
} from '@govtechsg/token-registry/dist/contracts';
import { ethers } from 'ethers';
import { getWalletSigner } from '../utils/wallet';

export const tokenRegistries = [
  {
    chainId: 11155111,
    address: '0x874d092cFd47cEc636Aa3cc6cB00d10aA1c43930',
    name: 'Ethereum sepolia',
  },
  {
    chainId: 80001,
    address: '0x2f63C6230DEA3830C94d594a41eC0cbd28EcdB86',
    name: 'Polygon mumbai',
  },
  {
    chainId: 51,
    address: '0x3C2AD967916c1fBA27F8E407f516367d8C691d8e',
    name: 'XDC Apothem',
  },
];

export function getTokenRegistryAddress(chainId: number) {
  const registry = tokenRegistries.find((r) => r.chainId === chainId);
  // By default, we use the polygon mumbai network
  if (!registry) return tokenRegistries[1].address;
  return registry.address;
}

export function getNetworkName(chainId: number) {
  const registry = tokenRegistries.find((r) => r.chainId === chainId);
  // By default, we use the polygon mumbai network
  if (!registry) return tokenRegistries[1].name + 'network';
  return registry.name + ' network';
}

export let ttTokenRegistry: TradeTrustToken;

export async function initTokenRegistry(signer: ethers.Signer) {
  console.log('Initializing token registry');
  const chainId = await signer.getChainId();

  const registry = tokenRegistries.find((r) => r.chainId === chainId);
  if (!registry) {
    console.error('Token registry not found for network with chain ID ' + chainId);
    return;
  }
  ttTokenRegistry = TradeTrustToken__factory.connect(registry.address, signer);
  console.log('Token registry initialized. Using token registry ' + ttTokenRegistry.address);
}

export async function getTradeTrustTokenRegistry() {
  if (ttTokenRegistry === undefined) {
    await initTokenRegistry(getWalletSigner());
  }
  return ttTokenRegistry;
}
