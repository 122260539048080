/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The mode of transport as defined by DCSA.
 * @export
 */
export const ModeOfTransport = {
    Vessel: 'VESSEL',
    Rail: 'RAIL',
    Truck: 'TRUCK',
    Barge: 'BARGE'
} as const;
export type ModeOfTransport = typeof ModeOfTransport[keyof typeof ModeOfTransport];


export function ModeOfTransportFromJSON(json: any): ModeOfTransport {
    return ModeOfTransportFromJSONTyped(json, false);
}

export function ModeOfTransportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModeOfTransport {
    return json as ModeOfTransport;
}

export function ModeOfTransportToJSON(value?: ModeOfTransport | null): any {
    return value as any;
}

