import { ReactElement } from 'react';
import { TitleTransfer } from '../components/TTManagement/TitleTransfer';
import { useParams } from 'react-router';

export const ManageTitleTransfer = (): ReactElement => {
  const { id } = useParams();

  if (id === undefined) {
    return <p>Error: shipment ID not found</p>;
  }

  return <TitleTransfer shipmentId={id} />;
};
