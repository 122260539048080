/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Refers to the shipment term at the unloading of the cargo out of the container. Options are defined in the Cargo Movement Type entity.
 * - FCL (Full Container Load)
 * - LCL (Less than Container Load)
 * - BB (Break Bulk)
 * @export
 */
export const CargoMovementTypeAtDestination = {
    Fcl: 'FCL',
    Lcl: 'LCL',
    Bb: 'BB'
} as const;
export type CargoMovementTypeAtDestination = typeof CargoMovementTypeAtDestination[keyof typeof CargoMovementTypeAtDestination];


export function CargoMovementTypeAtDestinationFromJSON(json: any): CargoMovementTypeAtDestination {
    return CargoMovementTypeAtDestinationFromJSONTyped(json, false);
}

export function CargoMovementTypeAtDestinationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CargoMovementTypeAtDestination {
    return json as CargoMovementTypeAtDestination;
}

export function CargoMovementTypeAtDestinationToJSON(value?: CargoMovementTypeAtDestination | null): any {
    return value as any;
}

