import {
  TitleEscrow,
  TitleEscrowFactory__factory,
  TitleEscrow__factory,
  TradeTrustToken__factory,
} from '@govtechsg/token-registry/dist/contracts';
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useConnectWallet, useWallets } from '@web3-onboard/react';
import { Signer } from 'ethers';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { shipmentsApi } from '../../api/shipmentsApi';
import { ShipmentResponse } from '../../generated';
import { defaultTitleEscrowFactory } from '../../tradeTrust/titleEscrow';
import { getTokenRegistryAddress } from '../../tradeTrust/tokenRegistry';
import { getAuthHeader } from '../../utils/getAuthHeader';
import { getWalletSigner } from '../../utils/wallet';
import { PageTitle } from '../PageTitle';
import { ShipmentsFooter } from '../ShipmentsFooter';

interface TitleEscrowStatuses {
  [key: string]: boolean;
}

async function fetchMyShipments(): Promise<Array<ShipmentResponse>> {
  const authHeader = await getAuthHeader();

  return await shipmentsApi.shipmentsGet({ headers: authHeader });
}

function QuickSearchToolbar() {
  const toolbarStyle = {
    paddingLeft: 1,
    paddingRight: 1,
    paddingBottom: 2,
    paddingTop: 1,
    display: 'flex',
  };

  const filterStyle = {
    flex: 1,
  };

  return (
    <Box sx={toolbarStyle}>
      <GridToolbarQuickFilter style={filterStyle} debounceMs={600} />
    </Box>
  );
}

export const ShipmentsDatagrid = (): ReactElement => {
  const { data, isLoading, isError, refetch } = useQuery(['shipments'], fetchMyShipments);
  const [titleEscrowStatuses, setTitleEscrowStatuses] = useState<TitleEscrowStatuses>({});
  const [surrenderedStatuses, setSurrenderedStatuses] = useState<Record<string, boolean>>({});
  const [tokenStatusLoading, setTokenStatusLoading] = useState<Record<string, boolean>>({});
  const [{ wallet }] = useConnectWallet();
  const wallets = useWallets();

  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [selectedShipmentId, setSelectedShipmentId] = useState('');
  const handleClickOpen = (id: string) => {
    setOpen(true);
    setSelectedShipmentId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  const deleteShipment = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();

      return shipmentsApi.shipmentsIdDelete({ id: id }, { headers: authHeader });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipments']);
        handleClose();
      },
    },
  );

  const getSigner = () => getWalletSigner();

  async function getTitleEscrowAddress(tokenId: string, signer: Signer) {
    const titleEscrowFactory = TitleEscrowFactory__factory.connect(
      defaultTitleEscrowFactory,
      signer,
    );
    return await titleEscrowFactory.getAddress(
      getTokenRegistryAddress(parseInt(window.ethereum?.networkVersion)),
      '0x' + tokenId,
    );
  }

  async function checkIsActive(titleEscrow: TitleEscrow) {
    return await titleEscrow.active();
  }

  async function checkIsSurrendered(tokenId: string, signer: Signer) {
    const tokenRegistry = TradeTrustToken__factory.connect(
      getTokenRegistryAddress(parseInt(window.ethereum?.networkVersion)),
      signer,
    );
    const owner = await tokenRegistry.ownerOf('0x' + tokenId);
    return owner === '0x000000000000000000000000000000000000dEaD';
  }

  useEffect(() => {
    async function getTitleEscrow(shipment: ShipmentResponse) {
      const tokenId = shipment.tokenId;
      if (!tokenId) return;

      setTokenStatusLoading((prev) => ({ ...prev, [shipment.id]: true }));

      try {
        const signer = getSigner();
        const titleEscrowAddress = await getTitleEscrowAddress(tokenId, signer);
        const titleEscrow = TitleEscrow__factory.connect(titleEscrowAddress, signer);

        const isActive = await checkIsActive(titleEscrow);
        const isSurrendered = await checkIsSurrendered(tokenId, signer);

        setSurrenderedStatuses((prevStatuses) => ({
          ...prevStatuses,
          [shipment.id]: isSurrendered,
        }));

        setTitleEscrowStatuses((prevStatuses) => ({
          ...prevStatuses,
          [shipment.id]: isActive,
        }));
      } catch (error) {
        console.error('Failed to get title escrow for shipment', shipment.id, ':', error);
      } finally {
        setTokenStatusLoading((prev) => ({ ...prev, [shipment.id]: false }));
      }
    }

    if (data) {
      data.forEach(getTitleEscrow);
    }
  }, [data]);

  useEffect(() => {
    if (wallets.length > 0) {
      refetch();
    }
  }, [wallets, refetch]);

  const handleDelete = () => {
    deleteShipment.mutate(selectedShipmentId);
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return <h1>Error</h1>;
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 300 },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 300,
      valueFormatter: (params) => {
        return params.value.toUTCString();
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 300,
      valueFormatter: (params) => {
        return params.value.toUTCString();
      },
    },
    {
      field: 'tokenStatus',
      headerName: 'eBL token status',
      width: 300,
      valueGetter: (params) => {
        const isActive = titleEscrowStatuses[params.row.id];
        const isSurrendered = surrenderedStatuses[params.row.id];

        if (isSurrendered) {
          return 'Token shredded';
        }
        return isActive ? 'Token active' : 'Token not created';
      },
      renderCell: (params) => {
        if (tokenStatusLoading[params.row.id]) {
          return <CircularProgress size={40} />;
        }
        if (!wallet) {
          return <Typography></Typography>;
        }
        return <Typography>{params.value}</Typography>;
      },
      filterable: true,
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const handleViewClick = () => {
          navigate(`/shipments/${params.row.id}`);
        };
        const deleteOnClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation(); // don't select this row after clicking
          handleClickOpen(params.row.id);
        };
        return (
          <>
            <Button style={{ marginRight: '20px' }} variant="contained" onClick={handleViewClick}>
              View
            </Button>
            <Button variant="contained" color="error" onClick={deleteOnClick}>
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <PageTitle title="shipments" />

      {!wallet && !Object.values(tokenStatusLoading).some((loading) => loading) && (
        <Alert severity="warning" style={{ marginBottom: '16px' }}>
          Please connect your wallet to see eBL token statuses.
        </Alert>
      )}

      <div style={{ height: 450, width: '100%' }}>
        <DataGrid
          rows={data}
          getRowId={(row) => row.id}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          components={{ Toolbar: QuickSearchToolbar, Footer: ShipmentsFooter }}
          sortingOrder={[null, 'desc', null, null]}
          sortModel={[{ field: 'updatedAt', sort: 'desc' }]}
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <span style={{ color: 'red' }}>Warning</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will also delete any attached booking requests, commodities, shipment
            locations, and document parties. Are you sure you want to delete this shipment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button style={{ color: 'red' }} onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
