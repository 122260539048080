/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The unit of measure which can be expressed in imperial or metric terms
 * - KGM (Kilograms)
 * - LBR (Pounds)
 * @export
 */
export const CargoGrossWeightUnit = {
    Kgm: 'KGM',
    Lbr: 'LBR'
} as const;
export type CargoGrossWeightUnit = typeof CargoGrossWeightUnit[keyof typeof CargoGrossWeightUnit];


export function CargoGrossWeightUnitFromJSON(json: any): CargoGrossWeightUnit {
    return CargoGrossWeightUnitFromJSONTyped(json, false);
}

export function CargoGrossWeightUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): CargoGrossWeightUnit {
    return json as CargoGrossWeightUnit;
}

export function CargoGrossWeightUnitToJSON(value?: CargoGrossWeightUnit | null): any {
    return value as any;
}

