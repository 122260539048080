import { CancelScheduleSend, Delete, Send } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import ViewArray from '@mui/icons-material/ViewArray';
import ViewIcon from '@mui/icons-material/ViewTimeline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { bookingsApi } from '../../api/bookingsApi';
import { shipmentsApi } from '../../api/shipmentsApi';
import { BookingResponse, ShipmentResponse } from '../../generated';
import {
  canBLBeApproved,
  canBLBeRequested,
  canBookingBeCancelled,
  canBookingBeDetached,
  canBookingBeEdited,
  canBookingBeSent,
  hasTTAttached,
  isBLIssued,
} from '../../utils/bookingStatus';
import { getAuthHeader } from '../../utils/getAuthHeader';
import { AddDocumentParties } from '../AddDocumentParties';
import EditBimcoForm from '../BimcoForms/EditBimcoForm';

export default function BookingActions(props: {
  shipment: ShipmentResponse;
  booking: BookingResponse;
}) {
  const booking = props.booking;
  const shipment = props.shipment;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [openBimcoForm, setOpenBimcoForm] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteBooking = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();

      return bookingsApi.bookingRequestsIdDelete({ id: id }, { headers: authHeader });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipment', shipment.id]);
      },
    },
  );

  const sendBooking = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();

      return bookingsApi.bookingRequestsIdSendPost({ id: id }, { headers: authHeader });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipment', shipment.id]);
      },
    },
  );

  const requestBL = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();

      return shipmentsApi.shipmentsIdRequestBlPost(
        { id: id, draft: true },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipment', shipment.id]);
      },
    },
  );

  const approveBl = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();

      return shipmentsApi.shipmentsIdRequestBlPost(
        { id: id, draft: false },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipment', shipment.id]);
      },
    },
  );

  const cancelBooking = useMutation(
    async (id: string) => {
      const authHeader = await getAuthHeader();

      return bookingsApi.bookingRequestsIdCancelPost(
        {
          id: id,
          bookingRequestsIdCancelPostRequest: { reason: '' },
        },
        { headers: authHeader },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shipment', shipment.id]);
      },
    },
  );

  const hasBookingAttachment = function (shipment: ShipmentResponse) {
    return shipment.docs?.find((doc) => doc.fileName.startsWith('booking-request-'));
  };

  const handleEditClick = () => {
    if (booking.eblType == 'DCSA') {
      navigate(`/shipments/` + booking.shipmentId + `/edit-booking-request`, {
        state: {
          shipment: shipment,
        },
      });
    }

    if (booking.eblType == 'BIMCO') {
      setOpenBimcoForm(!openBimcoForm);
    }
  };

  function handleCancelClick() {
    cancelBooking.mutate(booking.shipmentId);
  }

  function handleDetachClick() {
    deleteBooking.mutate(booking.id || '');
  }

  function handleSendClick() {
    sendBooking.mutate(booking.shipmentId);
  }

  function handleRequestBLClick() {
    requestBL.mutate(booking.shipmentId);
  }

  function handleApproveBLClick() {
    approveBl.mutate(booking.shipmentId);
  }

  function navigateTitleTransfer() {
    navigate(`/shipments/` + booking.shipmentId + `/manage-title-transfer`);
  }

  function navigateTokenHistory() {
    navigate(`/shipments/` + booking.shipmentId + `/token-history`);
  }

  function navigateBookingRequest() {
    navigate(`/shipments/` + booking.shipmentId + `/view-booking-request`);
  }

  return (
    <>
      {canBookingBeEdited(booking) && (
        <>
          <Button onClick={handleEditClick} variant="outlined" startIcon={<EditIcon />}>
            Edit Booking Request
          </Button>
          <AddDocumentParties booking={booking} />
        </>
      )}
      {hasBookingAttachment(shipment) && (
        <Button onClick={navigateBookingRequest} variant="outlined" startIcon={<ViewArray />}>
          View booking request
        </Button>
      )}
      {canBookingBeDetached(booking) && (
        <Button onClick={handleClickOpen} variant="outlined" color="error" startIcon={<Delete />}>
          Detach Booking Request
        </Button>
      )}
      {canBookingBeSent(booking) && (
        <Button onClick={handleSendClick} variant="outlined" color="success" startIcon={<Send />}>
          Send booking request to carrier
        </Button>
      )}
      {canBLBeRequested(shipment, booking) && (
        <Button
          onClick={handleRequestBLClick}
          variant="outlined"
          color="success"
          startIcon={<Send />}
        >
          Request eBL from carrier
        </Button>
      )}
      {canBLBeApproved(shipment) && (
        <Button
          onClick={handleApproveBLClick}
          variant="outlined"
          color="success"
          startIcon={<Send />}
        >
          Approve draft eBL
        </Button>
      )}
      {canBookingBeCancelled(booking) && (
        <Button
          onClick={handleCancelClick}
          variant="outlined"
          color="warning"
          startIcon={<CancelScheduleSend />}
        >
          Cancel the pending booking request
        </Button>
      )}
      {isBLIssued(shipment) && hasTTAttached(shipment) && (
        <>
          <Button onClick={navigateTitleTransfer} variant="outlined" startIcon={<EditIcon />}>
            Manage title transfer
          </Button>
          <Button onClick={navigateTokenHistory} variant="outlined" startIcon={<ViewIcon />}>
            View token history
          </Button>
        </>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <span style={{ color: 'red' }}>Warning</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will also delete any attached commodities, shipment locations, and document
            parties. Are you sure you want to delete this booking request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDetachClick} style={{ color: 'red' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <EditBimcoForm
        booking={booking}
        shipment={shipment}
        isDialogOpen={openBimcoForm}
        handleCloseDialog={() => setOpenBimcoForm(false)}
      ></EditBimcoForm>
    </>
  );
}
