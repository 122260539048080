import { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AppContext } from '../../Context';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ShipmentResponse } from '../../generated';
import { useFormContext } from 'react-hook-form';

export default function FirstStep(shipment: ShipmentResponse) {
  const {
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useFormContext();
  const formValues = getValues();
  const { handleNext } = useContext(AppContext);

  const [exportDeclarationRequired, setExportDeclarationRequired] = useState(
    shipment.booking?.isExportDeclarationRequired
      ? shipment.booking.isExportDeclarationRequired
      : false,
  );

  const [importLicenseRequired, setImportLicenseRequired] = useState(
    shipment.booking?.isImportLicenseRequired ? shipment.booking.isImportLicenseRequired : false,
  );

  watch();

  const onSubmit = handleSubmit(() => {
    handleNext();
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            error={!!errors.receiptTypeAtOrigin}
            defaultValue={
              shipment.booking?.receiptTypeAtOrigin ? shipment.booking.receiptTypeAtOrigin : ''
            }
            inputProps={register('receiptTypeAtOrigin', {
              required: true,
            })}
            label="Delivery type at origin"
            name="receiptTypeAtOrigin"
          >
            <option value=""></option>
            <option value="CY">Container yard</option>
            <option value="SD">Store Door</option>
            <option value="CFS">Container Freight Station</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            error={!!errors.deliveryTypeAtDestination}
            defaultValue={
              shipment.booking?.deliveryTypeAtDestination
                ? shipment.booking.deliveryTypeAtDestination
                : ''
            }
            inputProps={register('deliveryTypeAtDestination', {
              required: true,
            })}
            label="Delivery type at destination"
            name="deliveryTypeAtDestination"
          >
            <option value=""></option>
            <option value="CY">Container yard</option>
            <option value="SD">Store Door</option>
            <option value="CFS">Container Freight Station</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            SelectProps={{
              native: true,
            }}
            error={!!errors.cargoMovementTypeAtOrigin}
            select
            defaultValue={
              shipment.booking?.cargoMovementTypeAtOrigin
                ? shipment.booking.cargoMovementTypeAtOrigin
                : ''
            }
            inputProps={register('cargoMovementTypeAtOrigin', {
              required: true,
            })}
            label="Cargo type at origin"
            name="cargoMovementTypeAtOrigin"
          >
            <option value=""></option>
            <option value="FCL">Full Container Load</option>
            <option value="LCL">Less than Container Load</option>
            <option value="BB">Break Bulk</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            SelectProps={{
              native: true,
            }}
            error={!!errors.cargoMovementTypeAtDestination}
            select
            defaultValue={
              shipment.booking?.cargoMovementTypeAtDestination
                ? shipment.booking.cargoMovementTypeAtDestination
                : ''
            }
            inputProps={register('cargoMovementTypeAtDestination', {
              required: true,
            })}
            label="Cargo type at destination"
            name="cargoMovementTypeAtDestination"
          >
            <option value=""></option>
            <option value="FCL">Full Container Load</option>
            <option value="LCL">Less than Container Load</option>
            <option value="BB">Break Bulk</option>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            SelectProps={{
              native: true,
            }}
            error={!!errors.communicationChannelCode}
            select
            defaultValue={
              shipment.booking?.communicationChannelCode
                ? shipment.booking.communicationChannelCode
                : ''
            }
            inputProps={register('communicationChannelCode', {
              required: true,
            })}
            label="Specify booking communication channel"
            name="communicationChannelCode"
          >
            <option value=""></option>
            <option value="AO">API</option>
            <option value="EI">EDI transmission</option>
            <option value="EM">Email</option>
          </TextField>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register('isPartialLoadAllowed')}
              defaultChecked={
                formValues.isPartialLoadAllowed || shipment.booking?.isPartialLoadAllowed
              }
              onChange={(e) => {
                setValue('isPartialLoadAllowed', e.target.checked);
              }}
              name="isPartialLoadAllowed"
              color="primary"
            />
          }
          label="Is partial load allowed?"
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register('isExportDeclarationRequired')}
              checked={exportDeclarationRequired}
              onChange={(e) => {
                setExportDeclarationRequired(e.target.checked);
                setValue('isExportDeclarationRequired', e.target.checked);
              }}
              name="isExportDeclarationRequired"
              color="primary"
            />
          }
          label="Is export declaration required?"
        />
      </Grid>

      {exportDeclarationRequired && (
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('exportDeclarationReference')}
            fullWidth
            label="Export declaration reference"
            name="exportDeclarationReference"
            placeholder="Enter export declaration reference"
            value={
              formValues.exportDeclarationReference || shipment.booking?.exportDeclarationReference
            }
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register('isImportLicenseRequired')}
              defaultChecked={
                formValues.isImportLicenseRequired || shipment.booking?.isImportLicenseRequired
              }
              onChange={(e) => {
                setImportLicenseRequired(e.target.checked);
                setValue('isImportLicenseRequired', e.target.checked);
              }}
              name="isImportLicenseRequired"
              color="primary"
            />
          }
          label="Is import license required?"
        />
      </Grid>

      {importLicenseRequired && (
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('importLicenseReference')}
            fullWidth
            label="Import license reference"
            name="importLicenseReference"
            placeholder="Enter import license reference"
            value={formValues.importLicenseReference || shipment.booking?.importLicenseReference}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              {...register('isEquipmentSubstitutionAllowed')}
              defaultChecked={
                formValues.isEquipmentSubstitutionAllowed ||
                shipment.booking?.isEquipmentSubstitutionAllowed
              }
              onChange={(e) => {
                setValue('isEquipmentSubstitutionAllowed', e.target.checked);
              }}
              name="isEquipmentSubstitutionAllowed"
              color="primary"
            />
          }
          label="Is equipment substitution allowed?"
        />
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" sx={{ mt: 3, ml: 1 }} color="primary" onClick={onSubmit}>
          Next
        </Button>
      </Box>
    </>
  );
}
