import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Tooltip, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TitleEscrow } from '@govtechsg/token-registry/dist/contracts';
import { toast } from 'react-toastify';
import { QueryClient } from '@tanstack/react-query';
import { Item, titleEscrowTransfer } from './TitleTransfer';
import { useConnectWallet } from '@web3-onboard/react';
import { ethers } from 'ethers';
import { PartyResponse } from '../../generated';
import { Logger } from 'ethers/lib/utils';
import { showShortWalletAddr } from '../../utils/shortWalletAddress';

interface Props {
  connectedEscrow: TitleEscrow;
  queryClient: QueryClient;
  refreshTitleEscrowData: () => void;
  parties: Array<PartyResponse>;
}

// Small component to view and update the current beneficiary
export function ManageBeneficiary(props: Props): ReactElement {
  const [beneficiary, setBeneficiary] = useState<string>();
  const [holder, setHolder] = useState<string>();
  const { handleSubmit, reset } = useForm<titleEscrowTransfer>();
  const [{ wallet }] = useConnectWallet();
  const [nominee, setNominee] = useState<string>();
  const [partyName, setPartyName] = useState<string>();

  const walletAddr = wallet?.accounts?.[0].address;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const nominee = await props.connectedEscrow.nominee();
        setNominee(nominee);

        const beneficiary = await props.connectedEscrow.beneficiary();
        const party = props.parties.find(
          (p) => p.publicKey?.toLowerCase() === beneficiary.toLowerCase(),
        );
        if (party !== undefined) {
          setPartyName(party.partyName);
        }
        setBeneficiary(beneficiary);
      } catch (error) {
        console.error(error);
        setBeneficiary('(None)');
      }

      try {
        const holder = await props.connectedEscrow.holder();
        setHolder(holder);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [props.connectedEscrow, props.parties]);
  // If the current user is the beneficiary nominee of the token,
  // perform the transfer of beneficiary here
  const performTransaction: SubmitHandler<titleEscrowTransfer> = async () => {
    if (nominee === undefined) {
      console.error('Nominee has not been set');
      return;
    }
    try {
      const r = await props.connectedEscrow.transferBeneficiary(nominee);
      setBeneficiary('Waiting for confirmation...');
      await r.wait(1);
      toast.success(`Transfer to owner ${showShortWalletAddr(nominee)} succeeded`);
      setBeneficiary(nominee);
      props.refreshTitleEscrowData();
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.code === Logger.errors.UNPREDICTABLE_GAS_LIMIT) {
        toast.info(
          `Please switch the active account to ${showShortWalletAddr(walletAddr)} in MetaMask`,
        );
      } else {
        toast.error('Could not transfer owner, please check gas amount and permissions');
      }
    }
    reset();
  };

  return (
    <div>
      <Item>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Current owner
        </Typography>
        <Typography variant="body1">{partyName}</Typography>
        <Typography variant="body2">{beneficiary}</Typography>
      </Item>

      {walletAddr === beneficiary?.toLowerCase() && walletAddr === holder?.toLowerCase() && (
        <Item>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Transfer owner
          </Typography>
          <Typography variant="body2">Confirm the change of owner to the nominee</Typography>
          <form onSubmit={handleSubmit(performTransaction)}>
            {nominee === ethers.constants.AddressZero ? (
              <Tooltip title="No nominee has been assigned yet">
                <span>
                  <Button type="submit" disabled={true} variant="contained" sx={{ my: 1 }}>
                    Transfer
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button type="submit" variant="contained" sx={{ my: 1 }}>
                Transfer
              </Button>
            )}
          </form>
        </Item>
      )}
    </div>
  );
}
