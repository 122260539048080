import { styled, Typography } from '@mui/material';

import { FOOTER_HEIGHT, FOOTER_TEXT } from '../../utils/constants';
import { ReactElement } from 'react';

export const Footer = (): ReactElement => (
  <FooterWrapper>
    <FooterText variant="caption" color="textSecondary">
      {FOOTER_TEXT}
    </FooterText>
  </FooterWrapper>
);

const FooterWrapper = styled('div')(
  `
    flex: 1;
    display: flex;
    justify-content: center;
    minHeight: ${FOOTER_HEIGHT};
`,
);

const FooterText = styled(Typography)`
  word-spacing: 0.1rem;
  text-transform: uppercase;
`;
