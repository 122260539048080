/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Links to the Location Type Code defined by DCSA.
 * - PRE (Place of Receipt)
 * - POL (Port of Loading)
 * - POD (Port of Discharge)
 * - PDE (Place of Delivery)
 * - PCF (Pre-carriage From)
 * - PSR (Pre-carriage under shipper’s responsibility)
 * - OIR (Onward In-land Routing)
 * - DRL (Depot release location)
 * - ORI (Origin of goods)
 * - IEL (Container intermediate export stop off location)
 * - PTP (Prohibited transshipment port)
 * - RTP (Requested transshipment port)
 * - FCD (Full container drop-off location)
 * - ECP (Empty container pick-up location)
 * @export
 */
export const ShipmentLocationTypeCode = {
    Pre: 'PRE',
    Pol: 'POL',
    Pod: 'POD',
    Pde: 'PDE',
    Pcf: 'PCF',
    Psr: 'PSR',
    Oir: 'OIR',
    Drl: 'DRL',
    Ori: 'ORI',
    Iel: 'IEL',
    Ptp: 'PTP',
    Rtp: 'RTP',
    Fcd: 'FCD',
    Ecp: 'ECP'
} as const;
export type ShipmentLocationTypeCode = typeof ShipmentLocationTypeCode[keyof typeof ShipmentLocationTypeCode];


export function ShipmentLocationTypeCodeFromJSON(json: any): ShipmentLocationTypeCode {
    return ShipmentLocationTypeCodeFromJSONTyped(json, false);
}

export function ShipmentLocationTypeCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentLocationTypeCode {
    return json as ShipmentLocationTypeCode;
}

export function ShipmentLocationTypeCodeToJSON(value?: ShipmentLocationTypeCode | null): any {
    return value as any;
}

