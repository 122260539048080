/* tslint:disable */
/* eslint-disable */
/**
 * Ebl API
 * Ebl API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressData } from './AddressData';
import {
    AddressDataFromJSON,
    AddressDataFromJSONTyped,
    AddressDataToJSON,
} from './AddressData';
import type { PartyDataIdentifyingCodesInner } from './PartyDataIdentifyingCodesInner';
import {
    PartyDataIdentifyingCodesInnerFromJSON,
    PartyDataIdentifyingCodesInnerFromJSONTyped,
    PartyDataIdentifyingCodesInnerToJSON,
} from './PartyDataIdentifyingCodesInner';
import type { PartyDataPartyContactDetailsInner } from './PartyDataPartyContactDetailsInner';
import {
    PartyDataPartyContactDetailsInnerFromJSON,
    PartyDataPartyContactDetailsInnerFromJSONTyped,
    PartyDataPartyContactDetailsInnerToJSON,
} from './PartyDataPartyContactDetailsInner';

/**
 * 
 * @export
 * @interface PartyData1
 */
export interface PartyData1 {
    /**
     * 
     * @type {string}
     * @memberof PartyData1
     */
    partyName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyData1
     */
    taxReference1?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyData1
     */
    taxReference2?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyData1
     */
    publicKey?: string;
    /**
     * 
     * @type {AddressData}
     * @memberof PartyData1
     */
    address?: AddressData;
    /**
     * A list of contact details - the list cannot be empty
     * @type {Array<PartyDataPartyContactDetailsInner>}
     * @memberof PartyData1
     */
    partyContactDetails: Array<PartyDataPartyContactDetailsInner>;
    /**
     * 
     * @type {Array<PartyDataIdentifyingCodesInner>}
     * @memberof PartyData1
     */
    identifyingCodes?: Array<PartyDataIdentifyingCodesInner>;
}

/**
 * Check if a given object implements the PartyData1 interface.
 */
export function instanceOfPartyData1(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "partyContactDetails" in value;

    return isInstance;
}

export function PartyData1FromJSON(json: any): PartyData1 {
    return PartyData1FromJSONTyped(json, false);
}

export function PartyData1FromJSONTyped(json: any, ignoreDiscriminator: boolean): PartyData1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partyName': !exists(json, 'partyName') ? undefined : json['partyName'],
        'taxReference1': !exists(json, 'taxReference1') ? undefined : json['taxReference1'],
        'taxReference2': !exists(json, 'taxReference2') ? undefined : json['taxReference2'],
        'publicKey': !exists(json, 'publicKey') ? undefined : json['publicKey'],
        'address': !exists(json, 'address') ? undefined : AddressDataFromJSON(json['address']),
        'partyContactDetails': ((json['partyContactDetails'] as Array<any>).map(PartyDataPartyContactDetailsInnerFromJSON)),
        'identifyingCodes': !exists(json, 'identifyingCodes') ? undefined : ((json['identifyingCodes'] as Array<any>).map(PartyDataIdentifyingCodesInnerFromJSON)),
    };
}

export function PartyData1ToJSON(value?: PartyData1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partyName': value.partyName,
        'taxReference1': value.taxReference1,
        'taxReference2': value.taxReference2,
        'publicKey': value.publicKey,
        'address': AddressDataToJSON(value.address),
        'partyContactDetails': ((value.partyContactDetails as Array<any>).map(PartyDataPartyContactDetailsInnerToJSON)),
        'identifyingCodes': value.identifyingCodes === undefined ? undefined : ((value.identifyingCodes as Array<any>).map(PartyDataIdentifyingCodesInnerToJSON)),
    };
}

